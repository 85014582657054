import { BOOTCAMP_TOOL, SELF_LEARNING_TOOL } from "./lib/Constants";

export const URLS_SELF_LEARNING = {
  DEFAULT_SELF_LEARNING: "/" + SELF_LEARNING_TOOL + "/",
  HOME_SELF_LEARNING: "/" + SELF_LEARNING_TOOL + "/home",
  COURSE_DETAILS: "/" + SELF_LEARNING_TOOL + "/course-details/:id",
  COURSE_CONTENT: "/" + SELF_LEARNING_TOOL + "/coursecontent/:id",
  ASSESSMENT_DETAILS:
    "/" +
    SELF_LEARNING_TOOL +
    "/course-details/:courseId/assessment-details/:id",
  MY_LEARNINGS: "/" + SELF_LEARNING_TOOL + "/my-learnings",
  SETTINGS: "/" + SELF_LEARNING_TOOL + "/settings",
  MY_COURSES: "/" + SELF_LEARNING_TOOL + "/content/courses",
  ADD_NEW_COURSE: "/" + SELF_LEARNING_TOOL + "/content/course/",
  COURSE_FOR_CREATOR: "/" + SELF_LEARNING_TOOL + "/content/course/:id",
  COURSE_ASSESSMENT_LIST:
    "/" + SELF_LEARNING_TOOL + "/content/course/:id/assessments",
  ADD_ASSESSMENT: "/" + SELF_LEARNING_TOOL + "/content/course/:id/assessment/",
  COURSE_ASSESSMENT_CREATE_EDIT:
    "/" + SELF_LEARNING_TOOL + "/content/course/:id/assessment/:assessId",
  COURSE_PREVIEW:
    "/" + SELF_LEARNING_TOOL + "/content/course/:id/assessments/preview",
  ADD_QUIZ: "/" + SELF_LEARNING_TOOL + "/content/course/:id/quiz/",
  COURSE_QUIZ_CREATE_EDIT:
    "/" + SELF_LEARNING_TOOL + "/content/course/:id/quiz/:assessId",
  USER_QUIZ_VIEW: "/" + SELF_LEARNING_TOOL + "/course-details/:id/quiz/:quizId",
  IMPORT_COURSE: "/" + SELF_LEARNING_TOOL + "/content/courses/import-course",
  REVIEW_COURSES: "/" + SELF_LEARNING_TOOL + "/review/courses",
  REVIEW_COURSE_DETAILS: "/" + SELF_LEARNING_TOOL + "/review/course/:id",
  REVIEW_COURSE_LABS_DETAILS:
    "/" + SELF_LEARNING_TOOL + "/review/course/:id/assessments",
  CONFIGURE: "/" + SELF_LEARNING_TOOL + "/configure",
  COURSE_ENROLLEMNT_DETAILS:
    "/" + SELF_LEARNING_TOOL + "/content/course/:id/course-enrollment",
  COURSE_ENROLLMENT_FOR_REVIEWER:
    "/" + SELF_LEARNING_TOOL + "/review/course/:id/course-enrollment",
};

export const URLS = {
  DEFAULT: "/",
  LOGIN: "/login",
  ERROR: "/error",
  HOME: "/home",
  SELF_LEARNING: "/" + SELF_LEARNING_TOOL,
  BOOTCAMP: "/" + BOOTCAMP_TOOL,

  ...URLS_SELF_LEARNING,
};
