import { Col, Row, Steps, ConfigProvider, Divider, Spin } from "antd";
import AddEditCourseDetails from "component/AddEditCourseDetails/AddEditCourseDetails";
import { useEffect, useState } from "react";
import { useLazyQueryData, useMutationData } from "services";
import {
  COURSE_DETAILS,
  GET_ALL_USERS,
  GET_MODULE_DETAILS,
} from "services/Queries";
import { CREATE_UPDATE_COURSE } from "services/Mutation";
import { useParams } from "react-router-dom";
import { getLoginUserEmail } from "lib/Helper";
import AddEditSectionDetails from "component/AddEditSectionDetails/AddEditSectionDetails";
import "./CourseCreationForm.scss";
import Preview from "component/Preview/Preview";
import FinalAssessment from "component/FInalAssessment/FinalAssessment";

const CourseCreationForm = () => {
  const userEmail = getLoginUserEmail();
  const [currentStep, setCurrentStep] = useState(0);
  const [usersInfo, setUsersInfo] = useState([]);
  const [sectionDetails, setSectionDetails] = useState<any>([]);
  const [initialData, setInitialData] = useState({});
  const [course_id, setCourse_id] = useState<any>();
  const { courseId } = useParams();
  const [courseCreationData, setCourseCreationData] = useState<any>(null);
  const [course_uuid, setCourse_uuid] = useState(null);

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
    }
  }, [courseId]);

  const onChange = (value: number) => {
    if (value < currentStep) {
      setCurrentStep(value);
    }
  };

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // ====Queries=====
  const [getAllUsers, { data: usersResp, loading: usersLoading }] =
    useLazyQueryData(GET_ALL_USERS);

  const [
    getCourseDetails,
    { data: courseDetailsResp, loading: courseDetailsLoading },
  ] = useLazyQueryData(COURSE_DETAILS, {
    courseId: courseId,
    userEmail: userEmail,
  });

  // ====Mutations====
  const [createUpdateCourseMutation, { loading: createUpdateCourseLoading }] =
    useMutationData(CREATE_UPDATE_COURSE);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (usersResp) {
      setUsersInfo(usersResp);
    }
  }, [usersResp]);

  useEffect(() => {
    setInitialData(courseDetailsResp);
  }, [courseDetailsResp]);

  useEffect(() => {
    if(course_id) {
      window.history.pushState({ path: `/content/course` }, '', `/content/course/${course_id}`);
    }
  }, [course_id]);

  return (
    <>
      {usersLoading || courseDetailsLoading || createUpdateCourseLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row className="mb-10 pl-16">
            <Col xs={24} lg={16}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#357bac",
                  },
                }}
              >
                <Steps
                  type="navigation"
                  current={currentStep}
                  onChange={onChange}
                  className="site-navigation-steps"
                  percent={60}
                  items={[
                    {
                      status:
                        currentStep === 0
                          ? "process"
                          : currentStep > 0
                          ? "finish"
                          : "wait",
                      title: "Course Details",
                    },
                    {
                      status:
                        currentStep === 1
                          ? "process"
                          : currentStep > 1
                          ? "finish"
                          : "wait",
                      title: "Course Content",
                    },
                    {
                      status:
                        currentStep === 2
                          ? "process"
                          : currentStep > 2
                          ? "finish"
                          : "wait",
                      title: "Final Assessment",
                    },
                    {
                      status:
                        currentStep === 3
                          ? "process"
                          : currentStep > 3
                          ? "finish"
                          : "wait",
                      title: "Preview",
                    },
                  ]}
                />
              </ConfigProvider>
              <Divider
                className="divider m-0 stepper-divider"
                style={{ width: `${(currentStep + 1) * 25}%` }}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]} className="p-18">
            <Col span={24}>
              {currentStep === 0 && (
                <AddEditCourseDetails
                  title="Enter Course Details"
                  onNext={() => nextStep()}
                  usersInfo={usersInfo}
                  courseDetails={initialData}
                  courseId={courseId ? courseId : course_id}
                  setCourseId={setCourse_id}
                  createUpdateCourseMutation={createUpdateCourseMutation}
                  userEmail={userEmail}
                  setCourse_uuid={setCourse_uuid}
                  setCourseCreationData={setCourseCreationData}
                  courseCreationData={courseCreationData}
                />
              )}
              {currentStep === 1 && (
                <AddEditSectionDetails
                  onNext={() => {
                    nextStep();
                  }}
                  onPrev={() => prevStep()}
                  sectionDetails={sectionDetails}
                  setSectionDetails={setSectionDetails}
                  courseId={courseId ? courseId : course_id}
                  coursecuuid={course_uuid}
                />
              )}
              {currentStep === 2 && (
                <FinalAssessment
                  onNext={() => {
                    nextStep();
                  }}
                  onPrev={() => prevStep()}
                  sectionDetails={sectionDetails}
                  setSectionDetails={setSectionDetails}
                  courseId={courseId ? courseId : course_id}
                />
              )}
              {currentStep === 3 && (
                <Preview
                  title="Sections"
                  onPrev={() => {
                    prevStep();
                  }}
                  sectionDetails={sectionDetails}
                  courseId={courseId ? courseId : course_id}
                  setSectionDetails={setSectionDetails}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CourseCreationForm;
