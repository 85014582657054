import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Layout } from "antd";
import HeaderContent from "component/HeaderContent";
import LoginAndSignupRedirect from "component/LoginSignUpRedirect";
import Navbar from "component/Navbar";
import CourseContent from "pages/CourseContent/CourseContent";
import CourseCreationForm from "pages/CourseCreationForm";
import CourseDetails from "pages/CourseDetails/CourseDetails";
import MyContent from "pages/MyContent/MyContent";
import MyLearnings from "pages/MyLearnings/MyLearnings";
import Quiz from "pages/Quiz/Quiz";
import Dashboard from "pages/dashboard/Dashboard";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useLazyQueryData } from "services";
import { GET_LOGGEDIN_USER } from "services/Queries";

const { Header, Sider, Content } = Layout;

function SelfLearningModule(): JSX.Element {
  const [collapsed, setCollapsed] = useState(true);
  const [fetchUser, { data: userResp }] = useLazyQueryData(GET_LOGGEDIN_USER);
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount() || accounts[0];

  const redirectIfTokenExpired = () => {
    instance.loginRedirect();
    activeAccount &&
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: activeAccount,
        })
        .then((response) => {
          if (response) {
            localStorage.setItem(
              "userData",
              JSON.stringify({
                account: response.account,
                accessToken: response.accessToken,
              })
            );
          }
        });
  };

  useEffect(() => {
    fetchUser();
    console.log(userResp);
    if (userResp) {
      if (!userResp.users) {
        redirectIfTokenExpired();
      }
      localStorage.setItem("userId", userResp.users?.id);
    }
  }, [userResp, fetchUser]);

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Layout className="layout">
            <Sider
              className="sider"
              trigger={null}
              collapsible
              collapsed={collapsed}
            >
              <Navbar setCollapsed={setCollapsed} collapsed={collapsed} />
            </Sider>
            <Layout>
              <Header>
                <HeaderContent />
              </Header>
              <Content
                className={`content ${collapsed ? "" : "content-margin-left"}`}
              >
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                </Routes>
                <Routes>
                  <Route path="/mylearnings" element={<MyLearnings />} />
                </Routes>
                <Routes>
                  <Route path="/content" element={<MyContent />} />
                </Routes>
                <Routes>
                  <Route
                    path="/coursedetails/:id"
                    element={<CourseDetails />}
                  />
                  <Route
                    path="/coursedetails/coursecontent/:id"
                    element={<CourseContent />}
                  />
                  {/* <Route
                    path="/coursedetails/coursecontent/:courseId/quiz/:quizId"
                    element={<Quiz />}
                  /> */}
                  <Route
                    path="/createcourse"
                    element={<h1>Create Course</h1>}
                  />
                </Routes>

                <Routes>
                  <Route
                    path="/content/course"
                    element={<CourseCreationForm />}
                  />
                  <Route
                    path="/content/course/:courseId"
                    element={<CourseCreationForm />}
                  />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginAndSignupRedirect />
      </UnauthenticatedTemplate>
    </>
  );
}

export default SelfLearningModule;
