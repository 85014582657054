import React from "react";

const AddContent = ({ active = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2223_15467)">
        <path
          d="M5 18.9998H9.414L23.057 5.35677C23.6414 4.77094 23.9696 3.97724 23.9696 3.14977C23.9696 2.32229 23.6414 1.5286 23.057 0.942768C22.4627 0.374661 21.6722 0.0576172 20.85 0.0576172C20.0278 0.0576172 19.2373 0.374661 18.643 0.942768L5 14.5858V18.9998ZM7 15.4138L20.057 2.35677C20.2706 2.15272 20.5546 2.03886 20.85 2.03886C21.1454 2.03886 21.4294 2.15272 21.643 2.35677C21.8529 2.56728 21.9708 2.85246 21.9708 3.14977C21.9708 3.44708 21.8529 3.73225 21.643 3.94277L8.586 16.9998H7V15.4138Z"
          fill="white"
        />
        <path
          d="M23.621 7.622L22 9.243V16H16V22H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H14.758L16.379 0.379C16.525 0.243624 16.6789 0.117066 16.84 0L3 0C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3L0 24H18.414L24 18.414V7.161C23.8827 7.32186 23.7561 7.47579 23.621 7.622ZM18 21.586V18H21.586L18 21.586Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2223_15467">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddContent;
