import { Button, Col, Form, Input, Radio, Row, Spin, Tooltip, Typography, Upload, message } from "antd";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import './SuggestiveQuestionsAndSummary.scss';
import { useEffect, useState } from "react";
import { useLazyQueryData, useMutationData } from "services";
import { FETCH_SUMMARY_SUGGESTIVE, GENERATE_URI, GET_MODULE_DETAILS, GET_TASK_STATUS_SUMMARY_SUGGESTIVE } from "services/Queries";
import { icons } from "assets";
import TextArea from "antd/es/input/TextArea";
import { GENERATE_SUMMARY_SUGGESTIVE_QUESTIONS, SUMIT_PREVIEW } from "services/Mutation";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { TASK_STATUS } from "component/lib/Constants";

const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const SuggestiveQuestionsAndSummary = ({ blobPath, courseId, lesson, setShowQuestionsScreen, showSummarySuggestiveForm, setShowSummarySuggestiveForm, sectionDetails }) => {
    const [generatedForm] = Form.useForm();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [pdfDoc, setPdfDoc] = useState<any>(null);
    const [numberPages, setNumberPages] = useState<any>(null);
    const [pageNum, setPageNum] = useState<number>(1);
    const [loadFail, setLoadFail] = useState(true);
    const [pageIsRendering, setPageIsRendering] = useState<boolean>(false);
    const [pageNumIsPending, setPageNumIsPending] = useState<number | null>(null);
    const [summary, setSummary] = useState<any>(null);
    const [suggestiveQuestions, setSuggestiveQuestions] = useState<any>(null);
    const [contentLink, setContentLink] = useState(null);
    const [fileName, setFileName] = useState(null);
    const scale: number = 1.5;

    // Mutations
    const [generateSummarySuggestiveRelData] = useMutationData(GENERATE_SUMMARY_SUGGESTIVE_QUESTIONS);
    const [detailsRelData, { loading: detailsRelLoading }] = useMutationData(SUMIT_PREVIEW);

    // Queries
    const [
        getGenerateUri,
        { data: generateUriResp, loading: generateUriLoading },
    ] = useLazyQueryData(GENERATE_URI, { blobPath: blobPath });

    const [
        getModuleDetails,
        { data: moduleDetailsResp, loading: moduleDetailsLoading },
    ] = useLazyQueryData(GET_MODULE_DETAILS, {
        courseId: courseId,
    });

    const [getTaskStatus] = useLazyQueryData(GET_TASK_STATUS_SUMMARY_SUGGESTIVE);
    const [fetchSummarySuggestive] = useLazyQueryData(FETCH_SUMMARY_SUGGESTIVE);

    const pdfjsLib: any = require("pdfjs-dist/legacy/build/pdf.js");
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const renderPage = (num: number) => {
        if (pdfDoc) {
            setPageIsRendering(true);
            pdfDoc.getPage(num).then((page: any) => {
                const viewport = page.getViewport({ scale });
                const canvas: HTMLCanvasElement | null = document.querySelector("#pdf-render");
                if (canvas) {
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    const ctx = canvas.getContext("2d");
                    const renderCtx = {
                        canvasContext: ctx,
                        viewport,
                    };
                    // Clear the canvas before rendering a new page
                    ctx?.clearRect(0, 0, canvas.width, canvas.height);
                    page.render(renderCtx).promise.then(() => {
                        setPageIsRendering(false);
                        if (pageNumIsPending !== null) {
                            renderPage(pageNumIsPending);
                            setPageNumIsPending(null);
                        }
                    });
                }
            });
        }
    };

    const showNextPage = () => {
        let currentPage = pageNum;
        if (currentPage >= numberPages) {
            return;
        }
        setPageNum(currentPage + 1);
        queueRenderPage(currentPage + 1);
    };

    const showPrevPage = () => {
        let currentPage = pageNum;
        if (currentPage === 1) {
            return;
        }
        setPageNum(currentPage - 1);
        queueRenderPage(currentPage - 1);
    };

    const queueRenderPage = (num: number) => {
        if (pageIsRendering) {
            setPageNumIsPending(num);
        } else {
            renderPage(num);
        }
    };

    const onSave = () => {
        form.resetFields();

        detailsRelData({
            variables: {
                courseId,
                details: sectionDetails?.map(({ m_id, moduleId, newEntry, title, moduleNumber, lessons }) => ({
                    id: newEntry === false ? m_id : undefined,
                    moduleId: moduleId,
                    newEntry: newEntry,
                    moduleName: title,
                    moduleNumber: moduleNumber,
                    lessons: lessons.map((item) => ({
                        id: newEntry === false ? item?.l_id : undefined,
                        lessonId: item?.lessonId,
                        newEntry: newEntry,
                        orderNumber: item?.orderNumber,
                        lessonName: item?.lessonName,
                        contentType: item?.fileType,
                        contentLink: newEntry === false ? item?.fileName : contentLink,
                        suggestiveQuestions: item?.l_id === lesson?.l_id || item?.l_id === lesson?.id ? JSON.stringify(suggestiveQuestions) : item?.suggestiveQuestions,
                        summary: item?.l_id === lesson?.l_id || item?.l_id === lesson?.id ? JSON.stringify(summary) : item?.summary
                    }))
                }))
            }
        }).then(() => {
            setSuggestiveQuestions(null);
            setSummary(null);
        }).then(() => {
            setShowQuestionsScreen(false);
            setShowSummarySuggestiveForm(true);
        });
    }

    useEffect(() => {
        if (contentLink) {
            pdfjsLib
                .getDocument(contentLink)
                .promise.then((pdfDoc_: any) => {
                    setPdfDoc(pdfDoc_);
                    setNumberPages(pdfDoc_.numPages);
                    setLoadFail(false);
                })
                .catch((err: any) => {
                    setLoadFail(true);
                });
        }
    }, [contentLink]);

    useEffect(() => {
        if (pdfDoc) {
            renderPage(1);
        }
    }, [pdfDoc]);

    useEffect(() => {
        if (blobPath) {
            getGenerateUri();
        }
    }, [blobPath]);

    useEffect(() => {
        if ((summary || suggestiveQuestions) && pdfDoc) {
            const fieldValues = {
                summary: summary[pageNum - 1],
                questions: suggestiveQuestions ? suggestiveQuestions[pageNum - 1] : []
            };

            const newFieldValues = { ...fieldValues };
            form.setFieldsValue(newFieldValues);
            setShowSummarySuggestiveForm(false);
        } else {
            setShowSummarySuggestiveForm(true);
        }
    }, [pageNum, summary, suggestiveQuestions, pdfDoc]);

    useEffect(() => {
        if (lesson) {
            if(lesson?.summary !== null) { 
                setSummary(JSON.parse(lesson?.summary));
            } else {
                setSummary(null);
            }

            if(lesson?.suggestiveQuestions !== null) { 
                setSuggestiveQuestions(JSON.parse(lesson?.suggestiveQuestions));
            } else {
                setSuggestiveQuestions(null);
            }

            const parts = lesson && (lesson?.fileName?.split("/") || lesson?.contentLink?.split("/"));
            const lastPart = parts?.[parts?.length - 1];
            setFileName(lastPart);
        }
    }, [lesson]);

    useEffect(() => {
        if (generateUriResp?.generateSasGetUri) {
            setContentLink(generateUriResp?.generateSasGetUri?.url);
        }
    }, [generateUriResp]);

    const handleGenerateClick = async () => {
        try {
            await generatedForm.validateFields();
            const errors = generatedForm.getFieldsError();
            const hasErrors = errors.some(
                (fieldError) => fieldError.errors.length !== 0
            );

            if (!hasErrors) {
                const formData = generatedForm.getFieldsValue();
                setIsLoading(true);
                message.info("Suggestive questions and summary data generation started");

                const generateSummarySuggestiveResult = await generateSummarySuggestiveRelData({
                    variables: {
                        lessonId: lesson?.l_id || lesson?.id,
                        courseId: courseId,
                        numOfSuggestiveQuestions: formData?.suggestiveQuestions,
                        generateSummary: formData?.summary
                    },
                });

                const { questionGenerationTrackingId, summaryGenerationTrackingId } = generateSummarySuggestiveResult?.data?.GenerateSummarySuggestiveQuestions ?? {};

                let status_sugg = TASK_STATUS.DATA_GENERATION_IN_PROGRESS;
                let status_summ;

                if (summaryGenerationTrackingId) {
                    status_summ = summaryGenerationTrackingId;
                }

                message.loading("Suggestive questions and summary data generation is in progress.");
                while (status_sugg === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status_sugg === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status_sugg === TASK_STATUS.DATA_PROGRESS || status_summ === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status_summ === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status_summ === TASK_STATUS.DATA_PROGRESS) {
                    let statusResultSuggResult, statusResultSummResult;

                    if (questionGenerationTrackingId) {
                        statusResultSuggResult = await getTaskStatus({
                            variables: { taskTrackingId: [questionGenerationTrackingId] }
                        });

                        status_sugg = statusResultSuggResult?.data?.getTaskStatus[0]?.taskStatus;
                    }

                    if (summaryGenerationTrackingId) {
                        statusResultSummResult = await getTaskStatus({
                            variables: { taskTrackingId: [summaryGenerationTrackingId] }
                        });

                        status_summ = statusResultSummResult?.data?.getTaskStatus[0]?.taskStatus;
                    }

                    message.loading("Suggestive questions and summary data generation is in progress.");

                    if (status_sugg === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status_sugg === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status_sugg === TASK_STATUS.DATA_PROGRESS || status_summ === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status_summ === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status_summ === TASK_STATUS.DATA_PROGRESS) {
                        await new Promise((resolve) => setTimeout(resolve, 10000));
                    }
                }

                if (status_sugg === "FAILED" || status_summ === "FAILED") {
                    setIsLoading(false);
                    message.error("Something went wrong. Please try again.");
                    return;
                }

                const fetchSummarySuggestiveResult = await fetchSummarySuggestive({
                    variables: {
                        lessonId: lesson?.l_id || lesson?.id
                    }
                });

                const resultObj = fetchSummarySuggestiveResult?.data?.lessonDetails;

                if (resultObj === null || resultObj?.suggestiveQuestions === null) {
                    setIsLoading(false);
                    message.error("Something went wrong. Please try again.");
                    return;
                }

                if (resultObj?.summary) {
                    setSummary(JSON.parse(resultObj?.summary));
                }

                setSuggestiveQuestions(JSON.parse(resultObj?.suggestiveQuestions));
                setIsLoading(false);
                message.success("Suggestive questions and summary data generated successfully.");
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const onInputChange = (field, event, index = 0) => {
        if(field === "summary") {
            const updatedSummary = [...summary];
            updatedSummary[pageNum - 1] = event.target.value;
            setSummary(updatedSummary);
        } 

        if(field === "suggestiveQuestions") {
            const updatedQuestions = [...suggestiveQuestions];
            updatedQuestions[pageNum - 1][index] = event.target.value;
            setSuggestiveQuestions(updatedQuestions);
        }
    } 

    return (
        <>
            {lesson === undefined || lesson === null ? (
                <div className="spin-container">
                    <Spin size="large" />
                </div>
            ) : 
            (
                <Row gutter={[24, 24]} className="question-container">
                    <Row className="upload-content-container">
                        <Col className="upload-content-question-box" span={24}>
                            <Row className="question-box-title">
                                <Col>
                                    <Text className="header-5 capitalize course-question-header">
                                        {`Uploaded File`}
                                    </Text>
                                </Col>
                            </Row>

                            <Col span={24} className="question-box-content">
                                <Text className="question-box-content-header">
                                    {`${lesson?.lessonName}`}
                                </Text>
                            </Col>

                            <Col span={24} className="upload-content">
                                <span
                                >
                                    <LinkOutlined />

                                    <Text className="ml-14 upload-content-text">
                                        {fileName}
                                    </Text>
                                </span>

                                {/* <span>
                                    <Upload
                                        showUploadList={false}
                                        maxCount={1}
                                        accept=".pdf"
                                    >
                                        <Button icon={<EditOutlined />}>
                                        </Button>
                                    </Upload>
                                </span> */}
                            </Col>
                        </Col>
                    </Row>

                    <Row className="course-content-question-header">
                        <Col
                            span={22}
                            className="content-question-left"
                        >
                            <Text className="header-5 capitalize course-question-header">
                                {`${lesson?.lessonName}`}
                            </Text>
                        </Col>

                        <Col
                            span={2}
                            className="content-question-right"
                        >
                            <Tooltip title="Previous Page">
                                <span
                                >
                                    <img
                                        src={icons.caretLeft}
                                        alt=""
                                        style={{ marginRight: "0" }}
                                        onClick={showPrevPage}
                                    />
                                </span>
                            </Tooltip>

                            <span className="slide-container-text">
                                {`Slide ${pageNum}/${numberPages}`}
                            </span>

                            <Tooltip title="Next Page">
                                <span
                                >
                                    <img
                                        src={icons.caretRight}
                                        alt=""
                                        style={{ marginRight: "0" }}
                                        onClick={showNextPage}
                                    />
                                </span>
                            </Tooltip>
                        </Col>
                    </Row>

                    <Col xs={24} lg={24} className="add-question-box-container">
                        <Col className="course-media-question-container" xs={24} lg={12}>
                            {loadFail ?
                                <div className="pdf-loading-spinner">
                                    <Spin size="large" />
                                </div> :
                                <canvas
                                    id="pdf-render"
                                    className="canvas pdf-question-container"
                                />
                            }
                        </Col>

                        <Col className="generate-content-container" xs={24} lg={12}>
                            <Col className="generate-content-question-box">
                                {!showSummarySuggestiveForm && (
                                    <>
                                        <Row className="mb-20 question-box-title mr-12 ml-12">
                                            <Col>
                                                <Row>
                                                    <span className="question-box-title-text">
                                                        {" "}
                                                        {"Generated Content"}
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Form form={form}>
                                            <Row gutter={[24, 24]} className="mb-20">
                                                <Col span={24}>
                                                    <Col lg={12} xs={24} className="label mt-2 mb-8">
                                                        Generated Summary
                                                    </Col>
                                                    <Col lg={24} xs={24}>
                                                        <Form.Item
                                                            name="summary"
                                                            className="mb-2"
                                                        >
                                                            <TextArea
                                                                size="large"
                                                                onChange={(event) => onInputChange("summary", event)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Col>

                                                <Col span={24}>
                                                    {suggestiveQuestions &&
                                                        suggestiveQuestions[pageNum - 1] && (
                                                            <Col lg={12} xs={24} className="label mt-2 mb-8">
                                                                Generated Suggestive Questions
                                                            </Col>
                                                        )
                                                    }

                                                    <Col lg={24} xs={24}>
                                                        <Form.Item
                                                            name="questions"
                                                            className="mb-20"
                                                        >
                                                            {suggestiveQuestions &&
                                                                suggestiveQuestions[pageNum - 1]?.map((question, index) => (
                                                                    <TextArea
                                                                        key={index}
                                                                        value={question}
                                                                        size="large"
                                                                        style={{ marginBottom: '16px' }}
                                                                        onChange={(event) => onInputChange("suggestiveQuestions", event, index)}
                                                                    />
                                                                ))
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                </Col>
                                            </Row>

                                            <Row className="flex flex--space-between mr-12 ml-12 button-container" style={(suggestiveQuestions && !suggestiveQuestions[pageNum - 1]) ? { marginTop: '-70px'} : {}}>
                                                <Col></Col>

                                                <Col>
                                                    <Button className="save-assessment-button" onClick={() => onSave()}>Save</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </>
                                )}

                                {showSummarySuggestiveForm && (
                                    <>
                                        <Row className="mb-20 question-box-title mr-12 ml-12">
                                            <Col>
                                                <Row>
                                                    <span className="question-box-title-text">
                                                        {" "}
                                                        {`Lesson-${lesson?.lessonName}`}
                                                    </span>
                                                </Row>
                                                <Row><span>Please fill the below details</span></Row>
                                            </Col>
                                        </Row>

                                        <Form form={generatedForm}>
                                            <Row gutter={[24, 24]} className="mb-20">
                                                <Col lg={24} xs={24}>
                                                    <Col lg={12} xs={24} className="label mt-2 mb-8">
                                                        Generated summary
                                                    </Col>

                                                    <Col lg={24} xs={24}>
                                                        <Form.Item
                                                            name="summary"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Please select summary`,
                                                                }
                                                            ]}
                                                            className="mb-0"
                                                        >
                                                            <Radio.Group name="radiogroup">
                                                                <Radio value={1}>Yes</Radio>
                                                                <Radio value={0}>No</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Col>

                                                <Col lg={24} xs={24}>
                                                    <Col lg={12} xs={24} className="label mt-2 mb-8">
                                                        No. of suggestive question
                                                    </Col>
                                                    <Col lg={24} xs={24}>
                                                        <Form.Item
                                                            name="suggestiveQuestions"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Please input number of suggestive questions`,
                                                                },
                                                                {
                                                                    validator: (_, value) => {
                                                                        if (value >= 0) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(new Error('Please enter a positive number.'));
                                                                    },
                                                                }
                                                            ]}
                                                            className="mb-0"
                                                        >
                                                            <Input
                                                                size="large"
                                                                placeholder="Enter number of suggestive questions"
                                                                type="number"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Col>
                                            </Row>

                                            <Row
                                                gutter={[24, 24]}
                                                className="mt-24"
                                                style={{ marginLeft: "0", marginRight: "0" }}
                                            >
                                                <Col span={24}>
                                                    <Button className="generate-question-button" onClick={() => handleGenerateClick()} disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <>
                                                                <div className="overlay"></div>
                                                                <Spin indicator={antIcon} style={{ color: 'white' }} /> Loading
                                                            </>
                                                        ) : ('Generate')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </>
                                )}
                            </Col>
                        </Col>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default SuggestiveQuestionsAndSummary;