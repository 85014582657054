import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Layout } from "antd";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import LoginAndSignupRedirect from "./component/LoginSignUpRedirect";
import Home from "./pages/home";

function MainContent() {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount() || accounts[0];

  const redirectIfTokenExpired = () => {
    instance.loginRedirect();
    activeAccount &&
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: activeAccount,
        })
        .then((response) => {
          if (response) {
            localStorage.setItem(
              "userData",
              JSON.stringify({
                account: response.account,
                accessToken: response.accessToken,
              })
            );
          }
        });
  };



  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Layout className="app-container">
            <Router basename={""}>
              <Routes>
                <React.Fragment></React.Fragment>
                <Route path="*" element={<Home />} />
              </Routes>
            </Router>
          </Layout>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginAndSignupRedirect />
      </UnauthenticatedTemplate>
    </div>
  );
}

const App = ({ instance }: { instance: any }) => (
  <MsalProvider instance={instance}>
    <MainContent />
  </MsalProvider>
);

export default App;
