import { TIME_TYPES } from "../../lib/Constants";
import { URLS } from "../../urls";

export const FILTER_SESSION = "FILTER_SESSION";

/**
 * Global constants for Cumulus
 */
export const NAVBAR_KEY_MAPPER = {
  home: URLS.HOME_SELF_LEARNING,
  "course-details": URLS.HOME_SELF_LEARNING,
  "my-learnings": URLS.MY_LEARNINGS,
  review: URLS.REVIEW_COURSES,
  settings: URLS.SETTINGS,
  content: URLS.MY_COURSES,
  configure: URLS.CONFIGURE,
};

export const USER_COURSE_STATUS = {
  "In Progress": "IN_PROGRESS",
  Completed: "COMPLETED",
  Incompleted: "INCOMPLETE",
};

export const ASSESSMENT_STATUS = {
  DRAFT: "DRAFT",
  READY_FOR_INFRA: "READY_FOR_INFRA",
  READY_FOR_USE: "READY_FOR_USE",
  FAILED: "FAILED",
};

export const ASSESSMENT_TYPES = {
  INDEPENDENT: "IND",
  PROGRESSIVE: "PRG",
};

export const COURSE_STATUS = {
  DRAFT: "DRAFT",
  UNDER_REVIEW: "UNDER_REVIEW",
  PUBLISHED: "PUBLISHED",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  REMOVED: "REMOVED",
};

export const REVIEW_STATUS = {
  REJECTED: "Rejected",
  APPROVED: "Approved",
  NOT_REVIEWED: "Not reviewed",
};

export const TIME_OPTIONS = [
  { label: "Min(s)", value: TIME_TYPES.MINS },
  { label: "Hour(s)", value: TIME_TYPES.HOURS },
  { label: "Day(s)", value: TIME_TYPES.DAYS },
];

export const confirmationModalInitialState = {
  visible: false,
  img: "",
  title: "",
  text: "",
  extraText: "",
  warningText: "",
  buttonText: "Confirm",
  cancelButonText: "Cancel",
  onSuccess: () => {
    /**/
  },
  onCancel: () => {
    /**/
  },
};

export const S3_PATH_LOCATION = {
  BUCKET_CONTENT_DIR: "content",
};

export const LAB_TYPE = {
  TECHNICAL: "technical",
  QUIZ: "quiz",
  CASE_STUDY: "case study",
};

export const USER_COURSE_RELATION_TYPE = {
  CONTENT_CREATOR: "C",
  TRAINEE: "T",
  REVIEWER: "R",
};

export const USER_CONFIG_ROLES = {
  REVIEWER: "R",
  CONTENT_CREATOR: "C",
  SUPER_ADMIN: "SA",
  EXECUTIVE_DIRECTOR: "ED",
  PROGRAM_MANAGER: "PM",
  COLLABORATOR: "CO",
};

export const ROLE_COLOR_CONFIG = {
  [USER_CONFIG_ROLES.COLLABORATOR]: "geekblue",
  [USER_CONFIG_ROLES.CONTENT_CREATOR]: "gold",
};

export const USER_CONFIG_ROLES_ABBREVIATION = {
  [USER_CONFIG_ROLES.REVIEWER]: "Reviewer",
  [USER_CONFIG_ROLES.CONTENT_CREATOR]: "Content Creator",
  [USER_CONFIG_ROLES.SUPER_ADMIN]: "Super Admin",
  [USER_CONFIG_ROLES.EXECUTIVE_DIRECTOR]: "Executive Director",
  [USER_CONFIG_ROLES.PROGRAM_MANAGER]: "Program Manager",
  [USER_CONFIG_ROLES.COLLABORATOR]: "Collaborator",
};

export const DEFAULT_PLAGIARISM_RANGES = {
  5: { from: 0, to: 20 },
  4: { from: 20.01, to: 30 },
  3: { from: 30.01, to: 50 },
  2: { from: 50.01, to: 70 },
  1: { from: 70.01, to: 100 },
};

export const DEFAULT_TEST_SUITE_PERCENTAGE_RANGES = {
  5: { from: 100, to: 90 },
  4: { from: 89.99, to: 75 },
  3: { from: 74.99, to: 60 },
  2: { from: 59.99, to: 40 },
  1: { from: 39.99, to: 0 },
};

export const DEFAULT_QUALITY_BUGS_RANGES = {
  5: { from: 0, to: 1 },
  4: { from: 2, to: 10 },
  3: { from: 11, to: 20 },
  2: { from: 21, to: 30 },
  1: { from: 31, to: "" },
};

export const DEFAULT_QUALITY_COVERAGE_PERCENTAGE_RANGES = {
  5: { from: 100, to: 80.01 },
  4: { from: 80, to: 60.01 },
  3: { from: 60, to: 40.01 },
  2: { from: 40, to: 20.01 },
  1: { from: 20, to: 0 },
};

export const DEFAULT_DUPLICATE_LINES_PERCENTAGE_RANGES = {
  5: { from: 0, to: 1 },
  4: { from: 1.01, to: 2 },
  3: { from: 2.01, to: 5 },
  2: { from: 5.01, to: 10 },
  1: { from: 10.01, to: 100 },
};

export const DEFAULT_VULNERABILITY_PERCENTAGE_RANGES = {
  5: { from: 0, to: 1 },
  4: { from: 2, to: 10 },
  3: { from: 11, to: 20 },
  2: { from: 21, to: 30 },
  1: { from: 31, to: "" },
};

export const REVIEW_EXECUTION_COMBINED_STATUS = {
  NOT_STARTED: -1,
  SOME_FAILED: 0,
  ALL_PASSED: 1,
};

export const COLLABORATOR_CONFIGS = {
  LIMIT: 5,
  MAX_TAG_LIMIT: 5,
};

export const ERROR_PERMISSION_EDIT =
  "You don't have the permission to edit the course.";

export const COURSE_REVIEW_FIELDS = {
  COURSE_MIX_DETAILS: "M",
  COURSE_DESCRIPTION: "CD",
  COURSE_WHAT_WE_LEARN: "WYWL",
  COURSE_PREREQUISITE: "P",
};

export const COURSE_REVIEW_FIELDS_ABREVIATION = {
  [COURSE_REVIEW_FIELDS.COURSE_MIX_DETAILS]: "Course Summary",
  [COURSE_REVIEW_FIELDS.COURSE_DESCRIPTION]: "Course Description",
  [COURSE_REVIEW_FIELDS.COURSE_WHAT_WE_LEARN]: "Course What you will learn",
  [COURSE_REVIEW_FIELDS.COURSE_PREREQUISITE]: "Course Prerequisite",
};

export const ERROR_FIELD_KEY = {
  COURSE: "Course Reviews",
  ASSESSMENT: "Assessment Reviews",
  QUIZ: "Quiz Reviews",
};

export const ASSESSMENT_STATUS_DISPLAY = {
  COMPLETED: "Completed",
  CONTINUE_EDITING: "Continue Editing",
  INCOMPLETE: "Incomplete",
};

export const DEFAULT_PASSING_CRITERIA_RANGES_QUIZ = {
  5: { from: 100, to: 95.01 },
  4: { from: 95, to: 85.01 },
  3: { from: 85, to: 75.01 },
  2: { from: 75, to: 50.01 },
  1: { from: 50, to: 0 },
};

export const TIME_FORMAT = {
  "12HR": "h:mm A",
  "24HR": "HH:mm",
};

export const COURSE_CONTENT_BREADCRUMB_TILES = {
  SECTION_DETAILS: "Section Details",
  SEGMENTS: "Segments"
}

export const TASK_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  STARTED: "STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  DATA_GENERATION_IN_PROGRESS: "DATA_GENERATION_IN_PROGRESS",
  DATA_LOAD_IN_PROGRESS: "DATA_LOAD_IN_PROGRESS",
  DATA_PROGRESS: "DATA_PROGRESS"
}

export const ADMIN_MODAL_MODIFY = "Please note that the assessments and quizzes generated are based on the previously uploaded lesson and remain static. Should you require any changes, feel free to regenerate the quiz/assessment as needed.";

export const ALLOWED_TYPES_FOR_IMAGES = ['image/png', 'image/jpeg', 'image/jpg'];