import { Button, Col, Row, Form, Spin } from "antd";
import { useEffect, useState } from "react";

import { useParams } from "react-router";

import { useLazyQueryData, useMutationData } from "services";
import {
  ACKNOWLEDGE_TASK,
  EVALUATE_ASSESSMENT_PROXY,
  FETCH_MINI_ASSESSMENT,
  GET_MINI_ASSESSMENT_QUESTION_DETAILS,
  GET_MINI_ASSESSMENT_RESULT,
  GET_TASK_STATUS_MINI_ASSESSMENT,
  PENDING_TASKS,
} from "services/Queries";

import {
  CREATE_SAVE_ASSESSMENT,
  SUBMIT_MINI_ASSESSMENT,
  UPDATE_SAVE_ASSESSMENT,
} from "services/Mutation";
import "./UserAssessment.scss";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import { icons } from "assets";
import { TASK_STATUS } from "component/lib/Constants";
import { debounce } from "lodash";
const UserAssessment = ({
  miniAssessmentId,
  setEvaluationCriteria,
  setAssessmentQuestions,
  updateUserAnswers,
  userAnswers,
  curMiniAssessmentRelation,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  userScores,
  updateUserScores,
  isAssessmentResultLoading,
  setIsAssessmentResultLoading,
}) => {
  const { id: courseId } = useParams();
  const [assessmentForm] = Form.useForm();
  const [isSolutionSubmitted, SetIsSolutionSubmitted] = useState(false);
  const [submitAssessmentData] = useMutationData(SUBMIT_MINI_ASSESSMENT);
  const [evaluateAssessmentProxy] = useLazyQueryData(EVALUATE_ASSESSMENT_PROXY);
  const [getTaskStatus] = useLazyQueryData(GET_TASK_STATUS_MINI_ASSESSMENT);
  const [getPendingTasks] = useLazyQueryData(PENDING_TASKS);
  const [acknowledgeTask] = useLazyQueryData(ACKNOWLEDGE_TASK);
  const [getResult, { loading: resultLoading }] = useLazyQueryData(
    GET_MINI_ASSESSMENT_RESULT
  );
  const [isLoading, setIsLoading] = useState(false);
  const [taskTrackingId, setTaskTrackingId] = useState("");

  const [AssessmentData, setAssessmentData] = useState<any>();

  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [assessmentDetails, setAssessmentDetails] = useState<any>();
  const [userRelIds, setUserRelIds] = useState<Array<any>>(
    new Array(assessmentDetails?.miniassessmentquestionsSet?.length).fill("")
  );

  const [createAssessmentRelData] = useMutationData(CREATE_SAVE_ASSESSMENT);
  const [updateAssessmentRelData] = useMutationData(UPDATE_SAVE_ASSESSMENT);
  const [currRelId, setCurrRelId] = useState();
  const [textFieldValue, setTextFieldValue] = useState("");

  const CalcNumberOfQuestionsCompleted = () => {
    let score = 0;
    for (let i = 0; i < userScores.length; i++) {
      if (userScores[i] !== "-") {
        score = score + 1;
      }
    }
    return score;
  };

  const [
    getAssessmentDetails,
    { data: assessmentDetailsResp, loading: assessmentDetailsRespLoading },
  ] = useLazyQueryData(FETCH_MINI_ASSESSMENT, {
    miniAssessmentId,
    userId: localStorage.getItem("userId"),
    courseId,
  });

  const [
    getModuleAssessmentDetails,
    {
      data: moduleAssessmentDetailsResp,
      loading: moduleAssessmentDetailsLoading,
    },
  ] = useLazyQueryData(GET_MINI_ASSESSMENT_QUESTION_DETAILS, {
    courseId: courseId,
  });

  useEffect(() => {
    if (moduleAssessmentDetailsResp) {
      SetIsSolutionSubmitted(false);
      getModuleAssessmentDetails();

      setIsLoading(false);
      setIsAssessmentResultLoading(false);
      assessmentForm.setFieldsValue({
        solution:
          assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
            ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.skeletonCode,
      });
      // setCode("");

      setEvaluationCriteria(
        assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
          ?.miniassessmentquestionsSet?.[currentQuestionIndex]
          ?.evaluationCriteria
      );

      if (userRelIds[currentQuestionIndex]) {
        fetchQuestionReslt(currentQuestionIndex);
      } else {
        const miniAssessment =
          moduleAssessmentDetailsResp?.fetchUserMiniAssessmentRelation?.find(
            (item) => {
              return item?.miniAssessment?.id === miniAssessmentId;
            }
          );
        const data =
          miniAssessment?.userminiassessmentquestionrelationSet?.find(
            (item) => {
              return (
                item?.question?.id &&
                item?.question?.id ===
                  assessmentDetailsResp?.fetchMiniAssessmentDetails
                    ?.miniAssessment?.miniassessmentquestionsSet?.[
                    currentQuestionIndex
                  ]?.id
              );
            }
          );

        if (data) {
          setCurrRelId(data?.id);
          getPendingTasks({
            variables: {
              courseId: courseId,
              moduleId: null,
              userMiniAssessmentRelationId: curMiniAssessmentRelation?.id
            }
          }).then((pendingData) => {
            const activeTaskTrackingObj = pendingData?.data?.getPendingTasks?.find((obj) => {
              if (obj.taskType === "ASSESSMENT_EVALUATION" && 
              JSON.parse(obj.taskConfig)?.questionId === assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
              ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id) {
                return obj;
              }
            });

            if (data?.isEvaluated && !activeTaskTrackingObj) {
              fetchQuestionResltS(currentQuestionIndex, data?.id);
            } else {
              //if not evaluated then call update mutation and retrive the code
    
              assessmentForm.setFieldsValue({
                solution: data?.solution,
              });
            }
          });
        } else {
          //if not present then call create mutation and retrive the relation id

          const fetchData = async () => {
            try {
              const formData = assessmentForm.getFieldsValue();
              const code = formData?.solution;

              const createRel = await createAssessmentRelData({
                variables: {
                  codeSolution: code,
                  courseId: courseId,
                  miniassessmentId: miniAssessmentId,
                  miniassessmentquestionId:
                    assessmentDetailsResp?.fetchMiniAssessmentDetails
                      ?.miniAssessment?.miniassessmentquestionsSet?.[
                      currentQuestionIndex
                    ]?.id,
                  userId: localStorage.getItem("userId"),
                },
              });
              const { miniassessmentQuestionRelationId } =
                createRel.data?.createMiniAssessmentQuestionRelation ?? {};
              setCurrRelId(miniassessmentQuestionRelationId);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          fetchData();
        }
      }
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    getAssessmentDetails();

    getModuleAssessmentDetails();
  }, []);

  useEffect(() => {
    setAssessmentDetails(
      assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
    );

    setAssessmentQuestions(
      assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
        ?.miniassessmentquestionsSet
    );
    setEvaluationCriteria(
      assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
        ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.evaluationCriteria
    );
    assessmentForm.setFieldsValue({
      solution:
        assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
          ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.skeletonCode,
    });

    let l =
      assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
        ?.miniassessmentquestionsSet?.length;
    let ans = userAnswers;
    let score = userScores;
    for (let i = 0; i < l; i++) {
      const data =
        curMiniAssessmentRelation?.userminiassessmentquestionrelationSet?.find(
          (item) => {
            return (
              item?.question?.id &&
              item?.question?.id ===
                assessmentDetailsResp?.fetchMiniAssessmentDetails
                  ?.miniAssessment?.miniassessmentquestionsSet?.[i]?.id
            );
          }
        );
      if (data?.isEvaluated) {
        ans[i] = "answered";
        if (data?.scoreAchieved || data?.scoreAchieved === 0) {
          score[i] = data.scoreAchieved;
        }
      }
    }
    updateUserScores(score);
    updateUserAnswers(ans);
    if (curMiniAssessmentRelation) {
      const data =
        curMiniAssessmentRelation?.userminiassessmentquestionrelationSet?.find(
          (item) => {
            return (
              item?.question?.id &&
              item?.question?.id ===
                assessmentDetailsResp?.fetchMiniAssessmentDetails
                  ?.miniAssessment?.miniassessmentquestionsSet?.[
                  currentQuestionIndex
                ]?.id
            );
          }
        );
      if (data) {
        setCurrRelId(data?.id);
        getPendingTasks({
          variables: {
            courseId: courseId,
            moduleId: null,
            userMiniAssessmentRelationId: curMiniAssessmentRelation?.id
          }
        }).then((pendingData) => {
          const activeTaskTrackingObj = pendingData?.data?.getPendingTasks?.find((obj) => {
            if (obj.taskType === "ASSESSMENT_EVALUATION" && 
            JSON.parse(obj.taskConfig)?.questionId === assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
            ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id) {
              return obj;
            }
          });

          if (data?.isEvaluated && !activeTaskTrackingObj) {
            fetchQuestionResltS(currentQuestionIndex, data?.id);
          } else {
            //if not evaluated then call update mutation and retrive the code
  
            assessmentForm.setFieldsValue({
              solution: data?.solution,
            });
          }
        });
      } else {
        //if not present then call create mutation and retrive the relation id

        const fetchData = async () => {
          try {
            const formData = assessmentForm.getFieldsValue();
            const code = formData?.solution;

            const createRel = await createAssessmentRelData({
              variables: {
                codeSolution: code,
                courseId: courseId,
                miniassessmentId: miniAssessmentId,
                miniassessmentquestionId:
                  assessmentDetailsResp?.fetchMiniAssessmentDetails
                    ?.miniAssessment?.miniassessmentquestionsSet?.[
                    currentQuestionIndex
                  ]?.id,
                userId: localStorage.getItem("userId"),
              },
            });
            const { miniassessmentQuestionRelationId } =
              createRel.data?.createMiniAssessmentQuestionRelation ?? {};
            setCurrRelId(miniassessmentQuestionRelationId);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    }
  }, [assessmentDetailsResp]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getPendingData = await getPendingTasks({
          variables: {
            courseId: courseId,
            moduleId: null,
            userMiniAssessmentRelationId: curMiniAssessmentRelation?.id
          }
        });

        if (getPendingData?.data?.getPendingTasks?.length === 0) {
          return;
        }

        const activeTaskTrackingObj = getPendingData?.data?.getPendingTasks?.find((obj) => {
          if (obj.taskType === "ASSESSMENT_EVALUATION" && 
          JSON.parse(obj.taskConfig)?.questionId === assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
          ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id) {
            return obj;
          }
        });

        if (activeTaskTrackingObj === undefined) {
          return;
        }

        setIsLoading(true);
        let status = TASK_STATUS.DATA_GENERATION_IN_PROGRESS;

        while (
          status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status === TASK_STATUS.DATA_PROGRESS
        ) {
          const statusResult = await getTaskStatus({
            variables: { taskTrackingId: [activeTaskTrackingObj?.taskTrackingId] },
          });

          status = statusResult?.data?.getTaskStatus?.[0]?.message;

          if (
            status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status === TASK_STATUS.DATA_PROGRESS
          ) {
            await new Promise((resolve) => setTimeout(resolve, 10000));
          }
        }

        const fetchAssessmentsResult = await getResult({
          variables: {
            courseId: courseId,
            userId: localStorage.getItem("userId"),
            questionId:
              assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
                ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id,
            miniAssessmentQuestionRelationId: currRelId,
          },
        });

        acknowledgeTask({
          variables: {
            taskTrackingId: activeTaskTrackingObj?.taskTrackingId
          }
        });

        const assessmentObj = fetchAssessmentsResult?.data;
        setAssessmentData(assessmentObj);
        let score = userScores;
        score[currentQuestionIndex] =
          fetchAssessmentsResult?.data?.fetchMiniAssessmentResultDetails?.scoreAchieved;
        updateUserScores(score);
        setIsLoading(false);
        setIsAssessmentResultLoading(false);
        SetIsSolutionSubmitted(true);
      } catch(error) {

      }
    } 

    fetchData();
  }, [currentQuestionIndex]);

  const fetchQuestionResltS = async (index, id) => {
    const fetchAssessmentsResult = await getResult({
      variables: {
        courseId: courseId,
        userId: localStorage.getItem("userId"),
        questionId:
          assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
            ?.miniassessmentquestionsSet?.[index]?.id,
        miniAssessmentQuestionRelationId: id,
      },
    });
    let arr = userRelIds;
    arr[index] = id;
    setUserRelIds(arr);

    const assessmentObj = fetchAssessmentsResult?.data;
    setAssessmentData(assessmentObj);
    assessmentForm.setFieldsValue({
      solution:
        fetchAssessmentsResult?.data?.fetchMiniAssessmentResultDetails
          ?.solution,
    });

    SetIsSolutionSubmitted(true);
  };

  const fetchQuestionReslt = async (index) => {
    const fetchAssessmentsResult = await getResult({
      variables: {
        courseId: courseId,
        userId: localStorage.getItem("userId"),
        questionId:
          assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
            ?.miniassessmentquestionsSet?.[index]?.id,
        miniAssessmentQuestionRelationId: userRelIds[index],
      },
    });

    const assessmentObj = fetchAssessmentsResult?.data;
    setAssessmentData(assessmentObj);
    assessmentForm.setFieldsValue({
      solution:
        fetchAssessmentsResult?.data?.fetchMiniAssessmentResultDetails
          ?.solution,
    });

    SetIsSolutionSubmitted(true);
  };

  const handleSubmitQuestion = async () => {
    try {
      await assessmentForm.validateFields();
      const errors = assessmentForm.getFieldsError();
      const hasErrors = errors.some(
        (fieldError) => fieldError.errors.length !== 0
      );

      if (!hasErrors) {
        setIsLoading(true);
        setIsAssessmentResultLoading(true);
        const formData = assessmentForm.getFieldsValue();
        const codeSolution = formData?.solution;

        const submitResult = await submitAssessmentData({
          variables: {
            codeSolution: codeSolution,
            userId: localStorage.getItem("userId"),
            miniassessmentId: miniAssessmentId,
            miniassessmentQuestionRelationId: currRelId,
            courseId: courseId,
            miniassessmentquestionId:
              assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
                ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id,
            completed:
              assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
                ?.miniassessmentquestionsSet?.length ===
              CalcNumberOfQuestionsCompleted() + 1,
          },
        });

        const { ok, miniassessmentQuestionRelationId } =
          submitResult.data?.submitMiniAssessment ?? {};
        let arr = userRelIds;
        arr[currentQuestionIndex] = miniassessmentQuestionRelationId;
        setUserRelIds(arr);

        let ans = userAnswers;
        ans[currentQuestionIndex] = codeSolution;
        updateUserAnswers(ans);

        const evalResult = await evaluateAssessmentProxy({
          variables: {
            miniAssessmentQuestionRelationId: currRelId,
            courseId: courseId,
            questionId:
              assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
                ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id,
          },
        });

        const { taskTrackingId, success } =
          evalResult?.data?.evaluateAssessment ?? {};

        if (!success) {
          setIsLoading(false);
          setIsAssessmentResultLoading(false);
          return;
        }

        setTaskTrackingId(taskTrackingId);
        let status = TASK_STATUS.DATA_GENERATION_IN_PROGRESS;
        let taskConfig = "";

        while (
          status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status === TASK_STATUS.DATA_PROGRESS
        ) {
          const statusResult = await getTaskStatus({
            variables: { taskTrackingId: [taskTrackingId] },
          });

          status = statusResult?.data?.getTaskStatus?.[0]?.message;
          taskConfig =
            statusResult?.data?.getTaskStatus?.taskTrackingDetails?.[0]?.taskConfig;

          if (
            status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS || status === TASK_STATUS.DATA_LOAD_IN_PROGRESS || status === TASK_STATUS.DATA_PROGRESS
          ) {
            await new Promise((resolve) => setTimeout(resolve, 10000));
          }
        }

        const fetchAssessmentsResult = await getResult({
          variables: {
            courseId: courseId,
            userId: localStorage.getItem("userId"),
            questionId:
              assessmentDetailsResp?.fetchMiniAssessmentDetails?.miniAssessment
                ?.miniassessmentquestionsSet?.[currentQuestionIndex]?.id,
            miniAssessmentQuestionRelationId: currRelId,
          },
        });

        acknowledgeTask({
          variables: {
            taskTrackingId: taskTrackingId
          }
        });

        const assessmentObj = fetchAssessmentsResult?.data;
        setAssessmentData(assessmentObj);
        let score = userScores;
        score[currentQuestionIndex] =
          fetchAssessmentsResult?.data?.fetchMiniAssessmentResultDetails?.scoreAchieved;
        updateUserScores(score);
        setIsLoading(false);
        setIsAssessmentResultLoading(false);
        SetIsSolutionSubmitted(true);
      }
    } catch (error) {}
  };

  useEffect(() => {}, [taskTrackingId]);

  const saveTextToBackend = debounce((text) => {
    // Simulated API call (replace with your actual API call)

    updateAssessmentRelData({
      variables: {
        codeSolution: text,
        miniassessmentQuestionRelationId: currRelId,
      },
    });
  }, 1000);

  const handleTextFieldChange = (newvalue) => {
    saveTextToBackend(newvalue);
  };

  return (
    <>
      {assessmentDetailsRespLoading || resultLoading ? (
        <div className="pdf-loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <Row
          className="quiz-question-section"
          style={isLoading ? { cursor: "not-allowed" } : {}}
        >
          <Col
            // lg={isSolutionSubmitted ? 8 : 24}
            style={isSolutionSubmitted ? { width: "35%" } : { width: "100%" }}
            className={isSolutionSubmitted ? "assessment-border" : ""}
          >
            <Col span={20} className="quiz-question-section-question">
              <span className="question-content mr-10">{`${
                currentQuestionIndex + 1
              }.`}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    assessmentDetails?.miniassessmentquestionsSet?.[
                      currentQuestionIndex
                    ]?.title,
                }}
                className="question-content"
              ></span>
            </Col>

            <Col lg={24} xs={24} className="mt-24">
              <Form form={assessmentForm} disabled={isLoading}>
                <Col lg={12} xs={24} className="label mb-8">
                  <span className="mandatory">* </span>
                  <span className="solution-content">
                    Solution
                  </span>
                </Col>
                <Form.Item
                  name="solution"
                  rules={[
                    {
                      required: true,
                      message: `Please input solution code`,
                    },
                  ]}
                  className="mb-30"
                >
                  <AceEditor
                    mode="python"
                    theme="monokai"
                    name="skeleton-code"
                    fontSize={14}
                    // showPrintMargin={true}
                    // showGutter={true}
                    width="100%"
                    height="500px"
                    value={textFieldValue}
                    // onChange={handleChange}
                    style={{ borderRadius: "8px" }}
                    onChange={handleTextFieldChange}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Col>

          <Col
            // lg={isSolutionSubmitted ? 15 : 0}
            style={
              isSolutionSubmitted
                ? { width: "63%" }
                : { width: "0%", display: "none" }
            }
            className={isSolutionSubmitted ? "assessment-border" : ""}
          >
            <Row className="verdict">
              <Col className="verdict-details">
                <Row className="eval-title">
                  <img src={icons.verdict} alt="" className="mr-8" />
                  Verdict:{" "}
                  {AssessmentData?.fetchMiniAssessmentResultDetails?.verdict}
                </Row>

                <Row className="eval-score">
                  <strong>Score: </strong>
                  <span>
                    {
                      AssessmentData?.fetchMiniAssessmentResultDetails
                        ?.scoreAchieved
                    }
                    /
                    {
                      AssessmentData?.fetchMiniAssessmentResultDetails
                        ?.totalScore
                    }
                  </span>
                </Row>
              </Col>
              <Col className="verdict-details">
                <Row className="eval-title">
                  <img src={icons.testCase} alt="" className="mr-8" />
                  Test Case
                </Row>
                <Row className="eval-score">
                  <strong> Number of Test cases Passed: </strong>

                  {AssessmentData?.fetchMiniAssessmentResultDetails?.testCases}
                </Row>
              </Col>
            </Row>
            <Col className="task">
              <Row className="eval-title">
                {" "}
                <img src={icons.task} alt="" className="mr-8" />
                Task
              </Row>
              <Row>
                <p className="eval-title">Efficiency</p>
                <div>
                  {AssessmentData?.fetchMiniAssessmentResultDetails?.efficiency?.map(
                    (item, index) => {
                      return <p>{item}</p>;
                    }
                  )}
                </div>
              </Row>
              <Row>
                <p className="eval-title">Code structure and organization</p>{" "}
                <div>
                  {AssessmentData?.fetchMiniAssessmentResultDetails?.codeStructure?.map(
                    (item, index) => {
                      return <p>{item}</p>;
                    }
                  )}
                </div>
              </Row>
              <Row>
                <p className="eval-title">Correctness</p>
                <div>
                  {AssessmentData?.fetchMiniAssessmentResultDetails?.codeCorrectness?.map(
                    (item, index) => {
                      return <p>{item}</p>;
                    }
                  )}
                </div>
              </Row>
              <Row>
                <p className="eval-title">Edge cases handling</p>
                <div>
                  {AssessmentData?.fetchMiniAssessmentResultDetails?.edgeCaseHandling?.map(
                    (item, index) => {
                      return <p>{item}</p>;
                    }
                  )}
                </div>
              </Row>
            </Col>

            <Row className="area-of-improvement">
              <Row>
                <p className="eval-title">
                  {" "}
                  <img src={icons.areaOfImprovement} alt="" className="mr-8" />
                  Areas of improvement
                </p>
                <div>
                  {AssessmentData?.fetchMiniAssessmentResultDetails?.areasOfImprovement?.map(
                    (item, index) => {
                      return <p>{item}</p>;
                    }
                  )}
                </div>
              </Row>
            </Row>
          </Col>

          <Row className="flex flex-space-between" style={{ width: "100%" }}>
            <Col></Col>
            <Col></Col>
            <Col className="mt-16">
              {!isSolutionSubmitted && (
                <Button
                  onClick={handleSubmitQuestion}
                  className="assessment-submit-button"
                  loading={isLoading}
                >
                  {isLoading ? "Loading" : "Submit"}
                </Button>
              )}
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
};

export default UserAssessment;
