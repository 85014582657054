import { Row, Typography, Col } from "antd";
import React, { useEffect, useState } from "react";
import { icons } from "../../assets";
import { ICardType } from "../../lib/Types";
import EnrolButton from "../buttons/EnrollButton";
import "./Rcourse.scss";
import { useLazyQueryData } from "services";
import { GENERATE_URI } from "services/Queries";
const RCourseCard: React.FC<ICardType> = ({ course }) => {
  const { Paragraph } = Typography;
  const [banner, setBanner] = useState(null);

  // Queries
  const [
    getGenerateUri,
    { data: generateUriResp, loading: generateUriLoading },
  ] = useLazyQueryData(GENERATE_URI, {
    blobPath: course?.courseBanner,
  });

  useEffect(() => {
    getGenerateUri();
  }, []);

  useEffect(() => {
    if (generateUriResp?.generateSasGetUri) {
      setBanner(generateUriResp?.generateSasGetUri?.url);
    }
  }, [generateUriResp]);

  return (
    <Col className="rcourse-card" span={24}>
      <div className="rcourse-image-container">
        <img className="rcourse-image" src={banner || undefined} alt="" />
      </div>
      <Col className="rcourse-content">
        <Row className="flex flex--space-between ">
          <p className="rcourse-title">{course.title}</p>
          <p className="rcourse-duration">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5.12759 3.09444C4.79384 2.76008 4.82694 2.20645 5.2201 1.94483C7.06697 0.715932 9.28331 0 11.6666 0C18.1099 0 23.3332 5.23285 23.3332 11.6879C23.3332 18.1429 18.1099 23.3757 11.6666 23.3757C5.22333 23.3757 0 18.1429 0 11.6879C0 9.08572 0.848807 6.68218 2.28418 4.74001C2.55523 4.37325 3.08542 4.35435 3.4075 4.67705C3.69427 4.9643 3.71157 5.42161 3.47361 5.75058C2.26696 7.41883 1.55555 9.47013 1.55555 11.6879C1.55555 17.2822 6.08242 21.8173 11.6666 21.8173C17.2508 21.8173 21.7777 17.2822 21.7777 11.6879C21.7777 6.09351 17.2508 1.55838 11.6666 1.55838C9.63859 1.55838 7.75004 2.15653 6.16712 3.18634C5.83944 3.39949 5.40385 3.37121 5.12759 3.09444Z"
                fill="#5B8C00"
              />
              <path
                d="M11.6664 5.25977C12.096 5.25977 12.4442 5.60861 12.4442 6.03896V11.3653L15.7164 14.6435C16.0201 14.9478 16.0201 15.4411 15.7164 15.7454C15.4127 16.0497 14.9202 16.0497 14.6165 15.7454L11.1165 12.2391C10.9706 12.0929 10.8887 11.8947 10.8887 11.6881V6.03896C10.8887 5.60861 11.2369 5.25977 11.6664 5.25977Z"
                fill="#5B8C00"
              />
            </svg>
            {course.duration} days
          </p>
        </Row>
        <Row>
          <Paragraph
            ellipsis={{ rows: 5, expandable: false, symbol: "more" }}
            className="rcourse-text"
          >
            <div
              dangerouslySetInnerHTML={{ __html: course.courseDetails }}
            ></div>
          </Paragraph>
        </Row>
        <Row
          className="flex flex--space-between"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            <p className="rcourse-duration">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.3237 19.581H3.03033C2.15423 19.581 1.44149 18.9001 1.44149 18.0631V3.40269C1.44149 2.56111 2.15823 1.8779 3.03834 1.8779H17.7992C18.6753 1.8779 19.388 2.55882 19.388 3.39504V11.7543C19.388 12.1345 19.7108 12.4428 20.1088 12.4428C20.5068 12.4428 20.8295 12.1345 20.8295 11.7543V3.39504C20.8295 1.7991 19.4705 0.5 17.7992 0.5H3.03834C1.36301 0.5 0 1.80216 0 3.40269V18.0631C0 19.6598 1.3598 20.9589 3.03033 20.9589H11.3237C11.7217 20.9589 12.0444 20.6505 12.0444 20.2703C12.0444 19.8901 11.7217 19.581 11.3237 19.581Z"
                  fill="#006D75"
                />
                <path
                  d="M16.2931 5.26953H4.53373C4.13572 5.26953 3.81299 5.57786 3.81299 5.9581C3.81299 6.33834 4.13572 6.64667 4.53373 6.64667H16.2939C16.6919 6.64667 17.0146 6.33834 17.0146 5.9581C17.0146 5.57786 16.6911 5.26953 16.2931 5.26953Z"
                  fill="#006D75"
                />
                <path
                  d="M16.2931 10.0391H4.53373C4.13572 10.0391 3.81299 10.3474 3.81299 10.7276C3.81299 11.1079 4.13572 11.4162 4.53373 11.4162H16.2939C16.6919 11.4162 17.0146 11.1079 17.0146 10.7276C17.0146 10.3474 16.6911 10.0391 16.2931 10.0391Z"
                  fill="#006D75"
                />
                <path
                  d="M11.7322 14.8071H4.53276C4.13474 14.8071 3.81201 15.1155 3.81201 15.4957C3.81201 15.8759 4.13474 16.1843 4.53276 16.1843H11.7322C12.1302 16.1843 12.4529 15.8759 12.4529 15.4957C12.4529 15.1155 12.1302 14.8071 11.7322 14.8071Z"
                  fill="#006D75"
                />
                <path
                  d="M18.2515 13.5166C15.4286 13.5166 13.1318 15.7108 13.1318 18.4085C13.1318 21.1061 15.4286 23.3004 18.2515 23.3004C21.0744 23.3004 23.3712 21.1061 23.3712 18.4085C23.3712 15.7108 21.0752 13.5166 18.2515 13.5166ZM18.2515 21.9225C16.223 21.9225 14.5733 20.3457 14.5733 18.4085C14.5733 16.4713 16.2238 14.8945 18.2515 14.8945C20.2792 14.8945 21.9297 16.4713 21.9297 18.4085C21.9297 20.3457 20.28 21.9225 18.2515 21.9225Z"
                  fill="#006D75"
                />
                <path
                  d="M19.2958 17.1566L17.7163 18.3723L17.323 17.8926C17.0779 17.5927 16.6245 17.543 16.3114 17.7763C15.9982 18.0112 15.9429 18.4435 16.1888 18.7434L17.033 19.7716C17.1516 19.9162 17.327 20.0103 17.5184 20.031C17.5457 20.0341 17.5729 20.0356 17.6001 20.0356C17.7643 20.0356 17.9237 19.982 18.0519 19.8833L20.2001 18.2308C20.5101 17.9913 20.559 17.5583 20.309 17.2622C20.0591 16.9661 19.6058 16.9194 19.2958 17.1566Z"
                  fill="#006D75"
                />
              </svg>
              {course.noOfAssessments + course.totalNoOfMiniAssessment} Tech
              assesment, {course.noOfQuizzes} Quiz
            </p>
          </div>
          <EnrolButton id={course.id} />
        </Row>
      </Col>
    </Col>
  );
};
export default RCourseCard;
