/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Col,
  Empty,
  Row,
  Tabs,
  Divider,
  Card,
  Button,
  Table,
  Spin,
  Modal,
} from "antd";
import { ImportOutlined, PlusOutlined } from "@ant-design/icons";
import "./MyContent.scss";
import Breadcrumb from "../../component/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets";
import { useLazyQueryData,useMutationData } from "services";
import { GET_ALL_COURSES, GET_MY_CONTENT } from "services/Queries";
import { DELETE_COURSE } from "services/Mutation";
import MyContentCard from "component/MyContentCard/MyContentCard";
import { ICourseType, IUserCourseType } from "lib/Types";
import CourseDetails from "pages/CourseDetails/CourseDetails";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export const TABS = ["All", "Draft", "Pending", "Published", "Rejected"];

const MyContent: React.FC = () => {
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const [deleteCourseModalIsOpen, setDeleteCourseModalIsOpen] = useState(false);
  const [clickedCourseIndex, setClickedCourseIndex] = useState("");
  const [originalCourseList, setOriginalCourseList] = useState(["dad"]);
  const [currentFilterTab, setCurrentFilterTab] = useState("1");
  const [courseList, setCourseList] = useState<any>({
    userCourses: [],
    collaboratorCourses: [],
  });
  const [data, setData] = useState<any>([]);
  const userId = localStorage.getItem("userId");

  const [
    fetchAllCoursesForUser,
    { data: courseDetailsResp, loading: userCourseLoading },
  ] = useLazyQueryData(GET_MY_CONTENT, {
    userId: userId,
  });

  // ===Mutations===
  const [deleteCourseRelData] = useMutationData(DELETE_COURSE);
  
  useEffect(() => {
    fetchAllCoursesForUser();
  }, []);
  useEffect(() => {
    if (courseDetailsResp) {
      // const items = courseDetailsResp.allCourses;
      // console.log(courseDetailsResp.allCourses);
      // setCourseList(items);
      console.log(
        "My Content",
        courseDetailsResp.getUserCoursesAndCollaboratedCourses
      );
      setCourseList(courseDetailsResp.getUserCoursesAndCollaboratedCourses);
    }
  }, [courseDetailsResp, fetchAllCoursesForUser]);

  const handleAddCourse = () => {
    navigate("/content/course");
  };
  const breadcrumbItems = [
    {
      label: "My Content",
      className: "breadcrumb-child",
    },
  ];
  const handleEdit = (id) => {};

  const onCourseDelete = (index) =>{
    // console.log("DELETED INDEX",index)
    let deletedCourseId = null;
      deleteCourseRelData({
        variables:{
          courseId : index,
        },
      }).then(()=>{
        fetchAllCoursesForUser();
      });
  };

  useEffect(() => {
    const userCourseData = courseList.userCourses.map((item, index) => {
      return {
        key: index + 1,
        courseName: (
          <span className="table-item-text">{item.course.title}</span>
        ),
        createdBy: <span className="table-item-text">ME</span>,
        validFrom: (
          <span className="table-item-text">
            {item.course.validFrom.substring(0, 10)}
          </span>
        ),
        validTo: (
          <span className="table-item-text">
            {item.course.validTo.substring(0, 10)}
          </span>
        ),
        action: (
          <Col className="mycontent-details-tile-buttons">
            <EditOutlined
              style={{ color: "#08979C" }}
              className="mr-16"
              onClick={() => {
                navigate(`/content/course/${item.course.id}`);
              }}
            />
              <DeleteOutlined 
                style={{ color: "#A8071A" }} 
                className="mr-16"
                onClick={() => {
                  setDeleteCourseModalIsOpen(true);
                  setClickedCourseIndex(item.course.id);
                }}
              />
          </Col>       
        ),
      };
    });

    const collaboratorCoursesData = courseList.collaboratorCourses.map((item, index) => {
      return {
        key: index + 1,
        courseName: (
          <span className="table-item-text">{item.course.title}</span>
        ),
        createdBy: <span className="table-item-text">ME</span>,
        validFrom: (
          <span className="table-item-text">
            {item.course.validFrom.substring(0, 10)}
          </span>
        ),
        validTo: (
          <span className="table-item-text">
            {item.course.validTo.substring(0, 10)}
          </span>
        ),
        action: (
          <Col className="mycontent-details-tile-buttons">
            <EditOutlined
              style={{ color: "#08979C" }}
              className="mr-16"
              onClick={() => {
                navigate(`/content/course/${item.course.id}`);
              }}
            />
            <DeleteOutlined style={{ color: "#A8071A" }} className="mr-16" />
          </Col>
        ),
      };
    });

    const mergedData = [...userCourseData, ...collaboratorCoursesData];
    setData(mergedData);
  }, [courseList]);

  const columns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Valid from",
      dataIndex: "validFrom",
      key: "validFrom",
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <div className="dashboard-container">
      <Row className="mb-12">
        <Col>
          <Breadcrumb items={breadcrumbItems} />
          <Divider className="divider m-0 mt-12" />
        </Col>
      </Row>

      {originalCourseList.length > 0 ? (
        <>
          {userCourseLoading ? (
            <div className="dash-loader">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {courseList.userCourses?.length ||
              courseList.collaboratorCourses?.length ? (
                <>
                <Row className="mt-20">
                  <Row className="flex flex--space-between mycontent-header-container flex--align-center">
                    <Col>
                      <span className="my-content-header">
                        Course content details
                      </span>
                    </Col>
                    <Col>
                      <Button
                        className="mycontent-button"
                        onClick={() => handleAddCourse()}
                      >
                        Create Content
                      </Button>
                    </Col>
                  </Row>

                  <Row
                    className="mt-20 .mycontent-header-container"
                    style={{ width: "100%" }}
                  >
                    {data.length > 0 && (
                      <Table
                        dataSource={data}
                        columns={columns}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Row>
                </Row>
                <Modal
            title={"Are you sure you want to delete the Course ?"}
            visible={deleteCourseModalIsOpen}
            onCancel={() => setDeleteCourseModalIsOpen(false)}
            footer={[
              <Button
                key="no"
                onClick={() => setDeleteCourseModalIsOpen(false)}
              >
                No
              </Button>,
              <Button
                key="yes"
                type="primary"
                   onClick={() => {
                      onCourseDelete(clickedCourseIndex);
                      setDeleteCourseModalIsOpen(false);
                    }}
              >
                Yes
              </Button>,
            ]}
          />
              </>
              ) : (
                // <Empty
                //   image={icons.noCourses}
                //   description={
                //     <h4 className="header-4 mt-40">
                //       You have no course under this filter.
                //     </h4>
                //   }
                //   className="flex flex--center flex--column no-course-content"
                // />

                <Row className="mt-20">
                  <Col span={24} className="mycontent-container">
                    <Row className="flex flex--center flex--align-center">
                      <Row className="mycontent-image-container ">
                        <img
                          className="my-content-image"
                          src={icons.addContent}
                          alt=""
                        />
                      </Row>
                      <Row className="mycontent-text-container mt-30">
                        <span className="mycontent-text-container-span">
                          Initiate the creation of your instructional course
                          materials, commencing with the development of your
                          curriculum content
                        </span>
                      </Row>
                      <Row className="mycontent-button-container mt-30">
                        <Button
                          className="mycontent-button"
                          onClick={() => handleAddCourse()}
                        >
                          Create Content
                        </Button>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ) : (
        // <>{getEmptyPageBasedOnRole()}</>
        <></>
      )}
    </div>
  );
};
export default MyContent;
