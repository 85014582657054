import {
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Popover, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./ShowQuestions.scss";

const ShowQuestions = ({
  questions,
  changeQuestion = () => {},
  disable,
  selectedTaskIndex,
  questionEmpty,
  contentType,
}) => {
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    setCurrentTab(0);
  }, [selectedTaskIndex]);

  return questionEmpty ||
    contentType === "quiz" ||
    contentType === "miniAssessment" ||
    contentType === "badges" ? (
    <div className="prompt-box px-8 pt-8 mr-2">
      <div className="prompt-top">
        <div className="prompt-top-first">
          <ExclamationCircleOutlined />
          <span> PROMPT</span>
        </div>
      </div>
      <div className="prompt-bottom">
        <span className="prompt-question-text">
          <span className="cursor-n">
            There are no further questions at the moment. Type in your question
            to continue learning!
          </span>
        </span>
      </div>
    </div>
  ) : questions?.length ? (
    <div className="prompt-box px-8 pt-8 mr-2">
      {
        <>
          <Row className="prompt-top mb-8 px-8" align="middle">
            {questions.length > 1 && (
              <LeftOutlined
                className="question-icon-boldness"
                onClick={() => {
                  currentTab === 0
                    ? setCurrentTab(questions.length - 1)
                    : setCurrentTab(currentTab - 1);
                }}
              />
            )}
            <div className="prompt-top-second">
              Suggestive Questions
              <span>
                {currentTab + 1}/{questions.length}
              </span>
              <Popover content={"Your hyper-personalized learning assistant"}>
                <InfoCircleOutlined />
              </Popover>
            </div>
            {questions.length > 1 && (
              <RightOutlined
                className="question-icon-boldness"
                onClick={() => {
                  currentTab === questions.length - 1
                    ? setCurrentTab(0)
                    : setCurrentTab(currentTab + 1);
                }}
              />
            )}
          </Row>
          <div className="prompt-bottom">
            <div
              className="prompt-question-text"
              onClick={(e) => {
                changeQuestion(questions[currentTab], e);
              }}
            >
              <Popover
                content={!disable ? "Click to ask question" : ""}
                placement="topLeft"
              >
                <span className={disable ? "cursor-n" : "cursor-p"}>
                  <p
                    className="question_prompt-text py-4 px-8 mb-0"
                    ellipsis={{ tooltip: questions[currentTab] }}
                  >
                    {questions[currentTab]}
                  </p>
                </span>
              </Popover>
            </div>
          </div>
        </>
      }
    </div>
  ) : (
    <></>
  );
};

export default ShowQuestions;
