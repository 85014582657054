export class DefaultSettings {
  static home = `${window.location.protocol}//${window.location.host}`;
  static clientId = process.env.REACT_APP_COGNITO_ID;
  static authDomain = process.env.REACT_APP_AUTH_DOMAIN;
  static api = process.env.REACT_APP_API_ENDPOINT;
  static loginUrl = `${this.authDomain}/login?client_id=${this.clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${this.home}`;
  static logoutUrl = `${this.authDomain}/logout?client_id=${this.clientId}&response_type=code&redirect_uri=${this.home}`;
  static tokenEndpoint = `${this.authDomain}/oauth2/token`;
  static authDisabled = false;
  static open_ai_key = process.env.REACT_APP_OPENAI_API_KEY;
}
export class LocalSettings extends DefaultSettings {
  static home = `${window.location.protocol}//${window.location.host}`;
  static loginUrl = `${this.authDomain}/login?client_id=${this.clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${this.home}`;
  static logoutUrl = `${this.authDomain}/logout?client_id=${this.clientId}&response_type=code&redirect_uri=${this.home}`;
  static api = process.env.REACT_APP_API_ENDPOINT;
  static authDisabled = false;
  static open_ai_key = process.env.REACT_APP_OPENAI_API_KEY;
}

const CurrentSettings =
  window.location.hostname === "localhost" ? LocalSettings : DefaultSettings;

export default CurrentSettings;
