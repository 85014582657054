import { CaretRightOutlined, Html5Filled } from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Modal, Row, Typography } from "antd";
import { icons } from "assets";
import AssessmentTile from "component/AssessmentTile/AssessmentTile";
import LessonTile from "component/LessonTile/LessonTile";
import QuizTile from "component/QuizTile/QuizTile";
import SectionTile from "component/SectionTile/SectionTile";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutationData } from "services";
import { SUMIT_PREVIEW } from "services/Mutation";
import "./Preview.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useDrag, useDrop } from "react-dnd";

const ItemType = "LIST_ITEM";

const ListItem = ({
  index,
  moveItem,
  section,
  setClickedSectionIndex,
  handleEdit,
  setDeleteSectionModalIsOpen,
}) => {
  const ref = useRef<any>(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item: any, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset: any = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Col
      span={24}
      className={
        isDragging
          ? "preview-section-details-tile dragging mb-20"
          : "preview-section-details-tile mb-20"
      }
      key={index}
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        border: isDragging ? "1px solid #357bac" : "1px solid #00000026",
      }}
    >
      <Collapse
        accordion={false}
        collapsible="icon"
        bordered={false}
        size="large"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel
          key={index}
          header={
            <SectionTile
              section={section}
              index={index}
              handleDeleteSection={() => {
                setDeleteSectionModalIsOpen(true);
                setClickedSectionIndex(index);
              }}
              editInPreview={() => handleEdit()}
            />
          }
        >
          {section.lessons?.map((lesson) => {
            return (
              <>
                <LessonTile lesson={lesson} isPreview={true} />
              </>
            );
          })}

          {section?.assessments?.map((assessment) => {
            return (
              <>
                <AssessmentTile assessment={assessment} isPreview={true} />
              </>
            );
          })}

          {section?.quizzes?.map((quiz, index) => {
            return (
              <>
                <QuizTile quiz={quiz} isPreview={true} />
              </>
            );
          })}
        </Collapse.Panel>
      </Collapse>
    </Col>
  );
};

const { Text } = Typography;

const Preview = ({
  title,
  sectionDetails,
  onPrev,
  courseId,
  setSectionDetails,
}) => {
  const navigate = useNavigate();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [deleteSectionModalIsOpen, setDeleteSectionModalIsOpen] =
    useState(false);
  const [clickedSectionIndex, setClickedSectionIndex] = useState("");
  console.log("response", sectionDetails);
  const [mNumber, setMNumbers] = useState([]);

  useEffect(() => {
    if (sectionDetails) {
      const moduleNumbers = sectionDetails.map((item) => item.moduleNumber);
      setMNumbers(moduleNumbers);
    }
  }, []);

  // ====Mutations===
  const [detailsRelData, { loading: detailsRelLoading }] =
    useMutationData(SUMIT_PREVIEW);

  //dragger

  const moveItem = (dragIndex, hoverIndex) => {
    const draggedItem = sectionDetails[dragIndex];
    const updatedList = [...sectionDetails];
    updatedList.splice(dragIndex, 1);
    updatedList.splice(hoverIndex, 0, draggedItem);

    const updatedSectionDetails = updatedList.map((item, index) => ({
      ...item,
      moduleNumber: mNumber[index],
    }));
    setSectionDetails(updatedSectionDetails);
  };

  const handleSubmit = (sectionDetails) => {
    detailsRelData({
      variables: {
        courseId,
        details: sectionDetails?.map(
          ({ m_id, moduleId, newEntry, title, moduleNumber, lessons }) => ({
            id: newEntry === false ? m_id : undefined,
            moduleId: moduleId,
            newEntry: newEntry,
            moduleName: title,
            moduleNumber: moduleNumber,
            lessons: lessons.map(
              ({
                l_id,
                lessonId,
                newEntry,
                orderNumber,
                lessonName,
                fileType,
                fileName,
              }) => ({
                id: newEntry === false ? l_id : undefined,
                lessonId: lessonId,
                newEntry: newEntry,
                orderNumber: orderNumber,
                lessonName,
                contentType: fileType,
                contentLink: fileName,
              })
            ),
          })
        ),
      },
    }).then(() => {
      if (!detailsRelLoading) {
        navigate("/content");
      }
    });
  };

  const handleEdit = () => {
    onPrev();
  };

  const onSectionDelete = (index) => {
    handleDeleteSection(index);
  };

  const handleDeleteSection = (index) => {
    const updatedArray = sectionDetails.filter((_, i) => i !== index);
    setSectionDetails(updatedArray);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Row gutter={[24, 24]} className="preview-container">
        <Col className="preview-header" span={24}>
          <Text className="header-5 capitalize preview-header-title">
            {`${title}`}
          </Text>
          <Divider className="breadcrumb-divider m-0 mt-12" />
        </Col>

        <Col className="preview-content" span={24}>
          {sectionDetails?.map((section, index) => {
            return (
              <>
                {section.moduleType !== "BADGES" &&
                  index !== sectionDetails.length - 2 && (
                    <ListItem
                      key={index}
                      index={index}
                      moveItem={moveItem}
                      section={section}
                      setClickedSectionIndex={setClickedSectionIndex}
                      handleEdit={handleEdit}
                      setDeleteSectionModalIsOpen={setDeleteSectionModalIsOpen}
                    />
                  )}

                {section.moduleType !== "BADGES" &&
                  index === sectionDetails.length - 2 && (
                    <Col
                      span={24}
                      className="preview-section-details-tile mb-20"
                      key={index}
                    >
                      <Collapse
                        accordion={false}
                        collapsible="icon"
                        bordered={false}
                        size="large"
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                      >
                        <Collapse.Panel
                          key={index}
                          header={
                            <SectionTile
                              section={section}
                              index={index}
                              handleDeleteSection={() => {
                                setDeleteSectionModalIsOpen(true);
                                setClickedSectionIndex(index);
                              }}
                              editInPreview={() => handleEdit()}
                            />
                          }
                        >
                          {section.lessons?.map((lesson) => {
                            return (
                              <>
                                <LessonTile lesson={lesson} isPreview={true} />
                              </>
                            );
                          })}

                          {section?.assessments?.map((assessment) => {
                            return (
                              <>
                                <AssessmentTile
                                  assessment={assessment}
                                  isPreview={true}
                                />
                              </>
                            );
                          })}

                          {section?.quizzes?.map((quiz, index) => {
                            return (
                              <>
                                <QuizTile quiz={quiz} isPreview={true} />
                              </>
                            );
                          })}
                        </Collapse.Panel>
                      </Collapse>
                    </Col>
                  )}
              </>
            );
          })}
        </Col>
      </Row>

      <Row gutter={[24, 24]} className="preview-button-container mt-12">
        <Col span={24}>
          <Row className="flex flex--space-between mb-20 mt-20 mr-12">
            <Col></Col>
            <Col>
              <Button
                className="form-clear-button mr-8"
                onClick={() => onPrev()}
              >
                Back
              </Button>
              <Button
                className="form-next-button"
                onClick={() => setConfirmationModalIsOpen(true)}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={
          <span style={{ display: "flex" }}>
            <img
              src={icons.tickMarkIcon}
              alt="tickMarkIcon icon"
              style={{ marginRight: "10px" }}
            />
            <span>Are you sure you want to submit the course?</span>
          </span>
        }
        visible={confirmationModalIsOpen}
        onCancel={() => setConfirmationModalIsOpen(false)}
        footer={[
          <Button key="no" onClick={() => setConfirmationModalIsOpen(false)}>
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => handleSubmit(sectionDetails)}
          >
            Yes
          </Button>,
        ]}
      />

      <Modal
        title={
          <span style={{ display: "flex" }}>
            <img
              src={icons.deleteIcon}
              alt="deleteIcon icon"
              style={{ marginRight: "10px" }}
            />
            <span>Are you sure you want to delete the section?</span>
          </span>
        }
        visible={deleteSectionModalIsOpen}
        onCancel={() => setDeleteSectionModalIsOpen(false)}
        footer={[
          <Button key="no" onClick={() => setDeleteSectionModalIsOpen(false)}>
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              onSectionDelete(clickedSectionIndex);
              setDeleteSectionModalIsOpen(false);
            }}
          >
            Yes
          </Button>,
        ]}
      />
    </DndProvider>
  );
};

export default Preview;
