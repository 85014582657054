export const icons = {
  home: require("./images/selfLearning/home.svg")?.default,
  homeActive: require("./images/selfLearning/homeActive.svg")?.default,
  myLearnings: require("./images/selfLearning/myLearnings.svg")?.default,
  myLearningsActive: require("./images/selfLearning/myLearningsActive.svg")
    ?.default,
  settings: require("./images/selfLearning/settings.svg")?.default,
  settingsActive: require("./images/selfLearning/settingsActive.svg")?.default,
  abstractImage: require("./images/selfLearning/abstractImage.svg")?.default,
  avatarGeneral: require("./images/selfLearning/avatarGeneral.svg")?.default,
  checkCircle: require("./images/selfLearning/checkCircle.svg")?.default,
  checkCircleGreen: require("./images/CheckCircleGreen.svg")?.default,
  courseBack: require("./images/selfLearning/courseBack.svg")?.default,
  caretRight: require("./images/selfLearning/caret-right.svg")?.default,
  caretLeft: require("./images/selfLearning/caret-left.svg")?.default,
  launchQuiz: require("./images/selfLearning/launch-quiz.svg")?.default,
  notAnswered: require("./images/selfLearning/notAnswered.svg")?.default,
  arrowDown: require("./images/selfLearning/arrowDown.svg")?.default,
  answered: require("./images/selfLearning/answered.svg")?.default,
  defaultCourse: require("./images/selfLearning/course-details.svg")?.default,
  lock: require("./images/selfLearning/lock.svg")?.default,
  clock: require("./images/selfLearning/clock.svg")?.default,
  pendingCourse: require("./images/selfLearning/pendingCourse.svg")?.default,
  generate: require("./images/generate.svg")?.default,
  completedCourse: require("./images/selfLearning/completedCourse.svg")
    ?.default,
  completedAssessment: require("./images/selfLearning/completedAssessment.svg")
    ?.default,
  checkIcon: require("./images/selfLearning/checkIcon.svg")?.default,
  superTutor: require("./images/core/superTutor.svg")?.default,
  Notification: require("./images/core/Notification.svg")?.default,
  banIcon: require("./images/selfLearning/banIcon.svg")?.default,
  cancelIcon: require("./images/selfLearning/cancelIcon.svg")?.default,
  externalLink: require("./images/selfLearning/externalLink.svg")?.default,
  assessmentSubmit: require("./images/selfLearning/assessmentSubmit.svg")
    ?.default,
  check: require("./images/selfLearning/check.svg")?.default,
  error: require("./images/selfLearning/error.svg")?.default,
  processing: require("./images/selfLearning/processing.svg")?.default,
  cross: require("./images/selfLearning/cross.svg")?.default,
  linkDark: require("./images/selfLearning/external-link.svg")?.default,
  empty: require("./images/selfLearning/empty.svg")?.default,
  dummyCertificate: require("./images/selfLearning/dummyCertificate.svg")
    ?.default,
  emptyEnrolledCourse: require("./images/selfLearning/emptyEnrolledCourse.svg")
    ?.default,
  settingsSquare: require("./images/selfLearning/settingsSquare.svg")?.default,
  rocket: require("./images/selfLearning/rocket.svg")?.default,
  checkComputer: require("./images/selfLearning/checkComputer.svg")?.default,
  noCourses: require("./images/selfLearning/noCourses.svg")?.default,
  versionIcon: require("./images/selfLearning/versionIcon.svg")?.default,
  cancelModalImg: require("./images/selfLearning/cancelModal.svg")?.default,
  emptyAssessmentImg: require("./images/selfLearning/EmptyAssessment.svg")
    ?.default,
  copyLink: require("./images/selfLearning/copyLink.svg")?.default,
  infraCheckFailed: require("./images/selfLearning/infraCheckFailed.svg")
    ?.default,
  infraCheckedLight: require("./images/selfLearning/infraCheckedLight.svg")
    ?.default,
  infraCheckedDark: require("./images/selfLearning/infraCheckedDark.svg")
    ?.default,
  submitAssessmentImg: require("./images/selfLearning/submitAssessment.svg")
    ?.default,
  deleteImg: require("./images/selfLearning/deleteAssessment.svg")?.default,
  reviewConfirmation: require("./images/selfLearning/reviewConfirmation.svg")
    ?.default,
  emptyReviewCourses: require("./images/selfLearning/emptyReviewCourse.svg")
    ?.default,
  publishCourseConfirmation: require("./images/selfLearning/publishCourse.svg")
    ?.default,
  smartTutorLogo: require("./images/super-tutor-logo.svg")?.default,
  siderLogo: require("./images/siderLogo.svg")?.default,
  dashboardIcon: require("./images/bootcamp/dashboardIcon.svg")?.default,
  myLearningIcon: require("./images/bootcamp/myLearningIcon.svg")?.default,
  enrollmentCount: require("./images/enrollmentCount.svg")?.default,
  Bot: require("./images/Bot.svg")?.default,
  status: require("./images/Status.svg")?.default,
  ContentDetails: require("./images/Content Details.svg")?.default,
  BotActive: require("./images/BotActive.svg")?.default,
  statusActive: require("./images/StatusActive.svg")?.default,
  ContentDetailsActive: require("./images/Content DetailsActive.svg")?.default,
  verdict: require("./images/verdict.svg")?.default,
  testCase: require("./images/testCase.svg")?.default,
  task: require("./images/task.svg")?.default,
  areaOfImprovement: require("./images/areaOfImprovement.svg")?.default,

  courseCompletion: require("./images/courseCompletion.svg")?.default,
  pptLogo: require("./images/ppt_logo.svg")?.default,
  videoLogo: require("./images/video_logo.svg")?.default,
  launchAssessment: require("./images/launchAssessment.svg")?.default,
  quizLogo: require("./images/quiz_logo.svg")?.default,
  deloitteLogo: require("./images/deloitteLogo.svg")?.default,
  deloitteTextLogo: require("./images/deloitteTextLogo.svg")?.default,
  pdfLogo: require("./images/pdf_logo.svg")?.default,
  sendMessageIcon: require("./images/send.svg")?.default,
  addContent: require("./images/addContent.svg")?.default,
  exitIcon: require("./images/exitIcon.svg")?.default,

  //core
  bootcampLogo: require("./images/core/bootcamp-logo.svg")?.default,
  appLogo: require("./images/core/AppLogo.svg")?.default,
  clockRed: require("./images/core/clockRed.svg")?.default,
  rightArrow: require("./images/core/rightArrow.svg")?.default,
  submitImg: require("./images/core/confirmationImg.svg")?.default,
  starIcon: require("./images/core/star.svg")?.default,
  courseDefaultImage: require("./images/courseDefaultImage.png"),
  computerIcon: require("./images/core/computerIcon.svg")?.default,
  tickMarkIcon: require("./images/core/tickMarkIcon.svg")?.default,
  pending: require("./images/core/pending.svg")?.default,
  certificateOutlined: require("./images/core/certificateOutlined.svg")
    ?.default,
  bulbOutlined: require("./images/core/bulbOutlined.svg")?.default,
  profileOutlined: require("./images/core/profileIcon.svg")?.default,
  tickCircledFilledLight: require("./images/core/tickCircledFilledLight.svg")
    ?.default,
  questionFilled: require("./images/core/questionFilled.svg")?.default,
  successTick: require("./images/core/successTick.svg")?.default,
  unCheckedTick: require("./images/core/unCheckedTick.svg")?.default,
  doubleTickOutlined: require("./images/core/doubleTickOutlined.svg")?.default,
  exclamationRedCircle: require("./images/core/exclamationRedCircle.svg")
    ?.default,
  processingRunAssessment: require("./images/core/processingRunAssessment.svg")
    ?.default,
  chatBot: require("./images/core/chatBot.svg")?.default,
  chatBotFace: require("./images/core/chatBotFace.svg")?.default,
  chatBotImg: require("./images/core/chatBotImg.svg")?.default,
  enabledSwapArrowIcon: require("./images/core/swapEnabled.svg")?.default,

  //bootcamp
  addLinkerImg: require("./images/bootcamp/addLinkers.png"),
  noDataFound: require("./images/bootcamp/nodatafound.svg")?.default,
  bootcampStartIcon: require("./images/bootcamp/bootcampStartIcon.svg")
    ?.default,
  calendarIcon: require("./images/bootcamp/calendar.svg")?.default,
  noTracksImg: require("./images/core/emptyCard.svg")?.default,
  noTraineesImg: require("./images/bootcamp/noTrainees.svg")?.default,
  noSectionsImg: require("./images/bootcamp/noSections.svg")?.default,
  rocketOutlined: require("./images/bootcamp/rocketOutlined.svg")?.default,
  flagOutlined: require("./images/bootcamp/flagOutlined.svg")?.default,
  cupOutlined: require("./images/bootcamp/cupOutlined.svg")?.default,
  copyIcon: require("./images/bootcamp/copyIcon.svg")?.default,
  sectionAddedModal: require("./images/bootcamp/sectionAddedModal.svg")
    ?.default,
  gradedAssessmentLeft:
    require("./images/bootcamp/gradedAssessmentLeftIcon.svg")?.default,
  emptyIcon: require("./images/bootcamp/emptyIcon.svg")?.default,
  fileIcon: require("./images/bootcamp/fileIcon.svg")?.default,
  emptySchedule: require("./images/bootcamp/emptySchedule.svg")?.default,
  deadlineIcon: require("./images/bootcamp/deadlineIcon.svg")?.default,
  dragItem: require("./images/bootcamp/dragItem.svg")?.default,
  emptyPageTrackPerformance:
    require("./images/bootcamp/emptyPageTrackPerformance.svg")?.default,
  emptyKnowYourBootcamp: require("./images/bootcamp/emptyKnowYourBootcamp.svg")
    ?.default,
  emptyPOCImage: require("./images/bootcamp/emptyPOCImage.svg")?.default,
  autoSubmit: require("./images/bootcamp/autoSubmit.svg")?.default,
  hamburgerMenu: require("./images/bootcamp/hamburgerMenu.svg")?.default,
  tracksIcon: require("./images/bootcamp/tracksIcon.svg")?.default,
  publishSchedule: require("./images/bootcamp/publishSchedule.svg")?.default,
  minusCircleYellow: require("./images/bootcamp/minusCircleYellow.svg")
    ?.default,
  crossCircleRed: require("./images/bootcamp/crossCircleRed.svg")?.default,
  clockCircleYellow: require("./images/bootcamp/clockCircleYellow.svg")
    ?.default,
  getAutoEvaluation: require("./images/bootcamp/getAutoEvaluation.svg")
    ?.default,
  clockCircleBlue: require("./images/bootcamp/clockCircleBlue.svg")?.default,
  blueCircleTick: require("./images/bootcamp/blueCircleTick.svg")?.default,
  blueCircleStar: require("./images/bootcamp/blueCircleStar.svg")?.default,
  emptyTraineeDashboard: require("./images/bootcamp/emptyTraineeDashboard.svg")
    ?.default,
  bootcampImg: require("./images/bootcamp/bootcampImg.svg")?.default,
  handPalm: require("./images/bootcamp/handPalm.svg")?.default,
  inProgressStatus: require("./images/bootcamp/inProgressStatus.svg")?.default,
  emptyGraph: require("./images/bootcamp/emptyGraph.svg")?.default,
  dragHamburgerIcon: require("./images/bootcamp/dragHamburgerIcon.svg")
    ?.default,
  emptyEvaluationSubmission:
    require("./images/bootcamp/emptyEvaluationSubmission.svg")?.default,
  loadingEvaluationSubmission:
    require("./images/bootcamp/loadingEvaluationSubmission.svg")?.default,
  location: require("./images/bootcamp/location.svg")?.default,
  firstPrice: require("./images/bootcamp/firstPrice.svg")?.default,
  secondPrice: require("./images/bootcamp/secondPrice.svg")?.default,
  thirdPrice: require("./images/bootcamp/thirdPrice.svg")?.default,
  emptyTrackRating: require("./images/bootcamp/emptyTrackRating.svg")?.default,
  sectionCircle: require("./images/bootcamp/sectionCircle.svg")?.default,
  profileCard: require("./images/bootcamp/profileCard.svg")?.default,
  refreshIcon: require("./images/bootcamp/refreshIcon.svg")?.default,
  disabledRefreshIcon: require("./images/bootcamp/disabledRefreshIcon.svg")
    ?.default,
  loadingPieChart: require("./images/bootcamp/loadingPieChart.svg")?.default,

  //selflearning
  selfLearningIcon: require("./images/selfLearning/selfLearningStartIcon.svg")
    ?.default,
  downloadIcon: require("./images/selfLearning/downloadIcon.svg")?.default,
  certificate: require("./images/selfLearning/certificate.svg")?.default,
  rocketIcon: require("./images/selfLearning/rocketIcon.svg")?.default,
  flagIcon: require("./images/selfLearning/flagIcon.svg")?.default,
  userPlusIcon: require("./images/selfLearning/userPlusIcon.svg")?.default,
  techAssignmentIcon: require("./images/core/computerIcon.svg")?.default,
  caseStudyIcon: require("./images/selfLearning/caseStudyIcon.svg")?.default,
  emptyLabs: require("./images/core/emptyCard.svg")?.default,
  quizDurationIcon: require("./images/selfLearning/quizDuration.svg")?.default,
  sendForReviewImg: require("./images/selfLearning/sendForReviewImage.svg")
    ?.default,
  sentForReviewImg: require("./images/core/confirmationImg.svg")?.default,
  viewCourseFeedback: require("./images/selfLearning/viewCourseFeedback.svg")
    ?.default,
  reviewFlagIcon: require("./images/selfLearning/reviewFlagIcon.svg")?.default,
  reportIssueUnmarkedIcon:
    require("./images/selfLearning/reportIssueUnmarkedIcon.svg")?.default,
  closeIcon: require("./images/selfLearning/closeIcon.svg")?.default,
  checkFilledSquare: require("./images/selfLearning/checkFilledSquare.svg")
    ?.default,
  checkSquareDisabled: require("./images/selfLearning/checkSquareDisabled.svg")
    ?.default,
  deleteIcon: require("./images/selfLearning/deleteIcon.svg")?.default,
  courseExpireAction: require("./images/selfLearning/courseExpireAction.svg")
    ?.default,
  expireCourseIcon: require("./images/selfLearning/expireCourseIcon.svg")
    ?.default,
  expireCourseModal: require("./images/selfLearning/expireCourseModal.svg")
    ?.default,
  editUserRoleModal: require("./images/selfLearning/EditUserRoleModal.svg")
    ?.default,
  graphLoadingImage: require("./images/selfLearning/graphLoadingImage.svg")
    ?.default,
  emptyPlagiarismGraph:
    require("./images/selfLearning/emptyPlagiarismGraph.svg")?.default,
};
