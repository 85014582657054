import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { ApolloProvider } from "@apollo/client";
import client from "./lib/client";

const msal = new PublicClientApplication(msalConfig);

if (!msal.getActiveAccount()) {
  if (msal.getAllAccounts().length > 0) {
    msal.setActiveAccount(msal.getAllAccounts()[0]);
  }
}

msal.addEventCallback((e: any) => {
  if (e.eventType === EventType.LOGIN_SUCCESS) {
    if (e.payload) {
      try {
        const { account } = e.payload as any;
        msal.setActiveAccount(account);
      } catch (_e) { }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <App instance={msal} />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
