/**
 * File imports:
 *
 * library import <>
 * pages <>
 * containers <>
 * components <>
 * constants
 * Query/Mutation <>
 * Styles
 *
 */
import dayjs from "dayjs";
import { Empty, message } from "antd";
import { icons } from "../assets";

export const getTagColor = (value1, value2) => {
  const percent = (value1 / value2) * 100;

  if (percent >= 75) return "#49db7a";
  else if (percent < 75 && percent >= 40) return "#e7c61b";
  else return "#e13317";
};

export const nameIntials = (name) => {
  const fullName = name?.split(" ");
  const initials = fullName.shift()?.charAt(0) + fullName.pop()?.charAt(0);
  return initials.toUpperCase();
};

/**
 * function to remove html tags
 */
export const removeHtmlTag = (text) => {
  let div = document.createElement("div");
  div.innerHTML = text;
  return div.textContent || div.innerText || "";
};

/**
 * function to remove empty spaces from html text
 */
export const removeEmptySpacesFromHtmlText = (text) => {
  return text?.replace(/&nbsp;/g, "")?.trim();
};

/**
 * function to generate file name for uploading files
 */
export const generateFileName = (courseName, assessmentName, fileName) => {
  const time = +new Date();

  return `${courseName}-${assessmentName}-${fileName.split(".")[0]}-${time}.${
    fileName.split(".")[1]
  }`;
};
export const getSlicedTitle = (title, length = 50) => {
  return title?.length > length ? `${title?.slice(0, length)}...` : title;
};

/**
 * copy to clipboard
 */
export const getCopyUrl = (link) => {
  navigator.clipboard.writeText(link);
  message.success("Copied Sucessfully");
};

/**
 * function to add "s" at the end if count is greater than one
 */
export const getPluralNoun = (count) => (count > 1 ? "s" : "");

/**
 * function getHtmlContent - renders rich text content
 * @param {string} content
 */
export const getHtmlContent = (content = "") => {
  return content ? (
    <div
      className="body-1"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  ) : (
    <Empty
      image={icons.emptyEnrolledCourse}
      className="m-auto mt-12"
      description="No data found"
    />
  );
};

export const getLoginUserEmail = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData)?.account?.username;
  }
  return "";
};

export const formatDate = (date, format) => dayjs(date).format(format);

export const uploadProps = {
  name: "file",
  multiple: false,
  fileList: [],
  onChange(info) {
      if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
      }
  },
};
