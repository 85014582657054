import { Col, Row } from "antd";
import "./EmptyChat.scss";

const EmptyChat = ({ emptyChat, showContent }) => {
  const getNoteContent = () => {
    return (
      <>
        <span className="note-header">Note</span>
        <ul className="warn-box-text m-0 pl-14">
          <li>
            Capable of answering questions specific to internal Deloitte data
          </li>
          <li>Remembers what user said earlier in the conversation Sources</li>
          <li>Deloitte Data and OpenAl data to answer questions</li>
        </ul>
      </>
    );
  };

  return (
    <Row className="empty-chat-container">
      {showContent && <Col className="warning-box">{getNoteContent()}</Col>}
      {emptyChat && (
        <Col xs={{ span: 24 }}>
          <div className="left-pos-box">
            <div className="hi-there-box">
              Hi there,
              <br />I am here to provide answers to your queries.
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default EmptyChat;
