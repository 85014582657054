import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";

type LoginAndSignupProps = React.PropsWithChildren;

const LoginAndSignupRedirect: React.FC<LoginAndSignupProps> = (_props) => {
  const { instance, accounts } = useMsal();

  let activeAccount: AccountInfo | null = null;
  if (instance) {
    activeAccount = instance.getActiveAccount() || accounts[0];
    activeAccount &&
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: activeAccount,
        })
        .then((response) => {
          if (response) {
            localStorage.setItem(
              "userData",
              JSON.stringify({
                account: response.account,
                accessToken: response.accessToken,
              })
            );
          }
        });
  }

  useEffect(() => {
    if (!activeAccount) {
      //call login API when component mounts & msal instance does not have any active account
      instance.loginRedirect().catch(console.log);
    }
  }, [activeAccount, instance]);

  return <></>;
};

export default LoginAndSignupRedirect;
