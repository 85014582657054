import React, { useEffect, useState } from "react";
import { Col, Empty, Row, Tabs, ConfigProvider, Spin, Divider } from "antd";
import "./MyLearning.scss";
import { GET_COURSES_FOR_LOGGEDIN_USER } from "services/Queries";
import { useLazyQueryData } from "services";
import { IUserCourseType } from "lib/Types";
import LearningCourseCard from "component/LearningCourseCard/LearningCourseCard";
import { useNavigate } from "react-router-dom";
import { icons } from "assets";
import Breadcrumb from "component/Breadcrumb/Breadcrumb";

const MyLearnings: React.FC = () => {
  const [userCourses, setUserCourses] = useState<IUserCourseType[]>([]);
  const userId = localStorage.getItem("userId");
  const [
    fetchAllCoursesForUser,
    { data: userCourseResp, loading: userCourseLoading },
  ] = useLazyQueryData(GET_COURSES_FOR_LOGGEDIN_USER, {
    userId: userId,
  });

  useEffect(() => {
    fetchAllCoursesForUser();
    if (userCourseResp) {
      setUserCourses(userCourseResp.coursesForLoggedInUser);
      console.log(userCourseResp.coursesForLoggedInUser);
    }
  }, [userCourseResp, fetchAllCoursesForUser]);

  const ongoingCourse = userCourses.filter((item) => {
    return item.userEnrollment.completionStatus === "IN_PROGRESS";
  });

  const completedCourse = userCourses.filter((item) => {
    return item.userEnrollment.completionStatus === "COMPLETED";
  });

  const navigate = useNavigate();
  const breadcrumbItems = [
    {
      label: "My Learnings",
      className: "breadcrumb-child",
    },
  ];

  return (
    <div className="dashboard-container">
      <Row className="mb-12">
        <Col>
          <Breadcrumb items={breadcrumbItems} />
          <Divider className="divider m-0 mt-12" />
        </Col>
      </Row>

      {userCourseLoading ? (
        <div className="dash-loader">
          <Spin size="large" />
        </div>
      ) : (
        <Row>
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  /* here is your component tokens */
                  inkBarColor: "#007CB0",
                  itemActiveColor: "#007CB0",
                  itemHoverColor: "#007CB0",
                  itemSelectedColor: "#007CB0",
                  // itemColor: "#000000D9",
                },
              },
            }}
          >
            <Tabs
              className="my-learning-tabs"
              defaultActiveKey="1"
              items={[
                {
                  label: (
                    <div className="flex flex--space-between flex--align-center learning-tabs-header">
                      <Col className="lt-title">Ongoing Courses</Col>
                      <Col className="mylearnings-tabs-indicator">
                        {ongoingCourse.length}
                      </Col>
                    </div>
                  ),
                  key: "1",
                  children: (
                    <Row className="mylearning-holder">
                      <Row className="list">
                        {ongoingCourse.length === 0 ? (
                          <div className="my-empty-div">
                            <Empty
                              image={icons.noCourses}
                              description={
                                <h4 className="header-4 mt-40">
                                  You have no course under this filter.
                                </h4>
                              }
                              className="flex flex--center flex--column no-course-content"
                            />
                          </div>
                        ) : (
                          <>
                            {ongoingCourse.map((item, index) => (
                              <>
                                <LearningCourseCard {...item} />
                              </>
                            ))}
                          </>
                        )}
                      </Row>
                    </Row>
                  ),
                },

                {
                  label: (
                    <div className="flex flex--space-between flex--align-center learning-tabs-header">
                      <Col className="lt-title">Completed Courses</Col>
                      <Col className="mylearnings-tabs-indicator-completed">
                        {completedCourse.length}
                      </Col>
                    </div>
                  ),
                  key: "2",
                  children: (
                    <Row className="mylearning-holder">
                      <Row className="list">
                        {completedCourse.length === 0 ? (
                          <div className="my-empty-div">
                            <Empty
                              image={icons.noCourses}
                              description={
                                <h4 className="header-4 mt-40">
                                  You have no course under this filter.
                                </h4>
                              }
                              className="flex flex--center flex--column no-course-content"
                            />
                          </div>
                        ) : (
                          <>
                            {completedCourse.map((item, index) => (
                              <>
                                <LearningCourseCard {...item} />
                              </>
                            ))}
                          </>
                        )}
                      </Row>
                    </Row>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </Row>
      )}
    </div>
  );
};
export default MyLearnings;
