export const ORG_NAME = "HashedIn";
export const BOOTCAMP_TOOL = "bootcamp";
export const SELF_LEARNING_TOOL = "self-learning";

export const TIME_TYPES = {
  MINS: 1,
  HOURS: 60,
  DAYS: 60 * 24,
};

export const HTTP_REDUCER_STATUSES = {
  START: "START",
  PROGRESS: "PROGRESS",
  RESPONSE: "RESPONSE",
  ERROR: "ERROR",
};

export const S3_UPLOAD_TYPE = {
  BOOTCAMP_DOCUMENTS: "document",
  BUCKET_CONTENT_DIR: "scaffolding_and_testsuite_zip",
  SELF_LEARNING_CERTIFICATE: "certificate",
};

//FIX: that this should come from BE. post multi tenancy(sonarqube)
export const EXTERNAL_LINKS = {
  GITHUB_LOGIN:
    "https://github.com/login/oauth/authorize?scope=user%20workflow%20repo&client_id=<client_id>&redirect_uri=<redirect_uri>",
  DEFAULT_HASHEDIN_SONARQUBE_SERVER: "https://sonarqube.hashedin.com/",
};
export const STORAGE = {
  GITHUB_CODE: "github_access_code_repo",
  ASSESSMENT_ID: "start_assessment_id",
  BOOTCAMP_ID: "bootcampId",
  BOOTCAMP_NAME: "bootcampTitle",
  BOOTCAMP_TYPE: "bootcampType",
  BOOTCAMP_TIMEZONE: "bootcampTimezone",
  COGNITO_ORG_ID: "cognitoOrgId",
  PAGINATION_STATE: "paginationState",
  SCHEDULE_EVENT: "scheduleEvent",
  ARCHIVE_ASSESSMENT_ID: "archiveAssessmentId",
  ARCHIVE_ASSESSMENT_UUIDS: "archiveAssessmentUuids",
  USER_ASSESSMENT_IDS: "user_assessment_ids",
  CLEAN_UP_INFO: "clean_up_info",
  LABEL: "label",
  DEPLOYMENT_ERROR_REDIRECT: "deployment_error_redirect",
  GITHUB_CONFIG_BUTTON_CLICKED: "githubButtonClicked",
  EVALUATION_TABLE_STATES: "evaluationTableStates",
  SEARCH_PARAMS: "searchParam",
  SELECTED_ASSESSMENT_FOR_REPORTS: "assessment_id_for_reports",
  EXTRA_PAGINATION_FIELDS: "paginationExtraStates",
  COURSE_ID: "course_id",
  OPEN_AI_API_KEY: "open_ai_api_key",
};

export const PYTHON_QUESTIONS = {
  what_is_Python: {
    summary: [
      "Python is a dynamic typing language created by Guido Van Rossum in 1989. It allows for dynamic binding and can be embedded with code from other languages. Python is a general-purpose, high-level, and object-oriented language that focuses on readability. It is interpreted and has a variety of built-in data structures. Python is open source and has a large set of libraries available.",
      "Python is a general-purpose language that can be used for a wide range of development tasks, including scientific computing, cybersecurity, desktop and web applications. It is popular for data analysis, system scripting, and machine learning.",
      "Python is a high-level language that is simple and intuitive for human use. In contrast, C/C++ are low-level languages that require a deep understanding of computer workings. Implementing code in C is more complicated and involves thinking about memory usage and variable types. In Python, appending a number to a list is much simpler.",
      "Python is an Object-Oriented Programming language that uses objects to simplify the writing of large applications.",
      "Python is an interpreted programming language, meaning that the code is run by an interpreter at runtime instead of being compiled beforehand. This allows Python code to be portable and run on different devices. In contrast, compiled languages like C/C++ require a specific compiler to generate machine code for a specific type of computer.",
      "Python uses dynamic semantics, allowing variables to be dynamic objects. This means that Python can determine the type of a variable based on its assigned value. Unlike low-level languages like C, Python does not require static type definitions. This flexibility and intuitiveness make Python programming easier and more efficient.",
      "Python 2 and Python 3 have several differences in terms of data types, print function, string types, integer division, and input functions. Python 3 is closer to Guido's ideal vision for Python as it is built on Python's object model. Python 3 uses separate types for Unicode and ASCII strings, has only one integer type, and integer division can give a float answer. The raw_input() function is not available in Python 3, and print is a function that requires parentheses. Code written for Python 2 needs to be ported to be used with Python 3.",
      "The text provides a list of different flavors of Python, including CPython, Jython, Iron Python, PyObjC, Anaconda Python, Python x-y, and Pypy. These flavors have different features and are used for various purposes such as scientific computing and data analysis.",
      "Python is used in various frameworks and libraries for web development, data analysis, machine learning, and automation tasks. Some popular frameworks include Django, Flask, and Tornado, while libraries like Matplotlib, Pandas, and Scikit are used for visualization, data analysis, and machine learning. Python is also used in integration tasks, text processing, and automation of repetitive tasks. It supports internet protocols and has powerful interactive shells like Jupyter and Ipython.",
      "Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
      "Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
    ],
    questions: [
      [
        "Who is the creator of Python and what inspired him to name it Python?",
        "What are some key features of Python that make it a powerful programming language?",
        "What are some advantages of using Python, such as its syntax and built-in data structures?",
      ],
      [
        "What are some examples of development tasks that Python can be used for?",
        "What are some specific applications that Python can be used for in web development?",
        "What are some popular frameworks that are written in Python?",
      ],
      [
        "What is the difference between a high-level language like Python and a low-level language like C/C++?",
        "How does a high-level language like Python abstract away the inner workings of a computer?",
        "How would you append the number 4 to a list in C language compared to Python?",
      ],
      [
        "How does Python's object-oriented programming (OOP) approach simplify the development of large applications?",
        "What is the significance of objects in Python's OOP language?",
        "How does the use of objects in Python contribute to software architecture?",
      ],
      [
        "What is the difference between interpreted and compiled languages?",
        "Why is Python considered an interpreted language?",
        "How does an interpreter convert human-readable code into machine code in Python?",
      ],
      [
        "What is the difference between dynamic semantics and static semantics in programming languages?",
        "How does Python handle variable types compared to low-level languages like C?",
        "What are some advantages of using Python's dynamic semantics and flexible variable assignment?",
      ],
      [
        "What are the differences between Python 2x and Python 3x?",
        "How does Python 3 handle data types, such as strings and integers, differently from Python 2?",
        "What are the changes in the print function between Python 2 and Python 3?",
      ],
      [
        "What are the different flavors of Python and what are their main characteristics?",
        "How does Jython convert Python code to Java bytecode?",
        "What is the purpose of PyObjC and how does it bridge Python and Objective-C?",
      ],
      [
        "What are some frameworks and libraries in Python used for web and internet development?",
        "How does Python support integration tasks, text processing, and automation of repetitive tasks?",
        "What are some examples of scientific and numeric libraries in Python used for machine learning?",
      ],
      ["", "", ""],
      ["", "", ""],
    ],
  },
  installing_python: {
    summary: [
      "The text provides instructions on how to install Python on Windows using different methods such as downloading the installer from the official website, using Replit, installing from the Microsoft Store, or using Anaconda. It also mentions how to install and update pip, the package installer for Python.",
    ],
    questions: [
      [
        "How can Python be installed on Windows using the full installer?",
        "What is the purpose of pip in Python?",
        "How can pip be updated to the latest version on Windows?",
      ],
    ],
  },
  basic_coding_pratices: {
    summary: [
      "Python is a unique programming language where everything is an object and braces or semicolons are not used. Line endings and indentation are important in Python.",
      'The purpose of the "Hello World" programming phrase is to confirm the functionality of a new development environment.',
      'The import statement in Python is used to import modules, which can contain classes, functions, or other Python objects. An example is given using the datetime module to display the current date and time. Multiple import statements can be used in a Python script, and the rest of the script follows after the import statements. The if statement with the condition name == "main" is used to execute specific code only when the script is directly executed by the Python interpreter.',
      "The text discusses the distinction between expressions and statements in computer languages. An expression is a unit of evaluation that returns a value, while a statement is a line of code that may or may not be an expression. The text provides examples of expressions and statements in Python.",
      "Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
      "Python uses whitespace to determine the block and scope of functions.",
      "### Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
      "In Python 2, the print statement was used to display text, but in Python 3, it is a function and strings are objects. The text provides examples of how to use the print function in Python 3.",
      "Python uses indentation instead of brackets or special characters to delimit blocks of code. The indentation indicates that a line of code is part of a block and associated with the control statement above it.",
      "The scope in Python is defined by functions, objects, and modules rather than blocks. It provides an example of how a block does not define the scope of a variable, and also shows how a function can define the scope of a variable. The text ends with a NameError indicating that the variable 'x' is not defined.",
    ],
    questions: [
      [
        "How does Python differ from other programming languages?",
        "What is the significance of line endings and indentation in Python?",
        "Can you explain the concept of everything being an object in Python?",
      ],
      [
        'What is the purpose of the "Hello World" program in programming?',
        'Why is it beneficial to use minimal, simple, and functional programs like "Hello World" to confirm the new development environment?',
        'How does the "Hello World" program support the development environment?',
      ],
      [
        "What is the purpose of the import statement in Python?",
        "How can you import multiple modules in a single import statement?",
        "When is the code inside the if statement executed in a Python program?",
      ],
      [
        "What is the difference between an expression and a statement in computer languages?",
        "What is considered an expression in Python?",
        "Can a statement be an expression in Python?",
      ],
      [
        "Why does the import statement in Python act as a statement?",
        "How does the assignment act as both an expression and a statement?",
        "Can you provide an example of putting more than one statement in a single line using a semicolon?",
      ],
      [
        "How does Python determine the block and scope of a function?",
        "Why is whitespace significant in Python?",
        "How does Python differ from other modern script languages in terms of whitespace usage?",
      ],
      [
        "How are comments introduced in Python?",
        "What is the purpose of Docstrings in Python?",
        "How can comments be used to describe the functionality of a function in Python?",
      ],
      [
        "What is the difference between the print statement in Python 2 and Python 3?",
        "How can you format a string to include variables in Python 3?",
        "Can you provide an example of printing multiple strings and variables in a single print statement in Python 3?",
      ],
      [
        "How does Python delimit blocks compared to other languages?",
        "What is the purpose of indentation in Python?",
        "What error is raised when the indentation in a Python code block does not match the outer indentation level?",
      ],
      [
        "What are the different entities that define the scope in Python?",
        "How does a block in Python differ from a function in terms of scope?",
        "What error is encountered in the given code snippet and why?",
      ],
    ],
  },
  Common_data_types: {
    summary: [
      "The text discusses data types in Python programming, explaining that data types categorize or classify data items and determine what operations can be performed on them. It mentions various data types such as sets, sequences, numbers, dictionaries, booleans, strings, tuples, complex numbers, and lists.",
      "Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
      "The text provides an overview of the numeric data types in Python, including integers, floats, and complex numbers. It also briefly mentions other data types such as lists, tuples, dictionaries, sets, and strings.",
      "The text provides examples of numeric calculations using different types of numbers. It also includes examples of using mathematical functions and operations.",
      'The text provides information about the Boolean data type in Python. It explains that Boolean values can be either True or False, and that non-Boolean objects can also be evaluated as true or false. The text also includes an example of how to check the type of a Boolean value using the "type()" function. However, there is an error in the example code where "true" is not defined as a Boolean value.',
      "The text provides information about different data types in Python, including lists, tuples, dictionaries, sets, and strings. It mentions that lists are similar to arrays and can have different data types. It also discusses the append method for lists and the delete function for dictionaries. The text briefly mentions nested lists and provides an example.",
      "Tuples in Python are ordered collections of objects that cannot be modified after creation. They are represented by a tuple class and are faster than lists. Tuples can also be nested and can contain different types of objects such as dictionaries, sets, and strings.",
      "The text provides information about dictionaries in Python, including how to create and update them, how to get values from them, and how to remove elements. It also mentions that dictionaries are mutable and can hold key-value pairs.",
      "A set is an unordered collection of mutable and iterable data types in Python that does not allow duplicate numeric elements. Sets can consist of various elements. The order of elements in a set is undefined. Operations such as union, intersection, and difference can be performed on sets. Sets can also be created from lists, tuples, dictionaries, and strings.",
      "The text provides information about three datatype-related functions in Python: type(), id(), and isinstance(). The type() function is used to determine the type of a data type, the id() function returns a unique ID for an object, and the isinstance() function checks if an object is of a specified type.",
      "The string type in Python represents Unicode characters. A string can be represented by a single quote, double-quote, or triple quote collection of one or more characters. Other data types such as lists, tuples, dictionaries, and sets are briefly mentioned.",
      "Indexing in Python is a method used to access individual characters of a string. It allows for negative address references to access characters from the back of the string.",
    ],
    questions: [
      [
        "What are the different data types in Python programming?",
        "How are data types classified in Python?",
        "Can you provide examples of different data types in Python?",
      ],
      [
        "What are the different datatypes available in Python?",
        "How are tuples, dictionaries, numeric types, lists, sets, and strings used in Python?",
        "Can you provide an overview of the copyright information mentioned on the page?",
      ],
      [
        "What are the different numeric data types in Python?",
        "How are integers represented in Python?",
        "Can you provide an example of a complex number in Python?",
      ],
      [
        "What are some examples of numeric calculations using the Python arithmetic operators?",
        "How can the math module be used to perform mathematical operations in Python?",
        "What are some examples of different types of numbers in Python, such as integers, floats, and complex numbers?",
      ],
      [
        "What are the two built-in values of the Boolean data type?",
        "How are Boolean objects that are equal to False represented?",
        "Can non-Boolean objects be evaluated in the Boolean context?",
      ],
      [
        "What is the difference between a list and an array?",
        "How can items be added to a list using the append method?",
        "How can items be deleted from a list using the del keyword?",
      ],
      [
        "What is the main difference between tuples and lists in Python?",
        "How are nested tuples represented in Python?",
        "Can tuples be modified after they are created?",
      ],
      [
        "What is the syntax for creating a dictionary in Python?",
        "How can you update the value of a key in a dictionary?",
        "How can you remove an element from a dictionary?",
      ],
      [
        "What is a set in Python and what are its characteristics?",
        "How are sets different from lists in Python?",
        "What are the operations that can be performed on sets in Python?",
      ],
      [
        "What is the purpose of the type() function in Python?",
        "How does the id() function work in Python?",
        "How does the isinstance() function determine if an object is of a specified type in Python?",
      ],
      [
        "What data type represents a character in Python?",
        "How are Unicode characters represented in Python?",
        "How can multiline strings be defined in Python?",
      ],
      [
        "How can individual characters of a String be accessed in Python?",
        "What is the purpose of indexing in accessing characters of a String?",
        "How does negative address reference work in accessing characters from the back of a String?",
      ],
    ],
  },
  conditional_flow: {
    summary: [
      "Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
      "The text discusses flow control in programming, including for loops, while loops, and conditional statements.",
      "The text discusses conditional statements in Python, specifically if-else statements and nested conditions. It provides an example of how to use if-else statements to determine if an integer is negative, zero, or positive.",
      "The text provides an example of using a for loop to obtain even and odd numbers between 1 and 100. The even numbers are stored in a list called l_even and the odd numbers are stored in a list called l_odd. The lists are then printed.",
      "The text provides an explanation of how to use a while loop in programming to execute a set of statements multiple times. It includes an example of using a while loop to obtain user inputs.",
      "The text warns about the possibility of creating an infinite loop when using while loops. It advises caution to avoid this situation.",
      "The text discusses additional controls in programming, including loop control statements such as break and continue. It also mentions the pass statement in Python, which is used when a statement is required syntactically but no code needs to be executed.",
      "The use of a break statement in programming allows the execution of code to be terminated at a certain point within a loop.",
      "The continue statement in programming is used to move the control back to the beginning of the loop and skip the remaining statements in the current iteration. It is commonly used in conditional code when a certain condition is true.",
      "Summary unavailable on this page. \nNavigate to a new page for a concise summary.",
    ],

    questions: [
      [
        "What are the additional conditions that differentiate a Python set from a mathematical set?",
        "Can elements in a Python set be duplicated? Why or why not?",
        "Why do Python sets not support indexing or slicing operations?",
      ],
      [
        "How can individual values in a set be accessed?",
        "Can a list of individual elements be obtained from a set? If so, how?",
        "What is the purpose of the loop in the given example code?",
      ],
      [
        "How can elements be added to a set?",
        "What method is used to add elements to a set?",
        "Is there a specific index attached to newly added elements in a set?",
      ],
      [
        "How can elements be removed from a set?",
        "What method is used to remove elements from a set?",
        "Is there a specific index attached to the newly added element in a set?",
      ],
      [
        "What is the result of the union operation on the sets DaysA and DaysB?",
        "What is the result of the union operation on the sets x1 and x2?",
        "Why does the code print an error when trying to perform the union operation on x1 and x3?",
      ],
      [
        "What is the result of the intersection operation between DaysA and DaysB sets?",
        "How does the intersection operation work in sets?",
        "Can you provide an example of using the intersection method on sets?",
      ],
      [
        "How is a new set produced through the difference operation on two sets?",
        'Why is the element "Wed" not present in the result set in the displayed example?',
        "What is the difference between the difference() and symmetric_difference() methods in set operations?",
      ],
      [
        "How can you determine if a set is a subset of another set?",
        "What is the result of the comparison between DaysA and DaysB sets?",
        "How can you determine if a set is a superset of another set?",
      ],
    ],
  },
};

export const PYTHON_PROMPT = `You are a Python Functions 101 Smart Tutor. As an experienced tutor specializing in Python functions, you assist learners in understanding various concepts and solving coding challenges.


The user interacting with you is a developer focusing on learning python 101.
Your objective is to help learners understand Python functions by providing coding challenges, guiding questions, and evaluating their solutions. When the user says 'Challenge my Knowledge', assign them one of the Python functions coding challenge.
Encourage users to write their own code and evaluate it. Assess their solutions based on correctness, clarity, and adherence to best practices. Provide constructive feedback and encourage iteration for improvement.

Few examples of Coding Challenges:
1. Write a program to create a function called displayValue() that accepts a variable length of arguments and prints their values.

2. Create a function named calculation() that accepts two variables and performs addition and subtraction. It should return both the addition and subtraction results in a single return call.

3. Write a function to find the sum of a list of numbers.

4. Develop a decorator to cache function invocation results using the given fibonacci(n) example.

5.Write a timer decorator to measure the speedup achieved due to memoization.

Remember to prompt the user to provide their code and evaluate it. Evaluate their solutions based on correctness, efficiency, adherence to Python best practices, and any specific requirements mentioned in the challenge.

Encourage users to ask questions and engage in conversation to understand the concepts better. Provide guidance and ask guiding questions to help them arrive at the correct solutions.
Ensure to ask different question every time and dont give direct solution to the user. Always provide strictly only direction.`;
