import { Button, Col, Divider, Row, Spin, Tabs, Typography } from 'antd';
import Assessments from 'component/Assessments/Assessments';
import GenerateQuiz from 'component/GenerateQuiz/GenerateQuiz';
import { useEffect, useState } from 'react';
import { useLazyQueryData, useMutationData } from 'services';
import { SUMIT_PREVIEW } from 'services/Mutation';
import { GET_MODULE_DETAILS } from 'services/Queries';
import { v4 as uuidv4 } from "uuid";

const { TabPane } = Tabs;
const { Text } = Typography;

const FinalAssessment = ({ onNext, onPrev, sectionDetails, setSectionDetails, courseId }) => {
    const [activeTab, setActiveTab] = useState("Assessment");
    const [isPresent, setIsPresent] = useState(false);
    const [mNumber, setMNumber] = useState(null);
    const [sectionClicked, setSectionClicked] = useState<any>({});
    const [isDone, setIsDone] = useState(false);
    const [isExecute, setIsExecute] = useState(false);
    const [isLatest, setIsLatest] = useState(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    // ====Queries====
    const [
        getModuleDetails,
        { data: moduleDetailsResp, loading: moduleDetailsLoading },
    ] = useLazyQueryData(GET_MODULE_DETAILS, {
        courseId: courseId,
    });

    // ====Mutations====
    const [detailsRelData, { loading: detailsRelLoading }] = useMutationData(SUMIT_PREVIEW);

    useEffect(() => {
        getModuleDetails();
    }, []);

    useEffect(() => {
        if (moduleDetailsResp && !isDone) {
            let isFSPresent = false;

            const lastModuleNumber = (sectionDetails?.[sectionDetails.length - 1]?.moduleNumber) || 0;
            const newModuleNumber = lastModuleNumber + 1;
            const newModuleNumberForBadges = lastModuleNumber + 2;

            isFSPresent = moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.some((module) => {
                return module.module.moduleType === "FINAL_ASSESSMENT";
            });

            if (!isFSPresent) {
                setIsPresent(true);
                setMNumber(newModuleNumber);
                setSectionDetails((prev) => [
                    ...prev,
                    { newEntry: true, moduleId: uuidv4(), moduleType: "FINAL_ASSESSMENT", title: "Final Assessment", moduleNumber: newModuleNumber, lessons: [], assessments: [], quizzes: [] },
                    { newEntry: true, moduleId: uuidv4(), moduleType: "BADGES", title: "Badge and Certificate", moduleNumber: newModuleNumberForBadges, lessons: [], assessments: [], quizzes: [] }
                ]);
            } else {
                setIsDone(true);
            }
        }
    }, [moduleDetailsResp]);

    useEffect(() => {
        if (sectionDetails && isPresent && mNumber !== null) {
            detailsRelData({
                variables: {
                    courseId,
                    details: sectionDetails?.map(({ m_id, moduleId, newEntry, title, moduleNumber, lessons, moduleType }) => ({
                        id: newEntry === false ? m_id : undefined,
                        moduleId: moduleId,
                        newEntry: newEntry,
                        moduleName: title,
                        moduleType: moduleType,
                        moduleNumber: moduleType === "FINAL_ASSESSMENT" ? mNumber : moduleNumber,
                        lessons: lessons.map(({ l_id, lessonId, newEntry, orderNumber, lessonName, fileType, fileName }) => ({
                            id: newEntry === false ? l_id : undefined,
                            lessonId: lessonId,
                            newEntry: newEntry,
                            orderNumber: orderNumber,
                            lessonName,
                            contentType: fileType,
                            contentLink: fileName
                        }))
                    }))
                }
            }).then(() => {
                setIsDone(true);
            }).then(() => {
                setIsPresent(false);
            });
        }
    }, [sectionDetails, mNumber, isPresent]);

    useEffect(() => {
        if (isDone) {
            getModuleDetails().then(() => { setIsExecute(true); });
        }
    }, [isDone]);

    useEffect(() => {
        if (moduleDetailsResp && isExecute) {
            const responseData = moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.map((module) => {
                const m_id = module.module.id;
                const moduleId = module.module.moduleId;
                const title = module.module.moduleName;
                const moduleNumber = module.module.moduleNumber;
                const moduleType = module.module.moduleType;
                const lessons = module.moduleMapper.filter(item => item.lesson !== null).map((item) => {
                    const l_id = item.lesson.id;
                    const lessonId = item.lesson.lessonId;
                    const lessonName = item.lesson.lessonName;
                    const fileType = item.lesson.contentType;
                    const fileName = item.lesson.contentLink;
                    const orderNumber = item.orderNumber;
                    const summary = item.lesson.summary;
                    const suggestiveQuestions = item.lesson.suggestiveQuestions;

                    return {
                        l_id,
                        newEntry: false,
                        lessonId,
                        lessonName,
                        fileType,
                        fileName,
                        orderNumber,
                        summary,
                        suggestiveQuestions
                    }
                });

                const quizzes = module.moduleMapper.filter(item => item.quiz !== null).map((item) => {
                    const id = item.quiz.id;
                    const title = item.quiz.title;
                    const description = item.quiz.description;
                    const totalScore = item.quiz.totalScore;
                    const duration = item.quiz.duration;
                    const prerequisite = item.quiz.prerequisite;
                    const orderNumber = item.orderNumber;

                    return {
                        id,
                        title,
                        description,
                        totalScore,
                        duration,
                        prerequisite,
                        orderNumber
                    }
                });

                const assessments = module.moduleMapper.filter(item => item.miniAssessment !== null).map((item) => {
                    const id = item.miniAssessment.id;
                    const title = item.miniAssessment.title;
                    const duration = item.miniAssessment.duration;
                    const description = item.miniAssessment.description;
                    const orderNumber = item.orderNumber;

                    return {
                        id,
                        title,
                        duration,
                        description,
                        orderNumber
                    }
                });

                return {
                    m_id,
                    moduleId,
                    newEntry: false,
                    title,
                    moduleNumber,
                    moduleType,
                    lessons,
                    quizzes,
                    assessments
                }
            });

            setSectionDetails(responseData);
            setIsLatest(true);
        }
    }, [moduleDetailsResp, isExecute]);

    useEffect(() => {
        if(sectionDetails?.length >= 2 && isLatest) {
            setSectionClicked(sectionDetails?.[sectionDetails?.length - 2]);
        }
    }, [sectionDetails, isLatest]);

    return (
        <>
            {moduleDetailsLoading || detailsRelLoading ? (
                <div className="loading-spinner">
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Row gutter={24} className="segments-container">
                        <Col className="segments-header" span={24}>
                            <Text className="header-5 capitalize segments-header-title">
                                {`Final Assessment`}
                            </Text>
                            <Divider className="breadcrumb-divider m-0 mt-12" />
                        </Col>
                        <Col span={24} className="segments-content">
                            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                                <TabPane tab="Assessment" key="Assessment">
                                    <Assessments
                                        sectionDetails={sectionDetails}
                                        sectionClicked={sectionClicked}
                                        courseId={courseId}
                                        updateSection={setSectionDetails}
                                        fmode={"FINAL_ASSESSMENT"}
                                    />
                                </TabPane>
                                <TabPane tab="Generate Quiz" key="Generate Quiz">
                                    <GenerateQuiz
                                        sectionDetails={sectionDetails}
                                        sectionClicked={sectionClicked}
                                        courseId={courseId}
                                        updateSection={setSectionDetails}
                                        fmode={"FINAL_ASSESSMENT"}
                                    />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>

                    <Row gutter={[24, 24]} className="course-form-button-container mt-12">
                        <Col span={24}>
                            <Row className="flex flex--space-between mb-20 mt-20 mr-12">
                                <Col></Col>
                                <Col>
                                    <Button
                                        className="form-clear-button mr-8"
                                        onClick={() => onPrev()}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        className="form-next-button"
                                        onClick={() => onNext()}
                                    >
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default FinalAssessment;