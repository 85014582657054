import { useMsal } from "@azure/msal-react";
import { Col, Menu } from "antd";
import { icons } from "assets";
import { INavBarProps } from "component/lib/interface";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useLazyQueryData } from "../../services";
import { GET_LOGGEDIN_USER } from "../../services/Queries";
import AddContent from "./Icons/AddContent";
import MyLearning from "./Icons/MyLearning";
import DashboardIcon from "./Icons/dashboardIcon";
import "./index.scss";

const getPath = (path) => {
  if(path === "/mylearnings" || path === "/content") {
    return path;
  } else {
    return "/";
  }
}

const Navbar: React.FC<INavBarProps> = ({ setCollapsed, collapsed }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(getPath(location.pathname));

  const [fetchUser, { data: userResp }] = useLazyQueryData(GET_LOGGEDIN_USER);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    fetchUser();
    console.log(userResp);
    if (userResp) {
      if (!userResp.users){
        instance.loginRedirect().catch((error) => console.log(error));
      }
      localStorage.setItem("userId", userResp.users?.id);
    }
  }, [userResp, fetchUser]);

  const ROUTES = [
    {
      path: "/",
      key: "/",
      name: "Home",
      icon: <DashboardIcon active={selectedKeys === "/"} />,
    },
    {
      path: "/mylearnings",
      key: "/mylearnings",
      name: "My Learnings",
      icon: <MyLearning active={selectedKeys === "/mylearnings"} />,
    },

    ...(userResp?.users?.isContentCreator
      ? [
          {
            path: "/content",
            key: "/content",
            name: "My Content",
            icon: <AddContent active={selectedKeys === "/content"} />,
          },
        ]
      : []),
  ];

  return (
    <div className="navbar-container flex flex--column">
      <div
        className=" nav-collapse"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {collapsed ? (
          <div
            className="navbar-logo-container"
            onClick={() => setCollapsed(!collapsed)}
          >
            <img
              src={icons.deloitteLogo}
              alt="appLOGO"
              style={{ height: "30px", width: "30px" }}
            />
          </div>
        ) : (
          <div
            className="navbar-logo-container"
            onClick={() => setCollapsed(!collapsed)}
          >
            <img
              src={icons.deloitteTextLogo}
              alt="appLOGO"
              style={{ height: "25px" }}
            />
            {/* <span className="navbar-logo-title">Super Tutor</span> */}
          </div>
        )}
      </div>
      <Menu mode="vertical" selectedKeys={[selectedKeys]}>
        {ROUTES.map((route) => (
          <Menu.Item
            key={route.key}
            className="modifiedItem"
            onClick={() => {
              setSelectedKeys(route.key);
            }}
          >
            <NavLink className="nav-link" key={route.key} to={route.path}>
              <Col span={5} className={`menu-icon `}>
                {route.icon}
              </Col>
              <Col className="menu-name">{route.name}</Col>
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default Navbar;
