import {
  Button,
  Upload,
  message,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Spin,
} from "antd";
import "./LessonDetails.scss";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import LessonTile from "component/LessonTile/LessonTile";
import { useEffect, useState } from "react";
import { useLazyQueryData, useMutationData } from "services";
import { GENERATE_PUT_URI, SUMIT_PREVIEW } from "services/Mutation";
import { GET_MODULE_DETAILS } from "services/Queries";
import { icons } from "assets";
import { ADMIN_MODAL_MODIFY } from "component/lib/Constants";
import SuggestiveQuestionsAndSummary from "component/SuggestiveQuestionsAndSummary/SuggestiveQuestionsAndSummary";

const { Dragger } = Upload;

const LessonDetails = ({ section, sectionDetails, updateSection, courseId, course_cuuid }) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("CREATE");
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [lessonButtonClicked, setLessonButtonClicked] = useState(false);
  const [deleteLessonModalIsOpen, setDeleteLessonModalIsOpen] = useState(false);
  const [editLessonModalIsOpen, setEditLessonModalIsOpen] = useState(false);
  const [clickedLessonIndex, setClickedLessonIndex] = useState("");
  const [clickedLesson, setClickedLesson] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [cuuid, setcuuid] = useState(null);
  const [removeSpan, setRemoveSpan] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [contentLink, setContentLink] = useState(null);
  const [showQuestionsScreen, setShowQuestionsScreen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showSummarySuggestiveForm, setShowSummarySuggestiveForm] = useState(true);
  const [addLessonUuid, setAddLessonUuid] = useState(null); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [checkDelete, setCheckDelete] = useState(false);

  // ====Mutations====
  const [detailsRelData, { loading: detailsRelLoading }] = useMutationData(SUMIT_PREVIEW);
  const [generatePutUri, { loading: generatePutUriLoading }] = useMutationData(GENERATE_PUT_URI);

  // ====Queries====
  const [
    getModuleDetails,
    { data: moduleDetailsResp, loading: moduleDetailsLoading },
  ] = useLazyQueryData(GET_MODULE_DETAILS, {
    courseId: courseId,
  });

  useEffect(() => {
    setcuuid(uuidv4());
  }, []);

  const getIndex = (id: any) => {
    for (let i = 0; i < sectionDetails.length; i++) {
      if (sectionDetails[i].moduleId === id) return i;
    }
    return 0;
  };

  const updateObject = (idToUpdate: any, newLessons: any) => {
    // Create a new array with the updated object
    const updatedObjects = sectionDetails.map((obj) =>
      obj.moduleId === idToUpdate
        ? { ...obj, lessons: [...obj.lessons, newLessons] }
        : obj
    );

    // Update the state with the new array
    updateSection(updatedObjects);
  };

  const handleAddLesson = () => {
    setRemoveSpan(true);

    form
      .validateFields()
      .then(() => {
        const errors = form.getFieldsError();
        const hasErrors = errors.some(
          (fieldError) => fieldError.errors.length !== 0
        );

        if (!hasErrors) {
          const formData = form.getFieldsValue();
          const uploadedFile = formData?.uploadContent;

          if (!uploadedFile) {
            message.error("Please upload a file");
          } else {
            const newLesson = {
              lessonId: cuuid,
              lessonName: formData.lessonName,
              fileType: "application/pdf",
              fileName: formData.uploadContent
            };

            if (mode === "CREATE") {
              const sectionDetailsForModule = sectionDetails.find(item => item?.moduleId === section?.moduleId);
              
              const lessonsOrderNumbers = (sectionDetailsForModule?.lessons || []).map(lesson => lesson?.orderNumber || 0);
              const assessmentsOrderNumbers = (sectionDetailsForModule?.assessments || []).map(assessment => assessment?.orderNumber || 0);
              const quizzesOrderNumbers = (sectionDetailsForModule?.quizzes || []).map(quiz => quiz?.orderNumber || 0);
              
              const maxLessonsOrderNumber = lessonsOrderNumbers.length === 0 ? 0 : Math.max(...lessonsOrderNumbers);
              const maxAssessmentsOrderNumber = assessmentsOrderNumbers.length === 0 ? 0 : Math.max(...assessmentsOrderNumbers);
              const maxQuizzesOrderNumber = quizzesOrderNumbers.length === 0 ? 0 : Math.max(...quizzesOrderNumbers);

              const orderNumber = Math.max(maxLessonsOrderNumber, maxAssessmentsOrderNumber, maxQuizzesOrderNumber) + 1;
              setAddLessonUuid(newLesson?.lessonId);

              updateObject(section.moduleId, {
                ...newLesson,
                orderNumber,
                newEntry: true
              });
            } else {
              const updatedObj = sectionDetails.map((sectionItem) => {
                if (sectionItem.moduleId === section.moduleId) {
                  const updatedLessons = sectionItem.lessons.map(
                    (lessonItem) => {
                      if (lessonItem.lessonId === selectedLessonId) {
                        return {
                          ...lessonItem,
                          lessonName: formData.lessonName,
                          fileType: "application/pdf",
                          fileName: formData.uploadContent
                        };
                      }

                      return lessonItem;
                    }
                  );

                  return { ...sectionItem, lessons: updatedLessons };
                } else {
                  return sectionItem;
                }
              });
              updateSection(updatedObj);
              setMode("CREATE");
            }

            form.resetFields();
            setLessonButtonClicked(true);
          }
        }
      })
      .catch((errorInfo) => { });
  };

  useEffect(() => {
    if (selectedFile && selectedFile.name && cuuid) {
      console.log("selectedFile", selectedFile);
      const path = `course_content/course_${course_cuuid}/module_${section.moduleId}/lesson_${cuuid}/${selectedFile?.name}`;

      const processSelectedFile = async () => {
        const generatePutUriResponse = await generatePutUri({
          variables: {
            blobName: path
          }
        });

        const { url, blobPath } = generatePutUriResponse?.data?.generateSasPutUri ?? {};

        // This will give error for now.
        try {
          await fetch(url, {
            method: 'PUT',
            headers: {
              "x-ms-blob-type": "BlockBlob"
            },
            body: selectedFile
          });
        } catch (error) {
          console.log("Error uploading file", error);
        }

        setContentLink(blobPath);
        form.setFieldsValue({
          uploadContent: blobPath
        });
      }

      processSelectedFile();
    }
  }, [selectedFile]);

  useEffect(() => {
    if(contentLink) {
      setIsButtonDisabled(false);
    }
  }, [contentLink]);

  useEffect(() => {
    if (lessonButtonClicked) {
      detailsRelData({
        variables: {
          courseId,
          details: sectionDetails?.map(({ m_id, moduleId, newEntry, title, moduleNumber, lessons }) => ({
            id: newEntry === false ? m_id : undefined,
            moduleId: moduleId,
            newEntry: newEntry,
            moduleName: title,
            moduleNumber: moduleNumber,
            lessons: lessons.map(({ l_id, lessonId, newEntry, orderNumber, lessonName, fileType, fileName, suggestiveQuestions, summary }) => ({
              id: newEntry === false ? l_id : undefined,
              lessonId: lessonId,
              newEntry: newEntry,
              orderNumber: orderNumber,
              lessonName,
              contentType: fileType,
              contentLink: newEntry === false ? fileName : contentLink,
              suggestiveQuestions,
              summary
            }))
          }))
        }
      }).then(() => {
        getModuleDetails();
      }).then(() => {
        setLessonButtonClicked(false);
        setcuuid(uuidv4());
      }).then(() => {
        setCheckDelete(true);
      });
    }
  }, [lessonButtonClicked]);

  useEffect(() => {
    if(!isDeleted && checkDelete) {
      setShowQuestionsScreen(true);
      setCheckDelete(false);
    }

    if(isDeleted && checkDelete) {
      setIsDeleted(false);
      setCheckDelete(false);
    }
  }, [checkDelete, isDeleted]);

  useEffect(() => {
    if (moduleDetailsResp && moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.length > 0) {
      console.log("clicked module", moduleDetailsResp);
      const responseData = moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.map((module) => {
        const m_id = module.module.id;
        const moduleId = module.module.moduleId;
        const title = module.module.moduleName;
        const moduleNumber = module.module.moduleNumber;
        const moduleType = module.module.moduleType;
        const lessons = module.moduleMapper.filter(item => item.lesson !== null).map((item) => {
          if(item.lesson.lessonId === addLessonUuid) {
            setClickedLesson(item.lesson);
          }

          const l_id = item.lesson.id;
          const lessonId = item.lesson.lessonId;
          const lessonName = item.lesson.lessonName;
          const fileType = item.lesson.contentType;
          const fileName = item.lesson.contentLink;
          const orderNumber = item.orderNumber;
          const summary = item.lesson.summary;
          const suggestiveQuestions = item.lesson.suggestiveQuestions;

          return {
            l_id,
            newEntry: false,
            lessonId,
            lessonName,
            fileType,
            fileName,
            orderNumber,
            summary,
            suggestiveQuestions
          }
        });

        const quizzes = module.moduleMapper.filter(item => item.quiz !== null).map((item) => {
          const id = item.quiz.id;
          const title = item.quiz.title;
          const description = item.quiz.description;
          const totalScore = item.quiz.totalScore;
          const duration = item.quiz.duration;
          const prerequisite = item.quiz.prerequisite;
          const orderNumber = item.orderNumber;


          return {
            id,
            title,
            description,
            totalScore,
            duration,
            prerequisite,
            orderNumber
          }
        });

        const assessments = module.moduleMapper.filter(item => item.miniAssessment !== null).map((item) => {
          const id = item.miniAssessment.id;
          const title = item.miniAssessment.title;
          const duration = item.miniAssessment.duration;
          const description = item.miniAssessment.description;
          const orderNumber = item.orderNumber;


          return {
            id,
            title,
            duration,
            description,
            orderNumber
          }
        });

        return {
          m_id,
          moduleId,
          newEntry: false,
          title,
          moduleNumber,
          moduleType,
          lessons,
          quizzes,
          assessments
        }
      });

      updateSection(responseData);
    }
  }, [moduleDetailsResp]);

  const customRequest = async (options) => {
    try {
      setSelectedFile(options.file);
      setRemoveSpan(false);
      setFileName(options.file.name);
      setIsButtonDisabled(true);
      message.success('File uploaded successfully');
    } catch (error) {
      options.onError(error);
    }
  }

  const handleLessonDelete = (lessons, index) => {
    const newLesson = lessons.filter(
      (lesson, lessonIndex) => lessonIndex !== index
    );

    setLessonButtonClicked(true);
    const updatedObjects = sectionDetails.map((obj) =>
      obj.moduleId === section.moduleId ? { ...obj, lessons: newLesson } : obj
    );

    // Update the state with the new array
    updateSection(updatedObjects);
    setIsDeleted(true);
  };

  const onLessonDelete = (index) => {
    form.resetFields();
    setMode("CREATE");
    setFileName(null);
    setContentLink(sectionDetails[getIndex(section.moduleId)]?.lessons[index]?.fileName);
    handleLessonDelete(sectionDetails[getIndex(section.moduleId)]?.lessons, index);
  };

  const handleOnEditForm = (lesson, index) => {
    form.setFieldsValue({
      lessonName: lesson.lessonName,
      uploadContent: lesson.fileName,
    });

    setMode("EDIT");
    const parts = lesson && lesson?.fileName?.split("/");
    const lastPart = parts?.[parts?.length - 1];
    setContentLink(lesson.fileName);
    setFileName(lastPart);
    setRemoveSpan(false);
    setSelectedLessonId(lesson.lessonId);
    setAddLessonUuid(lesson?.lessonId);
  };

  useEffect(() => {
    getModuleDetails();
  }, [showQuestionsScreen]);

  return (
    <>
      {moduleDetailsLoading ? 
        <div className="spin-container">
          <Spin size="large" />
        </div>
      : !showQuestionsScreen && (
        <>
          {sectionDetails[getIndex(section.moduleId)].lessons.length !== 0 && (
            <>
              {sectionDetails[getIndex(section.moduleId)].lessons?.map(
                (lesson, index) => {
                  return (
                    <LessonTile
                      lesson={lesson}
                      handleLessonDelete={() => {
                        setClickedLessonIndex(index);
                        setDeleteLessonModalIsOpen(true);
                      }}
                      mode="CREATE"
                      onEdit={() => {
                        setClickedLessonIndex(index);
                        setClickedLesson(lesson);
                        setEditLessonModalIsOpen(true);
                      }}
                    />
                  );
                }
              )}
            </>
          )}

          <Col span={24} className="lesson-container mb-6">
            <Form form={form}>
              <Row
                gutter={[40, 40]}
                align="middle"
                className="lesson-tile-container"
              >
                <Col span={24} className="add-lesson-tile">
                  <Col span={12} className="label mt-8 mb-8">
                    <span className="mandatory">* </span>Lesson Name
                  </Col>
                  <Col></Col>
                  <Form.Item
                    name="lessonName"
                    rules={[
                      {
                        required: true,
                        message: `Please input Lesson Name`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input size="large" placeholder="Enter Lesson title" />
                  </Form.Item>

                  <Col span={12} className="label mt-20 mb-8">
                    <span className="mandatory">* </span>Upload Course Content
                  </Col>
                  <Col></Col>
                  <Form.Item
                    name="uploadContent"
                    rules={[
                      {
                        required: true,
                        message: `Please upload course content`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Dragger
                      customRequest={customRequest}
                      showUploadList={false}
                      maxCount={1}
                      accept=".pdf"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ color: "#357bac" }} />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single upload. Strictly prohibited
                        from uploading company data or other banned files.
                      </p>
                    </Dragger>
                  </Form.Item>
                  {!removeSpan && <span>{fileName}</span>}

                  <Col
                    span={24}
                    className="mt-8 mb-8 flex flex--space-between form-add-lesson-btn-col-p"
                  >
                    <Col></Col>
                    <Col className="form-add-lesson-btn-col">
                      <Button
                        className="form-add-lesson-button mr-8"
                        disabled={isButtonDisabled}
                        onClick={handleAddLesson}
                      >
                        {mode === "CREATE" ? "Add Lesson" : "Edit Lesson"}
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Col>
        </>
      )}

      {showQuestionsScreen && (
        <>
          <SuggestiveQuestionsAndSummary
            blobPath={contentLink}
            courseId={courseId}
            lesson={clickedLesson}
            setShowQuestionsScreen={setShowQuestionsScreen}
            showSummarySuggestiveForm={showSummarySuggestiveForm}
            setShowSummarySuggestiveForm={setShowSummarySuggestiveForm}
            sectionDetails={sectionDetails}
          />
        </>
      )}

      <Modal
        title={
          <>
            <span className="modal-title">
              <img src={icons.deleteIcon} alt="deleteIcon icon" className="modal-title-image"/>
              <span>{ADMIN_MODAL_MODIFY}</span>

            </span>

            <span className="modal-content">Are you sure you want to delete the lesson?</span>
          </>
        }
        visible={deleteLessonModalIsOpen}
        onCancel={() => setDeleteLessonModalIsOpen(false)}
        className="lesson-modal"
        footer={[
          <Button
            key="no"
            onClick={() => setDeleteLessonModalIsOpen(false)}
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              onLessonDelete(clickedLessonIndex);
              setDeleteLessonModalIsOpen(false);
            }}
          >
            Yes
          </Button>,
        ]}
      />

      <Modal
        title={
          <>
            <span className="modal-title">
              <EditOutlined className="modal-title-image"/>
              <span>{ADMIN_MODAL_MODIFY}</span>

            </span>

            <span className="modal-content">Are you sure you want to edit the lesson?</span>
          </>
        }
        visible={editLessonModalIsOpen}
        onCancel={() => setEditLessonModalIsOpen(false)}
        className="lesson-modal"
        footer={[
          <Button
            key="no"
            onClick={() => setEditLessonModalIsOpen(false)}
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              handleOnEditForm(clickedLesson, clickedLessonIndex);
              setEditLessonModalIsOpen(false);
            }}
          >
            Yes
          </Button>,
        ]}
      />
    </>
  );
};

export default LessonDetails;
