import { Col, Row } from 'antd';
import './AssessmentTile.scss';
import { icons } from 'assets';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const AssessmentTile = ({ assessment, isPreview = false, handleassessmentDelete = () => { }, mode = "EDIT", onEdit = () => { } }) => {
    return (
        <Col span={24} className="assessment-details-tile">
            <Row
                gutter={[40, 40]}
                align="middle"
                className="flex flex--space-between assessment-details-tile-row"
            >
                <Col>
                    <Row
                        className="assessment-details-tile-heading flex flex--align-center"
                        gutter={[24, 24]}
                    >
                        <Col>
                            <span className="title">{assessment.title}</span>
                        </Col>
                        <Col>
                            <img src={icons.techAssignmentIcon} alt="YourImage" />
                        </Col>
                        <Col>
                            <div>
                                <span className="duration">| 50min</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {!isPreview && (
                    <Col className='assessment-details-tile-icons'>
                        <EditOutlined
                            style={{ color: "#08979C" }}
                            className="mr-16 edit-icon"
                            onClick={() => {
                                onEdit();
                            }}
                        />
                        <DeleteOutlined
                            style={{ color: "#A8071A" }}
                            className="mr-16 delete-icon"
                            onClick={handleassessmentDelete}
                        />
                    </Col>
                )}
            </Row>
        </Col>
    )
}

export default AssessmentTile;