/* eslint-disable react-hooks/exhaustive-deps */
import { ReloadOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import CurrentSettings from "auth/Config";
import { useEffect, useState } from "react";
import { useLazyQueryData, } from "services";
import {
  PROXY_CONVERSATION_API
} from "services/Queries";
import { v4 as uuidv4 } from "uuid";
import ChatHeader from "./ChatHeader/ChatHeader";
import ChatTextInput from "./ChatTextInput/ChatTextInput";
import { getFetchRequestContent, handleChunk } from "./ChatTextInput/ChatTextInput.helper";
import EmptyChat from "./EmptyChat/EmptyChat";
import MessageBox from "./MessageBox/MessageBox";
import ShowQuestions from "./ShowQuestions/ShowQuestions";
import {
  getFormattedData,
  getInitials,
  pythonPrompt,
  scrollToBottom,
  scrolledToBottom,
} from "./SmartTutor.helper";
import "./SmartTutor.scss";

const { Configuration, OpenAIApi } = require("openai");

const SmartTutor = ({
  isBackend = false,
  visible = false,
  filePath = "",
  assessmentId,
  selectedTaskIndex,
  questionsList = [],
  pageContext = true,
  prompt = pythonPrompt,
  forLearning = false,
  local = true,
  questionEmpty = false,
  loadFail,
  contentType,
  courseId
}) => {
  const FetchApiCall = async (
    url = "",
    method = "GET",
    headers = {},
    body = undefined
  ) => {
    try {
      const response = await fetch(url, {
        method,
        headers,
        body: body ? body : undefined,
      });
      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      if (method === "GET") {
        //we are taking direct response in case of streamed data
        if (
          url.includes("generate_questions_for_document") ||
          url.includes("generate_summary_for_document")
        ) {
          return response;
        }
        const data = await response.json();
        return data;
      }
      return;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  const params = new URLSearchParams();
  params.append("system_name", filePath);

  const [disable, setDisable] = useState(false);
  const [responses, setResponses] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [maintainHistory, setMaintainHistory] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [messageId, setMessageId] = useState(uuidv4());
  const [prevMessageId, setPrevMessageId] = useState("");
  const [conversationId, setConversationId] = useState(uuidv4());
  const [conversationIsNew, setConversationIsNew] = useState(true);
  const [proxyConversationVariables, setproxyConversationVariables] = useState(null);
  const [
    proxyConversationAPI,
    { data: proxyConversationAPIResp, loading: generateUriLoading },
  ] = useLazyQueryData(PROXY_CONVERSATION_API, proxyConversationVariables);
  const [actionTypeState, setActionTypeState] = useState('')

  const [lastItem] = responses?.slice(-2) ?? null;

  const [items, setItems] = useState([]);

  const getQuestions = () => {
    if (questionsList?.length) {
      return questionsList;
    } else return [[]].concat(questions?.filter((item) => item?.length));
  };
  const flattenedQuestions = getQuestions();

  const getDocuments = async () => {
    const data = await FetchApiCall(
      `${CurrentSettings.smart_tutor_base_uri}/fetch_all_documents`
    );
    setItems(data);
  };

  useEffect(() => {
    if (!questionsList?.length) getDocuments();
  }, []);

  useEffect(() => {
    if (!questionsList?.length) setMaintainHistory(false);
  }, [selectedTaskIndex]);

  useEffect(() => {
    items.forEach((file) => {
      if (
        JSON.parse(CurrentSettings.assessment_document_mapper)[assessmentId] ===
        file.id
      ) {
        setQuestions(getFormattedData(file.questions));
      }
    });
  }, [items]);

  useEffect(() => {
    const handleScroll = () => scrolledToBottom(setIsScrolledToBottom);
    const handleResize = () => scrolledToBottom(setIsScrolledToBottom);

    scrolledToBottom(setIsScrolledToBottom);
    const chatInfo = document.querySelector("#chatContainer");
    chatInfo?.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    if (!isScrolledToBottom) {
      scrollToBottom();
    }
    return () => {
      chatInfo?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isScrolledToBottom, responses]);

  const handleSubmit = async (e = null, item = null, actionType) => {
    if (e) {
      e.preventDefault();
    }

    if (inputValue.trim() !== "" || item?.length) {
      setResponses([
        ...responses,
        { user: "user", message: `${item ?? inputValue}` },
      ]);

      if (!item?.length) {
        setInputValue("");
      }
    }

    // to add update user input in chatlogs
    const responsesNew = [
      ...responses,
      { user: "user", message: typeof (actionType) === "undefined" ? `${item ?? inputValue}` : lastItem?.message },
    ];
    setResponses(responsesNew);
    setActionTypeState(actionType);

    // show loading until response is coming from api
    setResponses([...responsesNew, { user: "chatbot", message: "Loading..." }]);
    setDisable(true);
    const payloadAction = typeof (actionType) === "undefined" ? (!conversationIsNew ? "next" : "new") : actionType;

    try {
      let payload = {
        action: payloadAction,
        message: {
          id: typeof (actionType) === "undefined" ? messageId : prevMessageId,
          author: {
            role: "user",
          },
          content: {
            content_type: "string",
            parts: typeof (actionType) === "undefined" ? (item ?? inputValue) : lastItem?.message,
          },
        },
        parent_message_id: prevMessageId,
        project_id: courseId
      };
      let response = await getFetchRequestContent(conversationId, payload);
      const reader = response.body.getReader();
      let fileContentBuffer = "";
      reader
        .read()
        .then(({ done, value }) =>
          handleChunk(
            { done, value },
            setResponses,
            responsesNew,
            fileContentBuffer,
            messageId,
            actionType,
            setMessageId,
            setPrevMessageId,
            setConversationIsNew,
            reader
          )
        );
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  
  const changeQuestion = (item, e) => {
    if (!disable) handleSubmit(e, item);
  };

  if (!visible) return <></>;

  return (
    <div className="st_wrapper-container">
      <div className="smart_tutor-container">
        <div className="chat-box">
          <div className="chat-message-box">
            <ChatHeader setShowContent={setShowContent} />
            <EmptyChat
              emptyChat={responses.length === 0}
              showContent={showContent}
            />
            <div
              className="chat-content-container"
              id="chat-content-container-id"
            >
              {responses.map((mObj) => (
                <MessageBox messageObject={mObj} getInitials={getInitials} />
              ))}
              {responses.length !== 0 && (
                <div className="regenerate-button-holder">
                  <div
                    className={`regenerate-button mb-6 ${disable ? "cursor-n" : "cursor-p"}`}
                    onClick={() => {
                      !disable && handleSubmit(null, null, 'variant');
                    }}
                  >
                    <span>
                      <ReloadOutlined />
                    </span>
                    Regenerate Response
                  </div>
                </div>
              )}
            </div>
            {loadFail ?
              <div className="spin-container">
                <Spin size="large" />
              </div> : (
                <ShowQuestions
                  changeQuestion={changeQuestion}
                  questionEmpty={questionEmpty}
                  disable={disable}
                  questions={
                    flattenedQuestions ? flattenedQuestions[selectedTaskIndex] : []
                  }
                  selectedTaskIndex={selectedTaskIndex}
                  contentType={contentType}
                />
              )}
            <ChatTextInput
              disable={disable}
              handleSubmit={handleSubmit}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartTutor;
