import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { Col, Divider, Row, Spin } from "antd";
import Banner from "component/Banner/Banner";
import Breadcrumb from "component/Breadcrumb/Breadcrumb";
import ComprehensiveStats from "component/ComprehensiveStats/ComprehensiveStats";
import CourseCard from "component/CourseCard/CourseCard";
import EnrolledCourseCard from "component/EnrolledCourseCard/EnrolledCourseCard";
import RCourseCard from "component/RCourseCard/RCourseCard";
import { ICourseType, IUserCourseType } from "lib/Types";
import React, { useEffect, useRef, useState } from "react";
import { useLazyQueryData } from "services";
import {
  GET_ALL_COURSES,
  GET_COURSES_FOR_LOGGEDIN_USER,
} from "services/Queries";
import "./dashboard.scss";

const Dashboard: React.FC = () => {
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [items, setItems] = useState<ICourseType[]>([]);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(items?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleItems = items?.slice(startIndex, endIndex);
  const [userCourses, setUserCourses] = useState<IUserCourseType[]>([]);
  const userId = localStorage.getItem("userId");
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  // Update the windowWidth state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 940) {
      setItemsPerPage(1);
    } else if (windowWidth < 1407) {
      setItemsPerPage(2);
    } else if (windowWidth < 1700) {
      setItemsPerPage(3);
    } else {
      setItemsPerPage(4);
    }
  }, [windowWidth]);

  const breadcrumbItems = [
    {
      label: "Dashboard",
      className: "breadcrumb-child",
    },
  ];

  const handleScroll = (scrollDirection: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.offsetWidth * itemsPerPage;

      if (scrollDirection === "left" && currentPage > 0) {
        setCurrentPage((prev) => prev - 1);
      } else if (scrollDirection === "right" && currentPage < totalPages - 1) {
        setCurrentPage((prev) => prev + 1);
      }

      const scrollPosition = currentPage * scrollAmount;
      scrollRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  //fetch all courses
  const [
    fetchAllCourses,
    { data: courseDetailsResp, loading: courseDetailsLoading },
  ] = useLazyQueryData(GET_ALL_COURSES);

  //fetch all courses for logged in user
  const [
    fetchAllCoursesForUser,
    { data: userCourseResp, loading: userCourseLoading },
  ] = useLazyQueryData(GET_COURSES_FOR_LOGGEDIN_USER, {
    userId: userId,
  });

  useEffect(() => {
    if (userCourseResp) {
      setUserCourses(userCourseResp.coursesForLoggedInUser);
    }
  }, [userCourseResp]);

  useEffect(() => {
    if (courseDetailsResp) {
      setItems(courseDetailsResp.getPublishedCourses);
    }
  }, [courseDetailsResp]);

  useEffect(() => {
    fetchAllCoursesForUser();
    fetchAllCourses();
  }, []);

  return (
    <div className="dashboard-container">
      <Row className="mb-24">
        <Col>
          <Breadcrumb items={breadcrumbItems} />
          <Divider className="divider m-0 mt-12" />
        </Col>
      </Row>

      {userCourseLoading || courseDetailsLoading ? (
        <div className="dash-loader">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {" "}
          {userCourses?.length === 0 &&
            !userCourseLoading &&
            items?.length !== 0 && (
              <>
                <Row className=" dcontainer">
                  {" "}
                  <Banner />
                </Row>

                <Row className="heading dash-section-header">
                  <h2 className="dash-title">Top picks for you</h2>
                </Row>
                <Row className="">
                  <RCourseCard course={items && items[0]} />
                </Row>
              </>
            )}
          {userCourses?.length !== 0 && (
            <>
              <Row className="heading">
                <h2 className="dash-title">Enrolled Courses</h2>
              </Row>
              <Row className=" dcontainer">
                {userCourses && <EnrolledCourseCard {...userCourses[0]} />}
              </Row>
            </>
          )}
          <Row className="flex flex--space-between heading dash-section-header">
            <h2 className="dash-title">Explore other courses</h2>
          </Row>
          <div className="flex">
            <button
              className="scroll-btn"
              onClick={() => handleScroll("left")}
              style={{ display: currentPage === 0 ? "none" : "block" }}
            >
              <LeftCircleFilled className="scroll-btn-icon" />
            </button>
            <Row ref={scrollRef} className="list">
              {visibleItems.map((item, index) => (
                <>
                  <CourseCard course={item} />
                </>
              ))}
            </Row>
            <button
              className="scroll-btn"
              onClick={() => handleScroll("right")}
              style={{
                display: currentPage === totalPages - 1 ? "none" : "block",
              }}
            >
              <RightCircleFilled className="scroll-btn-icon" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default Dashboard;
