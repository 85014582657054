import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import "./SectionDetails.scss";
import { v4 as uuidv4 } from "uuid";
import SectionTile from "component/SectionTile/SectionTile";
import { icons } from "assets";
import { SUMIT_PREVIEW } from "services/Mutation";
import { useLazyQueryData, useMutationData } from "services";
import { GET_MODULE_DETAILS } from "services/Queries";

const { Text } = Typography;

const SectionDetails = ({
  title,
  sectionDetails,
  updateSection,
  setSectionClicked,
  handleBreadcrumbItemClick,
  activeIndex,
  courseId
}) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("CREATE");
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [deleteSectionModalIsOpen, setDeleteSectionModalIsOpen] = useState(false);
  const [clickedSectionIndex, setClickedSectionIndex] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [moduleNumber, setModuleNumber] = useState(1);

  useEffect(() => {
    const lastModuleNumber = (sectionDetails?.[sectionDetails.length - 1]?.moduleNumber) || 0;
    const newModuleNumber = lastModuleNumber + 1;
    setModuleNumber(newModuleNumber);
  }, [sectionDetails]);

  // ====Mutations====
  const [detailsRelData, { loading: detailsRelLoading }] = useMutationData(SUMIT_PREVIEW);

  // ====Queries====
  const [
    getModuleDetails,
    { data: moduleDetailsResp, loading: moduleDetailsLoading },
  ] = useLazyQueryData(GET_MODULE_DETAILS, {
    courseId: courseId,
  });

  const handleDeleteSection = (index) => {
    setButtonClicked(true);
    const updatedArray = sectionDetails.filter((_, i) => i !== index);
    updateSection(updatedArray);
  };

  useEffect(() => {
    if (moduleDetailsResp && moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.length > 0) {
      const responseData = moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.map((module) => {
        const m_id = module.module.id;
        const moduleId = module.module.moduleId;
        const title = module.module.moduleName;
        const moduleNumber = module.module.moduleNumber;
        const moduleType = module.module.moduleType;
        const lessons = module.moduleMapper.filter(item => item.lesson !== null).map((item) => {
          const l_id = item.lesson.id;
          const lessonId = item.lesson.lessonId;
          const lessonName = item.lesson.lessonName;
          const fileType = item.lesson.contentType;
          const fileName = item.lesson.contentLink;
          const orderNumber = item.orderNumber;
          const summary = item.lesson.summary;
          const suggestiveQuestions = item.lesson.suggestiveQuestions;

          return {
            l_id,
            newEntry: false,
            lessonId,
            lessonName,
            fileType,
            fileName,
            orderNumber,
            summary,
            suggestiveQuestions
          }
        });

        const quizzes = module.moduleMapper.filter(item => item.quiz !== null).map((item) => {
          const id = item.quiz.id;
          const title = item.quiz.title;
          const description = item.quiz.description;
          const totalScore = item.quiz.totalScore;
          const duration = item.quiz.duration;
          const prerequisite = item.quiz.prerequisite;
          const orderNumber = item.orderNumber;

          return {
            id,
            title,
            description,
            totalScore,
            duration,
            prerequisite,
            orderNumber
          }
        });

        const assessments = module.moduleMapper.filter(item => item.miniAssessment !== null).map((item) => {
          const id = item.miniAssessment.id;
          const title = item.miniAssessment.title;
          const duration = item.miniAssessment.duration;
          const description = item.miniAssessment.description;
          const orderNumber = item.orderNumber;

          return {
            id,
            title,
            duration,
            description,
            orderNumber
          }
        });

        return {
          m_id,
          moduleId,
          newEntry: false,
          title,
          moduleNumber,
          moduleType,
          lessons,
          quizzes,
          assessments
        }
      });

      const lastModuleNumber = (responseData?.[responseData.length - 1]?.moduleNumber) || 0;
      const newModuleNumber = lastModuleNumber + 1;
      setModuleNumber(newModuleNumber);
      updateSection(responseData);
    }
  }, [moduleDetailsResp, setModuleNumber]);

  const handleAddSection = () => {
    setButtonClicked(true);

    form
      .validateFields()
      .then(() => {
        const errors = form.getFieldsError();
        const hasErrors = errors.some(
          (fieldError) => fieldError.errors.length !== 0
        );

        if (!hasErrors) {
          const formData = form.getFieldsValue();
          if (mode === "CREATE") {
            console.log("moduleNumber", moduleNumber);
            updateSection((prev) => [
              ...prev,
              { newEntry: true, moduleId: uuidv4(), moduleType: "NORMAL", title: formData.sectionTitle, moduleNumber, lessons: [], assessments: [], quizzes: [] },
            ]);
          } else {
            const newSection = sectionDetails.map((section, index) => {
              if (section.moduleId === selectedSectionId) {
                return {
                  ...section,
                  moduleId: section.moduleId,
                  title: formData.sectionTitle,
                  moduleNumber: index + 1,
                  lessons: [...section.lessons],
                };
              }
              return {
                ...section,
                moduleId: section.moduleId,
                moduleNumber: index + 1,
                title: section.title,
                lessons: [...section.lessons],
              };
            });
            updateSection(newSection);
            setMode("CREATE");
          }

          // Mutation to be added here (if any).
          form.resetFields();
        }
      })
      .catch((errorInfo) => { });
  };

  useEffect(() => {
    if (sectionDetails && buttonClicked) {
      detailsRelData({
        variables: {
          courseId,
          details: sectionDetails?.map(({ m_id, moduleId, newEntry, title, moduleNumber, lessons }) => ({
            id: newEntry === false ? m_id : undefined,
            moduleId: moduleId,
            newEntry: newEntry,
            moduleName: title,
            moduleNumber: moduleNumber,
            lessons: lessons.map(({ l_id, lessonId, newEntry, orderNumber, lessonName, fileType, fileName }) => ({
              id: newEntry === false ? l_id : undefined,
              lessonId: lessonId,
              newEntry: newEntry,
              orderNumber: orderNumber,
              lessonName,
              contentType: fileType,
              contentLink: fileName
            }))
          }))
        }
      }).then(() => {
        getModuleDetails();
      }).then(() => {
        setButtonClicked(false);
      });
    }
  }, [sectionDetails]);

  const handleClickedSection = (section: any) => {
    setSectionClicked(section);
    handleBreadcrumbItemClick(activeIndex + 1);
  };

  const onSectionDelete = (index) => {
    handleDeleteSection(index);
  };

  const onSectionClicked = (section) => {
    handleClickedSection(section);
  };
  const handleOnEditForm = (section, index) => {
    form.setFieldsValue({
      sectionTitle: section.title,
    });
    setMode("EDIT");
    setSelectedSectionId(section.moduleId);
  };

  return (
    <>
      <Row gutter={24} className="section-form-container">
        <Col className="section-form-header" span={24}>
          <Text className="header-5 capitalize section-form-header-title">
            {`${title}`}
          </Text>
          <Divider className="breadcrumb-divider m-0 mt-12" />
        </Col>

        {moduleDetailsLoading ? (
          <div className="loading-spinner">
            <Spin size="large" />
          </div>
        ) :
          sectionDetails.length !== 0 && <Col span={24} className="section-cards-container">
            {sectionDetails.map((section, index) => {
              return (
                <>
                  {(
                    <Col span={24} className="mb-14">
                      <Col span={24} className="section-tile-container">
                        <SectionTile
                          section={section}
                          index={index}
                          mode="CREATE"
                          handleDeleteSection={() => {
                            setClickedSectionIndex(index);
                            setDeleteSectionModalIsOpen(true);
                          }}
                          handleClickedSection={() => onSectionClicked(section)}
                          onEdit={() => handleOnEditForm(section, index)}
                        />
                      </Col>
                    </Col>
                  )}

                  {mode === "EDIT" && selectedSectionId === section.moduleId && (
                    <Col span={24} className="mb-14">
                      {/* <Col span={24}> */}
                        <Form form={form} id="sectionForm">
                          <Row
                            gutter={[40, 40]}
                            align="middle"
                            className="section-form-wrapper"
                          >
                            <Col span={24} className="add-section-tile">
                              <Col span={12} className="label mt-8 mb-8 section-form-label">
                                <span className="mandatory">* </span>Section Title
                              </Col>
                              <Col></Col>
                              <Form.Item
                                name="sectionTitle"
                                rules={[
                                  {
                                    required: true,
                                    message: `Please input Section Title`,
                                  },
                                ]}
                                className="mb-0"
                              >
                                <Input size="large" placeholder="Enter Section title" />
                              </Form.Item>

                              <Col
                                span={24}
                                className="mb-8 flex flex--space-between form-add-section-btn-col-p"
                              >
                                <Col></Col>
                                <Col className="form-add-section-btn-col">
                                  <Button
                                    className="form-add-section-button mr-8"
                                    onClick={handleAddSection}
                                  >
                                    Edit Section
                                  </Button>
                                </Col>
                              </Col>
                            </Col>
                          </Row>
                        </Form>
                      {/* </Col> */}
                    </Col>
                  )}
                </>


              );
            })}
          </Col>

        }

        {mode === "CREATE" && (
          <Col span={24} className="mb-8">
            <Col span={24}>
              <Form form={form} id="sectionForm">
                <Row
                  gutter={[40, 40]}
                  align="middle"
                  className="section-form-wrapper"
                >
                  <Col span={24} className="add-section-tile">
                    <Col span={12} className="label mt-8 mb-8 section-form-label">
                      <span className="mandatory">* </span>Section Title
                    </Col>
                    <Col></Col>
                    <Form.Item
                      name="sectionTitle"
                      rules={[
                        {
                          required: true,
                          message: `Please input Section Title`,
                        },
                      ]}
                      className="mb-0"
                    >
                      <Input size="large" placeholder="Enter Section title" />
                    </Form.Item>

                    <Col
                      span={24}
                      className="mb-8 flex flex--space-between form-add-section-btn-col-p"
                    >
                      <Col></Col>
                      <Col className="form-add-section-btn-col">
                        <Button
                          className="form-add-section-button mr-8"
                          onClick={handleAddSection}
                        >
                          Add Section
                        </Button>
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Col>
        )}
      </Row>

      <Modal
        title={
          <span style={{ display: 'flex' }}>
            <img src={icons.deleteIcon} alt="deleteIcon icon" style={{ marginRight: '10px' }} />
            <span>Are you sure you want to delete the section?</span>
          </span>
        }
        visible={deleteSectionModalIsOpen}
        onCancel={() => setDeleteSectionModalIsOpen(false)}
        footer={[
          <Button
            key="no"
            onClick={() => setDeleteSectionModalIsOpen(false)}
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              onSectionDelete(clickedSectionIndex);
              setDeleteSectionModalIsOpen(false);
            }}
          >
            Yes
          </Button>,
        ]}
      />
    </>
  );
};

export default SectionDetails;
