import { gql } from "@apollo/client";

export const CREATE_USER_COURSE_RELATION = gql`
  mutation ($courseId: ID!, $orgCognitoId: String!) {
    createUserCourseRelation(courseId: $courseId, orgCognitoId: $orgCognitoId) {
      ok
      message
    }
  }
`;

export const DELETE_USER_COURSE_RELATION = gql`
  mutation ($courseId: ID!) {
    deleteUserCourseRelation(courseId: $courseId) {
      ok
      message
    }
  }
`;

export const UPDATE_USER_LESSON_RELATION = gql`
  mutation ($lessonId: ID!, $courseId: ID!, $status: String!) {
    updateUserLessonRelation(
      lessonId: $lessonId
      courseId: $courseId
      status: $status
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_USER_COURSE_RELATION = gql`
  mutation (
    $courseId: ID!
    $userEmail: String!
    $updateId: String
    $updateType: String
    $bookmark: Int
  ) {
    updateUserCourseRelation(
      courseId: $courseId
      updateId: $updateId
      updateType: $updateType
      bookmark: $bookmark
      userEmail: $userEmail
    ) {
      ok
      message
    }
  }
`;

export const CREATE_USER_QUIZ_RELATION = gql`
  mutation ($courseId: ID!, $quizId: ID!, $userId: ID!) {
    createUserQuizRelation(
      courseId: $courseId
      quizId: $quizId
      userId: $userId
    ) {
      ok
      message
    }
  }
`;

export const SUBMIT_QUIZ = gql`
  mutation (
    $userId: ID!
    $quizId: ID!
    $courseId: ID!
    $questionAnswers: GenericScalar
  ) {
    submitQuiz(
      userId: $userId
      quizId: $quizId
      courseId: $courseId
      questionAnswers: $questionAnswers
    ) {
      ok
      message
    }
  }
`;

export const CREATE_UPDATE_COURSE = gql`
  mutation ($course: CourseInputType!, $userEmail: String!) {
    courseSaveDraft(
      courseInput: $course
      orgCognitoId: "9ebbef0e-6d07-4db8-8f0b-132ced05cf88"
      userEmail: $userEmail
    ) {
      ok
      message
      course {
        id
        courseName
        courseLink
        courseBanner
      }
    }
  }
`;

export const SUMIT_PREVIEW = gql`
  mutation ($courseId: Int!, $details: GenericScalar!) {
    createUpdateCourseDetails(courseId: $courseId, courseDetails: $details) {
      ok
      message
    }
  }
`;

export const GENERATE_PUT_URI = gql`
  mutation ($blobName: String!) {
    generateSasPutUri(blobName: $blobName) {
      ok
      url
      blobPath
    }
  }
`;

export const CREATE_UPDATE_QUIZ = gql`
  mutation (
    $courseId: ID!
    $moduleId: ID!
    $orderNumber: Int!
    $quizDetails: QuizInputType
  ) {
    createUpdateQuiz(
      courseId: $courseId
      moduleId: $moduleId
      orderNumber: $orderNumber
      quizDetails: $quizDetails
    ) {
      ok
      message
    }
  }
`;

export const CREATE_UPDATE_MINI_ASSESSMENT = gql`
  mutation (
    $miniAssessment: MiniAssessmentInputType
    $courseId: ID!
    $moduleId: ID!
    $orderNumber: Int
  ) {
    createUpdateMiniAssessement(
      courseId: $courseId
      moduleId: $moduleId
      orderNumber: $orderNumber
      miniAssessmentDetails: $miniAssessment
    ) {
      ok
      message
    }
  }
`;

export const CREATE_USER_ASSESSMENT_RELATION = gql`
  mutation ($assessmentId: ID!, $courseId: ID!, $userId: ID!) {
    createUserAssessmentRelation(
      assessmentId: $assessmentId
      courseId: $courseId
      userId: $userId
    ) {
      ok
      message
    }
  }
`;

export const SUBMIT_MINI_ASSESSMENT = gql`
  mutation (
    $codeSolution: String!
    $courseId: ID!
    $miniassessmentId: ID!
    $miniassessmentquestionId: ID!
    $userId: ID!
    $completed: Boolean
    $miniassessmentQuestionRelationId: ID!
  ) {
    submitMiniAssessment(
      codeSolution: $codeSolution
      courseId: $courseId
      miniassessmentId: $miniassessmentId
      miniassessmentquestionId: $miniassessmentquestionId
      userId: $userId
      completed: $completed
      miniassessmentQuestionRelationId: $miniassessmentQuestionRelationId
    ) {
      ok
      message
      miniassessmentQuestionRelationId
    }
  }
`;

export const DELETE_MINI_ASSESSMENT = gql`
  mutation ($courseId: ID!, $miniAssessmentId: ID!) {
    deleteMiniAssessment(
      courseId: $courseId
      miniAssessmentId: $miniAssessmentId
    ) {
      ok
      message
    }
  }
`;

export const DELETE_QUIZ = gql`
  mutation ($courseId: Int!, $quizId: ID!) {
    deleteQuiz(courseId: $courseId, quizId: $quizId) {
      ok
      message
    }
  }
`;

export const CREATE_CERTIFICATE = gql`
  mutation ($courseId: Int!, $traineeId: Int!) {
    createCertificate(courseId: $courseId, traineeId: $traineeId) {
      ok
      objectName
    }
  }
`;
export const SAVE_QUIZ = gql`
  mutation (
    $questionAnswers: GenericScalar!
    $courseId: ID!
    $quizId: ID!
    $userId: ID!
  ) {
    saveQuizResponse(
      courseId: $courseId
      questionAnswers: $questionAnswers
      quizId: $quizId
      userId: $userId
    ) {
      ok
      message
    }
  }
`;
export const CREATE_SAVE_ASSESSMENT = gql`
  mutation (
    $codeSolution: String!
    $courseId: ID!
    $miniassessmentId: ID!
    $miniassessmentquestionId: ID!
    $userId: ID!
  ) {
    createMiniAssessmentQuestionRelation(
      codeSolution: $codeSolution
      courseId: $courseId
      miniassessmentId: $miniassessmentId
      miniassessmentquestionId: $miniassessmentquestionId
      userId: $userId
    ) {
      ok
      message
      miniassessmentQuestionRelationId
    }
  }
`;
export const UPDATE_SAVE_ASSESSMENT = gql`
  mutation ($codeSolution: String!, $miniassessmentQuestionRelationId: ID!) {
    updateMiniAssessmentQuestionRelation(
      codeSolution: $codeSolution
      miniassessmentQuestionRelationId: $miniassessmentQuestionRelationId
    ) {
      ok
      message
    }
  }
`;

export const DELETE_MINI_ASSESSMENT_QUESTION = gql`
  mutation ($miniAssessmentQuestionId: ID!) {
    deleteMiniAssessmentQuestion(
      miniAssessmentQuestionId: $miniAssessmentQuestionId
    ) {
      ok
      message
    }
  }
`;


export const GENERATE_SUMMARY_SUGGESTIVE_QUESTIONS = gql`
  mutation($lessonId: Int!, $courseId: Int!, $numOfSuggestiveQuestions: Int!, $generateSummary: Int!) {
     GenerateSummarySuggestiveQuestions(
      lessonId: $lessonId,  
      courseId: $courseId,  
      numOfSuggestiveQuestions: $numOfSuggestiveQuestions, 
      generateSummary: $generateSummary  
    ) {
      ok
      message
      questionGenerationTrackingId
      summaryGenerationTrackingId
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation ($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      ok
      message
    }
  }
`;

