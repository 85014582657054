import React from "react";
import { Col, Row } from "antd";
import "./Banner.scss";
import { icons } from "assets";

const Banner: React.FC = () => {
  return (
    <Row className="banner-container">
      <Row className="banner-title-container">
        <Col className="banner-title">Welcome to Super Tutor</Col>
        <Col className="banner-text">
          A hyper-personalized learning assistant that integrates with existing
          Learning Management platforms and aims at maximising the learning
          experience for any individual.
        </Col>
      </Row>

      <img className="banner-image" src={icons.superTutor} alt="" />
    </Row>
  );
};
export default Banner;
