import { useParams } from "react-router-dom";
import { useLazyQueryData } from "services";
import { GET_QUIZ_ANSWERS, GET_QUIZ_DETAILS } from "services/Queries";
import { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Progress, Radio, Row, Spin } from "antd";
import { useMutation } from "@apollo/client";
import { SAVE_QUIZ, SUBMIT_QUIZ } from "services/Mutation";
import "./Quiz.scss";

import { Modal } from "antd";

const Quiz = ({
  quizId,
  quizDetails,
  quizAnswerDetails,
  setQuizDetails,
  setQuizAnswerDetails,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  progress,
  setProgress,
  userAnswers,
  setUserAnswers,
  quizSavedDetails,
  setQuizSavedDetails,
  quizLoading,
  setQuizLoading,
}): JSX.Element => {
  const { id: courseId } = useParams();

  const [confirmationModalIsOpen, setconfirmationModalIsOpen] = useState(false);

  const [answerLength, setAnswerLength] = useState(0);

  const [getQuizDetails, { data: quizDetailsResp }] = useLazyQueryData(
    GET_QUIZ_DETAILS,
    {
      quizId,
      userId: localStorage.getItem("userId"),
      courseId,
    }
  );

  //quiz Results Query
  const [getQuizAnswerDetails, { data: quizAnswerDetailsResp }] =
    useLazyQueryData(GET_QUIZ_ANSWERS, {
      quizId,
      userId: localStorage.getItem("userId"),
      courseId,
    });

  const [getQuizSavedDetails, { data: quizSavedDetailsResp }] =
    useLazyQueryData(GET_QUIZ_ANSWERS, {
      quizId,
      userId: localStorage.getItem("userId"),
      courseId,
    });

  // =====Mutation=====
  const [submitQuizData] = useMutation(SUBMIT_QUIZ);
  const [saveQuizData] = useMutation(SAVE_QUIZ);

  useEffect(() => {
    getQuizDetails();
  }, [getQuizDetails]);

  useEffect(() => {
    setQuizDetails(quizDetailsResp?.userQuizDetails?.quizDetails);
    getQuizSavedDetails();

    if (
      quizDetailsResp?.userQuizDetails?.userQuizRelation?.status === "COMPLETED"
    ) {
      getQuizAnswerDetails();
    }
    setQuizLoading(false);
  }, [quizDetailsResp]);

  useEffect(() => {
    setQuizAnswerDetails(
      quizAnswerDetailsResp?.fetchUserQuizChoice?.userQuestionChoice
    );
    setCurrentQuestionIndex(0);
    setQuizLoading(false);
  }, [quizAnswerDetailsResp]);

  useEffect(() => {
    setQuizSavedDetails(
      quizSavedDetailsResp?.fetchUserQuizChoice?.userQuestionChoice
    );

    let tempAnswers = new Array(quizDetails?.questionSet?.length).fill("");
    for (let i = 0; i < quizDetails?.questionSet?.length; i++) {
      // tempAnswers[i] =
      for (
        let j = 0;
        j <
        quizSavedDetailsResp?.fetchUserQuizChoice?.userQuestionChoice?.length;
        j++
      ) {
        if (
          quizDetails?.questionSet?.[i]?.id ===
          quizSavedDetailsResp?.fetchUserQuizChoice?.userQuestionChoice?.[j]
            ?.question.id
        ) {
          tempAnswers[i] = [
            quizSavedDetailsResp?.fetchUserQuizChoice?.userQuestionChoice?.[j]
              ?.choice.id,
          ];
        }
      }
    }

    setUserAnswers(tempAnswers);
  }, [quizSavedDetailsResp]);

  const handleNextQuestion = () => {
    if (answerLength < quizDetails?.questionSet?.length) {
      setconfirmationModalIsOpen(true);
    } else {
      handleSubmitQuiz();
    }
  };

  //update user Answers to backend
  const saveUserAnswers = (answers) => {
    const questionAnswers = quizDetails?.questionSet?.map(
      (question, index) => ({
        questionId: parseInt(question?.id, 10),
        answer: answers[index] || [],
      })
    );

    saveQuizData({
      variables: {
        userId: localStorage.getItem("userId"),
        quizId,
        courseId,
        questionAnswers: questionAnswers,
      },
    });
  };

  const handleAnswerSelect = (choiceIds: any) => {
    const updatedUserAnswers = [...userAnswers];
    if (!userAnswers[currentQuestionIndex]) {
      setAnswerLength(answerLength + 1);
    }
    console.log("user Answers", updatedUserAnswers);

    updatedUserAnswers[currentQuestionIndex] = Array.isArray(choiceIds)
      ? choiceIds
      : [choiceIds];
    const selectedAnswersInteger = updatedUserAnswers?.map((answers: any) =>
      answers !== "" ? answers?.map((answer: any) => parseInt(answer, 10)) : ""
    );
    setUserAnswers(selectedAnswersInteger);

    saveUserAnswers(selectedAnswersInteger);
  };

  const findIncorrectAnswers = (id) => {
    for (let i = 0; i < quizAnswerDetails.length; i++) {
      if (id === quizAnswerDetails[i].choice.id) {
        for (let j = 0; j < quizDetails.questionSet.length; j++) {
          if (
            quizDetails.questionSet[j].id === quizAnswerDetails[i].question.id
          ) {
            for (
              let k = 0;
              k < quizDetails.questionSet[j].choiceSet.length;
              k++
            ) {
              if (
                quizDetails.questionSet[j].choiceSet[k].id === id &&
                !quizDetails.questionSet[j].choiceSet[k].isRightChoice
              ) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };

  const returnUserAnswers = (index) => {
    for (let j = 0; j < quizAnswerDetails?.length; j++) {
      if (
        quizDetails.questionSet[index].id === quizAnswerDetails[j].question.id
      ) {
        for (
          let k = 0;
          k < quizDetails.questionSet[index].choiceSet.length;
          k++
        ) {
          if (
            quizDetails.questionSet[index].choiceSet[k].id ===
            quizAnswerDetails[j].choice.id
          ) {
            return [quizAnswerDetails[j].choice.id];
          }
        }
      }
    }
    return undefined;
  };

  const returnSavedAnswers = (index) => {
    for (let j = 0; j < quizSavedDetails?.length; j++) {
      if (
        quizDetails.questionSet[index].id === quizSavedDetails[j].question.id
      ) {
        for (
          let k = 0;
          k < quizDetails.questionSet[index].choiceSet.length;
          k++
        ) {
          if (
            quizDetails.questionSet[index].choiceSet[k].id ===
            quizSavedDetails[j].choice.id
          ) {
            return [quizSavedDetails[j].choice.id];
          }
        }
      }
    }
    return undefined;
  };

  const handleSubmitQuiz = () => {
    const questionAnswers = quizDetails?.questionSet?.map(
      (question, index) => ({
        questionId: parseInt(question?.id, 10),
        answer: userAnswers[index] || [],
      })
    );

    submitQuizData({
      variables: {
        userId: localStorage.getItem("userId"),
        quizId,
        courseId,
        questionAnswers: questionAnswers,
      },
    }).then((response) => {
      if (response.data) {
        setQuizLoading(true);
        getQuizAnswerDetails();
        setconfirmationModalIsOpen(false);
        setProgress(0);
      }
    });
  };

  return (
    <Row className="quiz-container">
      {quizLoading || !quizDetails ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row className="flex flex--space-between quiz-main-section-wrapper width-100">
            <Col className="width-100">
              <Card className="quiz-main-section">
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Progress
                      showInfo={false}
                      percent={progress}
                      strokeColor="#357bac"
                      status={
                        currentQuestionIndex ===
                        quizDetails?.questionSet?.length - 1
                          ? "success"
                          : "active"
                      }
                    />
                  </Col>
                </Row>

                {quizDetails &&
                  quizDetails?.questionSet[currentQuestionIndex]?.title && (
                    <>
                      <Row
                        className="quiz-question-section"
                        style={{ justifyContent: "normal" }}
                      >
                        <Col span={1}>
                          <span className="item-2">{`${
                            currentQuestionIndex + 1
                          }.`}</span>
                        </Col>

                        <Col
                          span={18}
                          className="quiz-question-section-question"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                quizDetails?.questionSet?.[currentQuestionIndex]
                                  ?.title,
                            }}
                            className="question-content"
                          ></span>
                        </Col>

                        <Col span={24}>
                          <div className="mark-content">{`${
                            quizDetails?.questionSet?.[currentQuestionIndex]
                              ?.score
                          } ${
                            quizDetails?.questionSet?.[currentQuestionIndex]
                              ?.score === 1
                              ? "Point"
                              : "Points"
                          }`}</div>
                        </Col>
                      </Row>

                      {!quizAnswerDetails && (
                        <Row>
                          {quizDetails?.questionSet[currentQuestionIndex]
                            ?.type === "MULTIPLE_CHOICE" ? (
                            <Checkbox.Group
                              className="width-100"
                              onChange={(values) => handleAnswerSelect(values)}
                              value={
                                userAnswers[currentQuestionIndex]
                                  ? userAnswers[currentQuestionIndex]?.map(
                                      (answer) => answer.toString()
                                    )
                                  : []
                              }
                            >
                              {quizDetails?.questionSet[
                                currentQuestionIndex
                              ]?.choiceSet.map((choice: any) => (
                                <Col span={24} className="checkbox-item">
                                  <Checkbox key={choice?.id} value={choice?.id}>
                                    {choice?.choice}
                                  </Checkbox>
                                </Col>
                              ))}
                            </Checkbox.Group>
                          ) : (
                            <Radio.Group
                              onChange={(e) =>
                                handleAnswerSelect(e.target.value)
                              }
                              value={
                                userAnswers[currentQuestionIndex]
                                  ? userAnswers[
                                      currentQuestionIndex
                                    ]?.[0]?.toString()
                                  : returnSavedAnswers(
                                      currentQuestionIndex
                                    )?.[0]?.toString()
                              }
                              className="width-100"
                            >
                              {quizDetails?.questionSet[
                                currentQuestionIndex
                              ]?.choiceSet.map((choice: any) => (
                                <Col span={24} className="radio-item">
                                  <Radio key={choice?.id} value={choice?.id}>
                                    {choice?.choice}
                                  </Radio>
                                </Col>
                              ))}
                            </Radio.Group>
                          )}
                        </Row>
                      )}

                      {quizAnswerDetails && (
                        <Row>
                          {quizDetails?.questionSet[currentQuestionIndex]
                            ?.type === "MULTIPLE_CHOICE" ? (
                            <Checkbox.Group
                              className="width-100"
                              onChange={(values) => handleAnswerSelect(values)}
                              disabled={true}
                              value={
                                userAnswers[currentQuestionIndex]
                                  ? userAnswers[currentQuestionIndex]?.map(
                                      (answer) => answer.toString()
                                    )
                                  : []
                              }
                            >
                              {quizDetails?.questionSet[
                                currentQuestionIndex
                              ]?.choiceSet.map((choice: any) => (
                                <Col
                                  span={24}
                                  className={
                                    choice?.isRightChoice
                                      ? "checkbox-item-correct"
                                      : findIncorrectAnswers(choice?.id)
                                      ? "checkbox-item-incorrect"
                                      : "checkbox-item"
                                  }
                                >
                                  <Checkbox key={choice?.id} value={choice?.id}>
                                    {choice?.choice}
                                  </Checkbox>
                                </Col>
                              ))}
                            </Checkbox.Group>
                          ) : (
                            <Radio.Group
                              onChange={(e) =>
                                handleAnswerSelect(e.target.value)
                              }
                              value={returnUserAnswers(
                                currentQuestionIndex
                              )?.[0]?.toString()}
                              className="width-100"
                              disabled={true}
                            >
                              {quizDetails?.questionSet[
                                currentQuestionIndex
                              ]?.choiceSet.map((choice: any) => (
                                <Col
                                  span={24}
                                  className={
                                    choice?.isRightChoice
                                      ? "radio-item-correct"
                                      : findIncorrectAnswers(choice?.id)
                                      ? "radio-item-incorrect"
                                      : "radio-item"
                                  }
                                >
                                  <Radio key={choice?.id} value={choice?.id}>
                                    {choice?.choice}
                                  </Radio>
                                </Col>
                              ))}
                            </Radio.Group>
                          )}
                        </Row>
                      )}
                    </>
                  )}

                <Row className="flex flex--space-between">
                  {quizAnswerDetails && (
                    <Col span={18} className="quiz-explanation-text">
                      <strong>Explanation:</strong>{" "}
                      {
                        quizDetails?.questionSet[currentQuestionIndex]
                          ?.choiceSet[0].explanation
                      }
                    </Col>
                  )}
                  <Col></Col>
                  <Col>
                    {!quizAnswerDetails &&
                      currentQuestionIndex ===
                        quizDetails?.questionSet?.length - 1 && (
                        <Button
                          onClick={handleNextQuestion}
                          className="quiz-next-button"
                          disabled={
                            currentQuestionIndex ===
                              quizDetails?.questionSet?.length - 1 &&
                            quizAnswerDetails
                              ? true
                              : false
                          }
                        >
                          Submit
                        </Button>
                      )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Modal
            title="Are you sure you want to submit the quiz?"
            visible={confirmationModalIsOpen}
            onCancel={() => setconfirmationModalIsOpen(false)}
            footer={[
              <Button
                key="No"
                onClick={() => setconfirmationModalIsOpen(false)}
              >
                No
              </Button>,
              <Button key="Yes" type="primary" onClick={handleSubmitQuiz}>
                Yes
              </Button>,
            ]}
          ></Modal>
        </>
      )}
    </Row>
  );
};

export default Quiz;
