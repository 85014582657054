import React from "react";

const MyLearning = ({ active = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2223_15454)">
        <path
          d="M22.922 1.7C22.5852 1.41773 22.1903 1.21329 21.7654 1.10128C21.3405 0.989266 20.8961 0.97244 20.464 1.052L14.284 2.175C13.3997 2.33446 12.5949 2.78755 12 3.461C11.4055 2.78644 10.6007 2.33226 9.716 2.172L3.536 1.049C3.10377 0.970515 2.65957 0.987911 2.2348 1.09996C1.81004 1.21201 1.41506 1.41598 1.07778 1.69746C0.740505 1.97893 0.469162 2.33105 0.282926 2.72892C0.0966906 3.12679 0.000106745 3.5607 0 4L0 20.834L12 23.017L24 20.834V4C24 3.56103 23.9035 3.12741 23.7172 2.72993C23.5309 2.33244 23.2594 1.98082 22.922 1.7ZM11 20.8L2 19.166V4C2.00012 3.85359 2.0324 3.709 2.09454 3.57643C2.15668 3.44386 2.24717 3.32655 2.35962 3.23279C2.47208 3.13903 2.60374 3.07111 2.74533 3.03383C2.88691 2.99654 3.03495 2.9908 3.179 3.017L9.358 4.14C9.81882 4.22385 10.2356 4.46677 10.5357 4.82641C10.8357 5.18606 11.0001 5.63962 11 6.108V20.8ZM22 19.164L13 20.8V6.108C12.9999 5.63962 13.1643 5.18606 13.4643 4.82641C13.7644 4.46677 14.1812 4.22385 14.642 4.14L20.821 3.017C20.965 2.9908 21.1131 2.99654 21.2547 3.03383C21.3963 3.07111 21.5279 3.13903 21.6404 3.23279C21.7528 3.32655 21.8433 3.44386 21.9055 3.57643C21.9676 3.709 21.9999 3.85359 22 4V19.164Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2223_15454">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MyLearning;
