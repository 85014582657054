import {
  Col,
  Form,
  Row,
  Input,
  Select,
  Button,
  Radio,
  Spin,
  message,
  Modal,
} from "antd";
import "./GenerateQuiz.scss";
import TextArea from "antd/es/input/TextArea";
import { icons } from "assets";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useLazyQueryData, useMutationData } from "services";

import {
  ACKNOWLEDGE_TASK,
  COURSE_DETAILS,
  GENERATE_ALL_QUIZ_QUESTIONS,
  GENERATE_MCQ_QUESTIONS,
  GET_MODULE_DETAILS,
  GET_TASK_STATUS_QUIZ,
  PENDING_TASKS,
} from "services/Queries";

import { CREATE_UPDATE_QUIZ, DELETE_QUIZ } from "services/Mutation";
import { TASK_STATUS } from "component/lib/Constants";
import QuizTile from "component/QuizTile/QuizTile";
import { getLoginUserEmail } from "lib/Helper";
import { Console } from "console";

const typeOfQuestions = ["SINGLE_CHOICE", "MULTIPLE_CHOICE"];
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const GenerateQuiz = ({
  sectionDetails,
  sectionClicked,
  updateSection,
  courseId,
  fmode = "REGULAR",
}) => {
  const [quizForm] = Form.useForm();
  const [questionForm] = Form.useForm();
  const [quizDetailsForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [quizData, setQuizData] = useState<any>([]);
  const [quizDetails, setQuizDetails] = useState<any>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [taskTrackingId, setTaskTrackingId] = useState<any>(null);
  const [quiz_id, setQuiz_id] = useState(null);
  const [orderNumber, setOrderNumber] = useState(0);
  const [responseData, setResponseData] = useState<any>(null);
  const { Option } = Select;
  const [deleteQuizModalIsOpen, setDeleteQuizModalIsOpen] = useState(false);
  const [clickedQuizIndex, setClickedQuizIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [insideHandleEdit, setInsideHandleEdit] = useState(false);
  const [isSaveClickedMultiple, setIsSaveClickedMultiple] = useState(0);
  const [courseTitle, setCourseTitle] = useState("");
  const userEmail = getLoginUserEmail();
  const [quizGenMode, setQuizGenMode] = useState("");

  // ===Queries===
  const [generateQuiz] = useLazyQueryData(GENERATE_MCQ_QUESTIONS);
  const [getTaskStatus] = useLazyQueryData(GET_TASK_STATUS_QUIZ);
  const [getAllQuiz] = useLazyQueryData(GENERATE_ALL_QUIZ_QUESTIONS);
  const [getPendingTasks] = useLazyQueryData(PENDING_TASKS);
  const [acknowledgeTask] = useLazyQueryData(ACKNOWLEDGE_TASK);
  const [
    getModuleDetails,
    { data: moduleDetailsResp, loading: moduleDetailsLoading },
  ] = useLazyQueryData(GET_MODULE_DETAILS, {
    courseId: courseId,
  });

  const handleModeSelect = (mode: any) => {
    setQuizGenMode(mode);
  };

  const [
    getCourseDetails,
    { data: courseDetailsResp, loading: courseDetailsLoading },
  ] = useLazyQueryData(COURSE_DETAILS, {
    courseId: courseId,
    userEmail: userEmail,
  });

  const MODE = ["MANUAL", "GEN_AI"];

  // ===Mutations===
  const [createUpdateQuizRelData] = useMutationData(CREATE_UPDATE_QUIZ);
  const [deleteQuizRelData] = useMutationData(DELETE_QUIZ);

  const [defaultSkills, setDefaultSkills] = useState<any>();
  const [skills, setSkills] = useState<any>([]);

  const updateQuestionFormFields = (questionIndex) => {
    const updatedData = [...quizData];
    const currentQuestion = updatedData[questionIndex];

    const updatedObj = {
      score: currentQuestion?.score,
      answer: currentQuestion?.answer,
      type: currentQuestion?.type,
      title: currentQuestion?.title,
      explanation: currentQuestion?.explanation,
      option_1: currentQuestion?.option_1,
      option_2: currentQuestion?.option_2,
      option_3: currentQuestion?.option_3,
      option_4: currentQuestion?.option_4,
    };

    questionForm.setFieldsValue(updatedObj);

    setSkills([
      currentQuestion?.option_1,
      currentQuestion?.option_2,
      currentQuestion?.option_3,
      currentQuestion?.option_4,
    ]);
  };

  const updateQuizFormFields = () => {
    if (quizDetails) {
      const obj = {
        title: quizDetails.title,
        duration: quizDetails.duration,
        descritpion: quizDetails.description,
        totalScore: quizDetails.totalScore,
      };
      quizDetailsForm.setFieldsValue(obj);
      quizDetailsForm.setFieldValue("description", quizDetails.description);
    }
  };

  const handleGenerateQuizClick = async () => {
    try {
      setIsEdit(false);
      setIsSaveClickedMultiple(0);
      await quizForm.validateFields();
      const errors = quizForm.getFieldsError();
      const hasErrors = errors.some(
        (fieldError) => fieldError.errors.length !== 0
      );

      if (!hasErrors) {
        const formData = quizForm.getFieldsValue();
        setCurrentQuestionIndex(0);
        setIsLoading(true);
        message.info("Quiz data generation started");

        const generateQuizResult = await generateQuiz({
          variables: {
            lessonIds: formData?.lessons,
            courseId: courseId,
            easy: formData?.easy,
            medium: formData?.medium,
            hard: formData?.hard,
            questionType: formData?.questionType,
            moduleId: sectionClicked?.m_id,
          },
        });

        const { taskTrackingId, success } = generateQuizResult?.data?.generateMcqQuestions ?? {};

        if (!success) {
          setIsLoading(false);
          message.error("Something went wrong. Please try again.");
          return;
        }

        setTaskTrackingId(taskTrackingId);
        let status = TASK_STATUS.DATA_GENERATION_IN_PROGRESS;
        let taskConfig = "";
        message.loading("Quiz data generation is in progress.");

        while (
          status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS ||
          status === TASK_STATUS.DATA_LOAD_IN_PROGRESS ||
          status === TASK_STATUS.DATA_PROGRESS
        ) {
          const statusResult = await getTaskStatus({
            variables: { taskTrackingId: [taskTrackingId] },
          });

          status = statusResult?.data?.getTaskStatus[0]?.taskStatus;
          taskConfig =
            statusResult?.data?.getTaskStatus[0]?.taskTrackingDetails
              ?.taskConfig;

          if (
            status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS ||
            status === TASK_STATUS.DATA_LOAD_IN_PROGRESS ||
            status === TASK_STATUS.DATA_PROGRESS
          ) {
            await new Promise((resolve) => setTimeout(resolve, 10000));
          }
        }

        if (status === "FAILED") {
          setIsLoading(false);
          message.error("Something went wrong. Please try again.");
          return;
        }

        const responseObj = JSON.parse(taskConfig);
        const quizId = responseObj?.quiz_id;

        if (quizId === null) {
          setIsLoading(false);
          message.error("Something went wrong. Please try again.");
          return;
        }

        setQuiz_id(quizId);

        const quizResult = await getAllQuiz({
          variables: {
            courseId: courseId,
            userId: localStorage.getItem("userId"),
            quizId: quizId,
          },
        });

        acknowledgeTask({
          variables: {
            taskTrackingId: taskTrackingId,
          },
        });

        if (
          quizResult?.data?.allQuizzes === null ||
          quizResult?.data?.allQuizzes?.length === 0
        ) {
          setIsLoading(false);
          message.error("Something went wrong. Please try again.");
          return;
        }

        setResponseData(quizResult?.data?.allQuizzes);
        setQuizData(
          quizResult?.data?.allQuizzes.map((obj, index) => {
            // To be replaced.
            return {
              score: obj?.score,
              answer: obj?.choiceSet?.find((item) => item.isRightChoice)
                ?.choice,
              type: obj?.type,
              title: obj?.title,
              explanation: obj?.choiceSet?.find((item) => item.isRightChoice)
                ?.explanation,
              choiceSet: obj?.choiceSet,
              level: obj?.level,
              option_1: obj?.choiceSet?.[0]?.choice,
              option_2: obj?.choiceSet?.[1]?.choice,
              option_3: obj?.choiceSet?.[2]?.choice,
              option_4: obj?.choiceSet?.[3]?.choice,
              correctIndex: obj?.choiceSet?.findIndex(
                (item) => item.isRightChoice
              ),
            };
          })
        );
        setIsLoading(false);
        message.success("Quiz data generated successfully");
        setIsFormEditable(true);
        setQuizDetails({
          duration: quizResult?.data?.allQuizzes[0]?.quiz?.duration,
          totalScore: quizResult?.data?.allQuizzes[0]?.quiz?.totalScore,
          title: quizResult?.data?.allQuizzes[0]?.quiz?.title,
          description: quizResult?.data?.allQuizzes[0]?.quiz?.description,
        });
        setQuizGenMode("MANUAL");
        // updateQuizFormFields();
        quizDetailsForm.setFieldsValue({
          duration: quizResult?.data?.allQuizzes[0]?.quiz?.duration,
          totalScore: quizResult?.data?.allQuizzes[0]?.quiz?.totalScore,
          title: quizResult?.data?.allQuizzes[0]?.quiz?.title,
          description: quizResult?.data?.allQuizzes[0]?.quiz?.description,
        });
      } else {
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fmode === "FINAL_ASSESSMENT") {
      getCourseDetails();
    } else {
      getModuleDetails();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Object.entries(sectionClicked)?.length !== 0) {
          const getPendingData = await getPendingTasks({
            variables: {
              courseId: courseId,
              moduleId: sectionClicked?.m_id,
              userMiniAssessmentRelationId: null,
            },
          });

          if (getPendingData?.data?.getPendingTasks?.length === 0) {
            return;
          }

          const activeTaskTrackingObj =
            getPendingData?.data?.getPendingTasks?.find((obj) => {
              if (obj.taskType === "MCQ_GENERATION") {
                return obj;
              }
            });

          if (activeTaskTrackingObj === undefined) {
            return;
          }

          setIsLoading(true);
          let status = TASK_STATUS.DATA_GENERATION_IN_PROGRESS;
          let taskConfig = "";
          message.loading("Quiz data generation is in progress.");

          while (
            status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS ||
            status === TASK_STATUS.DATA_LOAD_IN_PROGRESS ||
            status === TASK_STATUS.DATA_PROGRESS
          ) {
            const statusResult = await getTaskStatus({
              variables: {
                taskTrackingId: [activeTaskTrackingObj?.taskTrackingId],
              },
            });

            status = statusResult?.data?.getTaskStatus[0]?.taskStatus;
            taskConfig =
              statusResult?.data?.getTaskStatus[0]?.taskTrackingDetails
                ?.taskConfig;

            if (
              status === TASK_STATUS.DATA_GENERATION_IN_PROGRESS ||
              status === TASK_STATUS.DATA_LOAD_IN_PROGRESS ||
              status === TASK_STATUS.DATA_PROGRESS
            ) {
              await new Promise((resolve) => setTimeout(resolve, 10000));
            }
          }

          if (status === "FAILED") {
            setIsLoading(false);
            message.error("Something went wrong. Please try again.");
            return;
          }

          const responseObj = JSON.parse(taskConfig);
          const quizId = responseObj?.quiz_id;

          if (quizId === null) {
            setIsLoading(false);
            message.error("Something went wrong. Please try again.");
            return;
          }

          setQuiz_id(quizId);

          const quizResult = await getAllQuiz({
            variables: {
              courseId: courseId,
              userId: localStorage.getItem("userId"),
              quizId: quizId,
            },
          });

          acknowledgeTask({
            variables: {
              taskTrackingId: activeTaskTrackingObj?.taskTrackingId,
            },
          });

          if (
            quizResult?.data?.allQuizzes === null ||
            quizResult?.data?.allQuizzes?.length === 0
          ) {
            setIsLoading(false);
            message.error("Something went wrong. Please try again.");
            return;
          }

          setResponseData(quizResult?.data?.allQuizzes);
          setQuizData(
            quizResult?.data?.allQuizzes.map((obj, index) => {
              // To be replaced.
              return {
                score: obj?.score,
                answer: obj?.choiceSet?.find((item) => item.isRightChoice)
                  ?.choice,
                type: obj?.type,
                title: obj?.title,
                explanation: obj?.choiceSet?.find((item) => item.isRightChoice)
                  ?.explanation,
                option_1: obj?.choiceSet?.[0]?.choice,
                option_2: obj?.choiceSet?.[1]?.choice,
                option_3: obj?.choiceSet?.[2]?.choice,
                option_4: obj?.choiceSet?.[3]?.choice,
                correctIndex: obj?.choiceSet?.findIndex(
                  (item) => item.isRightChoice
                ),
              };
            })
          );
          setIsLoading(false);
          message.success("Quiz data generated successfully");
          setIsFormEditable(true);
        }
      } catch (error) {}
    };

    fetchData();
  }, [sectionClicked]);

  useEffect(() => {
    if (courseDetailsResp && fmode === "FINAL_ASSESSMENT") {
      const inputString = courseDetailsResp?.courseDetails?.courseName;
      if (inputString) {
        const updatedCourseName =
          inputString.charAt(0).toUpperCase() +
          inputString.slice(1).toLowerCase();
        setCourseTitle(updatedCourseName);
      }
    }
  }, [courseDetailsResp]);

  const nextQuestion = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      updateQuestionFormFields(currentQuestionIndex + 1);
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      updateQuestionFormFields(currentQuestionIndex - 1);
    }
  };

  const updateObject = (idToUpdate: any, newQuiz: any) => {
    const updatedObjects = sectionDetails.map((obj) =>
      obj.moduleId === idToUpdate
        ? {
            ...obj,
            quizzes: Array.isArray(obj.quizzes)
              ? !isEdit
                ? [...obj.quizzes, newQuiz]
                : obj.quizzes.map((quizObj) =>
                    quizObj.id === newQuiz.id ? newQuiz : quizObj
                  )
              : [newQuiz],
          }
        : obj
    );

    updateSection(updatedObjects);
  };

  const onSave = async () => {
    try {
      await questionForm.validateFields();
      const errors = questionForm.getFieldsError();
      const hasErrors = errors.some(
        (fieldError) => fieldError.errors.length !== 0
      );

      await quizDetailsForm.validateFields();
      const errors2 = questionForm.getFieldsError();
      const hasErrors2 = errors2.some(
        (fieldError) => fieldError.errors.length !== 0
      );

      if (!hasErrors && !hasErrors2) {
        const newQuiz = quizData;
        const orderNo = orderNumber;

        if (!isEdit) {
          updateObject(sectionClicked.moduleId, {
            ...newQuiz,
            orderNo,
            newEntry: true,
          });
        } else {
          updateObject(sectionClicked.moduleId, {
            ...newQuiz,
            orderNo,
          });
        }

        if (quiz_id) {
          createUpdateQuizRelData({
            variables: {
              courseId: courseId,
              moduleId: sectionClicked?.m_id,
              orderNumber: orderNo,
              quizDetails: {
                id: quiz_id,
                duration: quizDetails?.duration,
                description: quizDetails?.description,
                totalScore: quizDetails?.totalScore,
                title: quizDetails?.title,
                prerequisite: responseData?.quiz?.prerequisite,
                passingThresholdRanges:
                  responseData?.quiz?.passingThresholdRanges,
                considerForEvaluation:
                  responseData?.quiz?.considerForEvaluation,
                questions: quizData?.map((question, index) => {
                  return {
                    title: question?.title,
                    score: question?.score,
                    type: question?.type,
                    order: index + 1,
                    level: question?.level,
                    choices: question?.choiceSet?.map((item, ci) => {
                      if (ci == question?.correctIndex) {
                        return {
                          id: item?.id,
                          explanation: question?.explanation,
                          choice: question[`option_${ci + 1}`],
                          isRightChoice: true,
                        };
                      } else {
                        return {
                          id: item?.id,
                          explanation: question?.explanation,
                          choice: question[`option_${ci + 1}`],
                          isRightChoice: false,
                        };
                      }
                    }),
                  };
                }),
              },
            },
          })
            .then(() => {
              setInsideHandleEdit(false);
              setIsSaveClickedMultiple((prev) => prev + 1);
            })
            .then(() => {
              getModuleDetails();
              message.success("Quiz saved successfully!");
            });
        } else {
          createUpdateQuizRelData({
            variables: {
              courseId: courseId,
              moduleId: sectionClicked?.m_id,
              orderNumber: orderNo,
              quizDetails: {
                duration: quizDetails?.duration,
                description: quizDetails?.description,
                totalScore: quizDetails?.totalScore,
                title: quizDetails?.title,
                prerequisite: "",
                passingThresholdRanges: "",
                considerForEvaluation: true,
                questions: quizData?.map((question, index) => {
                  return {
                    title: question?.title,
                    score: question?.score,
                    type: question?.type,
                    order: index + 1,
                    level: question?.level,
                    choices: question?.choiceSet?.map((item, ci) => {
                      if (ci == question?.correctIndex) {
                        return {
                          id: item?.id,
                          explanation: question?.explanation,
                          choice: question[`option_${ci + 1}`],
                          isRightChoice: true,
                        };
                      } else {
                        return {
                          id: item?.id,
                          explanation: question?.explanation,
                          choice: question[`option_${ci + 1}`],
                          isRightChoice: false,
                        };
                      }
                    }),
                  };
                }),
              },
            },
          })
            .then(() => {
              setInsideHandleEdit(false);
              setIsSaveClickedMultiple((prev) => prev + 1);
            })
            .then(() => {
              getModuleDetails();
              message.success("Quiz saved successfully!");
            });
        }
      }
    } catch (error) {
      message.error("Cannot save the form");
      console.log(error);
    }
  };

  useEffect(() => {
    if (quizData?.length > 0) {
      updateQuestionFormFields(currentQuestionIndex);

      // updateQuizFormFields();
    }
  }, [quizData]);

  const handleInputChange = (event, field) => {
    if (!quizData) return;

    let updatedData = [...quizData];
    updatedData = updatedData.map((question) => ({ ...question }));
    updatedData[currentQuestionIndex][field] = event.target.value;
    setQuizData(updatedData);
  };

  const handleInputChange2 = (event, field) => {
    if (!quizDetails) {
      setQuizDetails({
        duration: "",
        totalScore: "",
        title: "",
        description: "",
      });
    }

    let updatedData = { ...quizDetails };
    updatedData[field] = event.target.value;
    setQuizDetails(updatedData);
  };

  const handleSelectChangeForType = (value, field) => {
    if (!quizData) return;

    if (quizData[currentQuestionIndex][field] !== value) {
      updateQuizData(field, value.value);
    }
  };
  const handleSelectChange = (value, field) => {
    if (!quizData) return;

    if (quizData[currentQuestionIndex][field] !== value) {
      updateQuizData(field, value);
    }
  };

  const handleQuillChange = (value, field) => {
    if (!quizData) return;

    if (quizData[currentQuestionIndex][field] !== value) {
      updateQuizData(field, value);
    }
  };
  const handleQuillChange2 = (value, field) => {
    if (!quizDetails) {
      setQuizDetails({
        duration: "",
        totalScore: "",
        title: "",
        description: "",
      });
    }

    if (quizDetails[field] !== value) {
      let updatedData = { ...quizDetails };
      updatedData[field] = value;
      setQuizDetails(updatedData);
    }
  };

  const updateQuizData = (field, value) => {
    let updatedData = [...quizData];
    updatedData = updatedData.map((question) => ({ ...question }));
    updatedData[currentQuestionIndex][field] = value;
    if (field === "answer") {
      updatedData[currentQuestionIndex][field] = value.value;
      updatedData[currentQuestionIndex]["correctIndex"] = value.key;
    }
    setQuizData(updatedData);
  };
  useEffect(() => {
    if (
      moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.length > 0 &&
      !insideHandleEdit &&
      isSaveClickedMultiple <= 1
    ) {
      const moduleData =
        moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.filter(
          (obj) => obj.module.id === sectionClicked.m_id
        );
      let orderNumber = 1;

      if (moduleData?.[0]?.moduleMapper?.length >= 1) {
        orderNumber =
          moduleData?.[0]?.moduleMapper?.[
            moduleData?.[0]?.moduleMapper?.length - 1
          ]?.orderNumber + 1;
      }

      const responseData =
        moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.map(
          (module) => {
            const m_id = module.module.id;
            const moduleId = module.module.moduleId;
            const title = module.module.moduleName;
            const moduleNumber = module.module.moduleNumber;
            const moduleType = module.module.moduleType;
            const lessons = module.moduleMapper
              .filter((item) => item.lesson !== null)
              .map((item) => {
                const l_id = item.lesson.id;
                const lessonId = item.lesson.lessonId;
                const lessonName = item.lesson.lessonName;
                const fileType = item.lesson.contentType;
                const fileName = item.lesson.contentLink;
                const orderNumber = item.orderNumber;
                const summary = item.lesson.summary;
                const suggestiveQuestions = item.lesson.suggestiveQuestions;

                return {
                  l_id,
                  newEntry: false,
                  lessonId,
                  lessonName,
                  fileType,
                  fileName,
                  orderNumber,
                  summary,
                  suggestiveQuestions,
                };
              });

            const quizzes = module.moduleMapper
              .filter((item) => item.quiz !== null)
              .map((item) => {
                const id = item.quiz.id;
                const title = item.quiz.title;
                const description = item.quiz.description;
                const totalScore = item.quiz.totalScore;
                const duration = item.quiz.duration;
                const prerequisite = item.quiz.prerequisite;
                const orderNumber = item.orderNumber;

                return {
                  id,
                  title,
                  description,
                  totalScore,
                  duration,
                  prerequisite,
                  orderNumber,
                };
              });

            const assessments = module.moduleMapper
              .filter((item) => item.miniAssessment !== null)
              .map((item) => {
                const id = item.miniAssessment.id;
                const title = item.miniAssessment.title;
                const duration = item.miniAssessment.duration;
                const description = item.miniAssessment.description;
                const orderNumber = item.orderNumber;

                return {
                  id,
                  title,
                  duration,
                  description,
                  orderNumber,
                };
              });

            return {
              m_id,
              moduleId,
              newEntry: false,
              title,
              moduleNumber,
              moduleType,
              lessons,
              quizzes,
              assessments,
            };
          }
        );

      updateSection(responseData);
      setOrderNumber(orderNumber);
    }
  }, [orderNumber, moduleDetailsResp, insideHandleEdit, isSaveClickedMultiple]);

  const getIndex = (id: any) => {
    for (let i = 0; i < sectionDetails.length; i++) {
      if (sectionDetails[i].moduleId === id) return i;
    }
    return 0;
  };

  const handleEditQuiz = async (quiz) => {
    setIsEdit(true);
    setIsFormEditable(true);
    setInsideHandleEdit(true);
    setOrderNumber(quiz.orderNumber);
    setIsSaveClickedMultiple(0);
    const quizResult = await getAllQuiz({
      variables: {
        courseId: courseId,
        userId: localStorage.getItem("userId"),
        quizId: quiz?.id,
      },
    });

    setQuiz_id(quiz?.id);
    setResponseData(quizResult?.data?.allQuizzes);
    const object = quizResult?.data?.allQuizzes?.quiz?.questionSet;
    setQuizData(
      object?.map((obj, index) => {
        return {
          score: obj?.score,
          answer: obj?.choiceSet?.find((item) => item.isRightChoice)?.choice,
          type: obj?.type,
          title: obj?.title,
          explanation: obj?.choiceSet?.find((item) => item.isRightChoice)
            ?.explanation,
          choiceSet: obj?.choiceSet,
          level: obj?.level,
          option_1: obj?.choiceSet?.[0]?.choice,
          option_2: obj?.choiceSet?.[1]?.choice,
          option_3: obj?.choiceSet?.[2]?.choice,
          option_4: obj?.choiceSet?.[3]?.choice,
          correctIndex: obj?.choiceSet?.findIndex((item) => item.isRightChoice),
        };
      })
    );
    message.success("Quiz fetched successfully!!");

    setQuizDetails({
      duration: quizResult?.data?.allQuizzes?.quiz?.duration,
      totalScore: quizResult?.data?.allQuizzes?.quiz?.totalScore,
      title: quizResult?.data?.allQuizzes?.quiz?.title,
      description: quizResult?.data?.allQuizzes?.quiz?.description,
    });
    setQuizGenMode("MANUAL");

    quizDetailsForm.setFieldsValue({
      duration: quizResult?.data?.allQuizzes?.quiz?.duration,
      totalScore: quizResult?.data?.allQuizzes?.quiz?.totalScore,
      title: quizResult?.data?.allQuizzes?.quiz?.title,
      description: quizResult?.data?.allQuizzes?.quiz?.description,
    });
  };

  const onQuizDelete = (index) => {
    handleQuizDelete(
      sectionDetails[getIndex(sectionClicked.moduleId)]?.quizzes,
      index
    );
  };

  const handleQuizDelete = (quizzes, index) => {
    let deleteQuizId = null;

    const newQuiz = quizzes.filter((quiz, quizIndex) => quizIndex !== index);

    const deleteQuiz = quizzes.filter((quiz, quizIndex) => quizIndex === index);

    deleteQuizId = deleteQuiz?.[0]?.id;

    const updatedObjects = sectionDetails.map((obj) =>
      obj.moduleId === sectionClicked.moduleId
        ? { ...obj, quizzes: newQuiz }
        : obj
    );

    // Update the state with the new array
    updateSection(updatedObjects);

    deleteQuizRelData({
      variables: {
        courseId: courseId,
        quizId: deleteQuizId,
      },
    })
      .then(() => {
        setInsideHandleEdit(false);
      })
      .then(() => {
        getModuleDetails();
      });
  };

  const handleQuestionDelete = (index) => {
    let tempQuizData = quizData.filter((quiz, qindex) => qindex !== index);
    setQuizData(tempQuizData);
    setCurrentQuestionIndex(0);
  };

  const handleAddQuestionClick = () => {
    // setIsSaveClickedMultiple(0);
    setQuizData([
      ...quizData,
      {
        score: "",
        answer: "",
        type: "",
        title: "",
        level: "",
        choiceSet: ["", "", "", ""],
        explanation: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        correctIndex: "",
      },
    ]);
  };

  return (
    <>
      {courseDetailsLoading || moduleDetailsLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {sectionDetails[getIndex(sectionClicked.moduleId)]?.quizzes
            ?.length !== 0 && (
            <>
              {sectionDetails[getIndex(sectionClicked.moduleId)]?.quizzes?.map(
                (quiz, index) => {
                  return (
                    <QuizTile
                      quiz={quiz}
                      mode="CREATE"
                      onEdit={() => handleEditQuiz(quiz)}
                      handlequizDelete={() => {
                        setDeleteQuizModalIsOpen(true);
                        setClickedQuizIndex(index);
                      }}
                    />
                  );
                }
              )}
            </>
          )}

          <Row className="quiz-parent-container">
            {quizGenMode === "MANUAL" && (
              <Row gutter={[24, 24]} className="quiz-details-container">
                <Form form={quizDetailsForm} className="quiz-details-form">
                  <Row gutter={[24, 24]} className="mb-20">
                    <Col lg={8} xs={24}>
                      <Col lg={12} xs={24} className="label mt-8 mb-8">
                        Title
                      </Col>
                      <Col lg={24} xs={24}>
                        <Form.Item
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: `Please input title`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <Input
                            size="large"
                            placeholder="Please enter title"
                            type="string"
                            onChange={(e) => handleInputChange2(e, "title")}
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>
                    </Col>

                    <Col lg={8} xs={24}>
                      <Col lg={12} xs={24} className="label mt-8 mb-8">
                        Total Score
                      </Col>
                      <Col lg={24} xs={24}>
                        <Form.Item
                          name="totalScore"
                          rules={[
                            {
                              required: true,
                              message: `Please input totalScore`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <Input
                            size="large"
                            placeholder="Please enter totalScore"
                            type="number"
                            onChange={(e) =>
                              handleInputChange2(e, "totalScore")
                            }
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>
                    </Col>

                    <Col lg={8} xs={24} style={{ paddingRight: "0" }}>
                      <Col lg={12} xs={24} className="label mt-8 mb-8">
                        Duration
                      </Col>
                      <Col lg={24} xs={24} style={{ paddingRight: "0" }}>
                        <Form.Item
                          name="duration"
                          rules={[
                            {
                              required: true,
                              message: `Please input duration`,
                            },
                          ]}
                          className="mb-0"
                        >
                          {/* <Input
                            size="large"
                            placeholder="Please select answer"
                            type="string"
                            onChange={(e) => handleInputChange(e, "answer")}
                            disabled={!isFormEditable}
                          /> */}
                          <Input
                            size="large"
                            placeholder="Please enter duration"
                            type="number"
                            onChange={(e) => handleInputChange2(e, "duration")}
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>
                    </Col>

                    <Col lg={24} xs={24} style={{ paddingRight: "0" }}>
                      <Col lg={12} xs={24} className="label mt-8 mb-8">
                        Description
                      </Col>
                      <Col lg={24} xs={24} style={{ paddingRight: "0" }}>
                        <Form.Item
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: `Please input description`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <ReactQuill
                            value={"text"}
                            theme="snow"
                            onChange={(value) =>
                              handleQuillChange2(value, "description")
                            }
                            readOnly={!isFormEditable && true}
                          />
                        </Form.Item>
                      </Col>
                    </Col>
                  </Row>
                </Form>
              </Row>
            )}

            <Row gutter={[24, 24]} className="generate-quiz-container">
              <Col
                lg={7}
                xs={24}
                className="generate-question-box"
                style={isLoading ? { cursor: "not-allowed" } : {}}
              >
                <Row className="mb-20 question-box-title mr-12 ml-12">
                  <Col>
                    <Row>
                      {quizGenMode !== "" && (
                        <ArrowLeftOutlined
                          className="mr-20"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            setQuizGenMode("");
                            //setAssessmentData(null);
                            setQuizData([]);
                            setQuizDetails({
                              duration: "",
                              totalScore: "",
                              title: "",
                              description: "",
                            });
                            quizDetailsForm.setFieldsValue({
                              duration: "",
                              totalScore: "",
                              title: "",
                              description: "",
                            });
                          }}
                        />
                      )}
                      <span className="question-box-title-text">
                        {" "}
                        {fmode === "REGULAR"
                          ? `Section ${
                              sectionDetails.findIndex(
                                (section) =>
                                  section.moduleId === sectionClicked.moduleId
                              ) + 1
                            }: ${sectionClicked?.title}`
                          : `${courseTitle}-Final Assessment`}
                      </span>
                    </Row>
                    <Row>
                      <span>Please fill below to create questions</span>
                    </Row>
                  </Col>
                </Row>
                {quizData?.length === 0 && quizGenMode === "GEN_AI" && (
                  <Form form={quizForm} className="">
                    <Row gutter={[24, 24]} className="mb-20">
                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-2 mb-8">
                          <span className="mandatory">* </span> Easy Level
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="easy"
                            rules={[
                              {
                                required: true,
                                message: `Please input number easy level of questions`,
                              },
                              {
                                validator: (_, value) => {
                                  if (value >= 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("Please enter a positive number.")
                                  );
                                },
                              },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              size="large"
                              placeholder="Enter number of easy level of questions"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-2 mb-8">
                          <span className="mandatory">* </span> Medium Level
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="medium"
                            rules={[
                              {
                                required: true,
                                message: `Please input number medium level of questions`,
                              },
                              {
                                validator: (_, value) => {
                                  if (value >= 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("Please enter a positive number.")
                                  );
                                },
                              },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              size="large"
                              placeholder="Enter number of medium level of questions"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-2 mb-8">
                          <span className="mandatory">* </span> Hard Level
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="hard"
                            rules={[
                              {
                                required: true,
                                message: `Please input number hard level of questions`,
                              },
                              {
                                validator: (_, value) => {
                                  if (value >= 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("Please enter a positive number.")
                                  );
                                },
                              },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              size="large"
                              placeholder="Enter number of hard level of questions"
                              type="number"
                            />
                          </Form.Item>
                        </Col>
                      </Col>
                    </Row>

                    <Row gutter={[24, 24]} className="mb-20">
                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          <span className="mandatory">* </span> Choose Lessons
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="lessons"
                            className="mb-0"
                            rules={[
                              {
                                required: true,
                                message: `Please select lessons`,
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Please select lessons"
                              size="large"
                            >
                              {sectionDetails &&
                                sectionDetails.map((section) => {
                                  if (
                                    fmode === "REGULAR" &&
                                    section.moduleId ===
                                      sectionClicked?.moduleId
                                  ) {
                                    return section.lessons.map((lesson) => (
                                      <Option
                                        key={lesson.l_id}
                                        value={lesson.l_id}
                                      >
                                        {lesson.lessonName}
                                      </Option>
                                    ));
                                  } else if (fmode === "FINAL_ASSESSMENT") {
                                    return section.lessons.map((lesson) => {
                                      return (
                                        <Option
                                          key={lesson.l_id}
                                          value={lesson.l_id}
                                        >
                                          {lesson.lessonName}
                                        </Option>
                                      );
                                    });
                                  }
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Col>
                    </Row>

                    <Row gutter={[24, 24]} className="mb-20">
                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          <span className="mandatory">* </span> Type of
                          questions
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="questionType"
                            className="mb-0"
                            rules={[
                              {
                                required: true,
                                message: `Please select type of question`,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please select type of questions"
                              size="large"
                            >
                              {typeOfQuestions.map((option, index) => (
                                <Option key={index} value={option}>
                                  {option}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Col>
                    </Row>

                    <Row
                      gutter={[24, 24]}
                      className="mt-30"
                      style={{ marginLeft: "0", marginRight: "0" }}
                    >
                      <Col span={24}>
                        <Button
                          className="generate-question-button"
                          onClick={() => handleGenerateQuizClick()}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <div className="overlay"></div>
                              <Spin
                                indicator={antIcon}
                                style={{ color: "white" }}
                              />{" "}
                              Loading
                            </>
                          ) : (
                            "Generate Quiz"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}

                {(quizData?.length !== 0 || quizGenMode === "MANUAL") && (
                  <>
                    {quizData?.map((question, index) => {
                      return (
                        <Row
                          gutter={[24, 24]}
                          className="mb-20 assessment-questions-tile flex flex--space-between"
                          style={{ marginLeft: "12px", marginRight: "12px" }}
                        >
                          <Col> Question {index + 1}</Col>
                          <Col className="assessment-details-tile-icons">
                            <DeleteOutlined
                              style={{ color: "#A8071A" }}
                              className="mr-16 delete-icon"
                              onClick={() => {
                                handleQuestionDelete(index);
                              }}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Row
                      gutter={[24, 24]}
                      className="mt-24"
                      style={{ marginLeft: "0", marginRight: "0" }}
                    >
                      <Col span={24}>
                        <Button
                          className="generate-question-button"
                          onClick={() => handleAddQuestionClick()}
                          disabled={isLoading}
                        >
                          Add Question
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}

                {quizGenMode === "" && quizData?.length === 0 && (
                  <>
                    <Row gutter={[24, 24]}>
                      <Radio.Group
                        onChange={(e) => handleModeSelect(e.target.value)}
                      >
                        {MODE.map((choice: any) => (
                          <Col
                            span={12}
                            style={{
                              marginLeft: "12px",
                              marginRight: "12px",
                              marginBottom: "10px",
                            }}
                          >
                            <Radio value={choice}>{choice}</Radio>
                          </Col>
                        ))}
                      </Radio.Group>
                    </Row>

                    {/* <Row
                    gutter={[24, 24]}
                    className="mt-24"
                    style={{ marginLeft: "0", marginRight: "0" }}
                  >
                    <Col span={24}>
                      <Button
                        className="generate-question-button"
                        onClick={() => handleModeSelect()}
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row> */}
                  </>
                )}
              </Col>

              <Col lg={16} xs={24} className="quiz-question-box">
                <Row className="mb-20 question-box-title mr-14 ml-14">
                  <Col>
                    <Row>Generated Question</Row>
                    <Row className="question-box-title-text">
                      {quizData?.length === 0
                        ? `Question -/-`
                        : `Question ${currentQuestionIndex + 1}/${
                            quizData?.length
                          }`}
                    </Row>
                  </Col>
                  <Col className="content-right">
                    <span
                      className="mr-24"
                      onClick={previousQuestion}
                      style={
                        currentQuestionIndex === 0
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                    >
                      <img src={icons.caretLeft} alt="" />
                    </span>
                    <span
                      onClick={nextQuestion}
                      style={
                        quizData?.length === 0 ||
                        (quizData &&
                          currentQuestionIndex === quizData?.length - 1)
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                    >
                      <img src={icons.caretRight} alt="" />
                    </span>
                  </Col>
                </Row>

                {quizData?.length !== 0 ? (
                  <Form form={questionForm} className="">
                    <Row gutter={[24, 24]} className="mb-20">
                      <Col lg={12} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          Type
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="type"
                            className="mb-0"
                            rules={[
                              {
                                required: true,
                                message: `Please select type`,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please select type of questions"
                              size="large"
                              onChange={(value, index) => {
                                handleSelectChangeForType(index, "type");
                              }}
                            >
                              {typeOfQuestions.map((option, index) => (
                                <Option key={index} value={option}>
                                  {option}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Col>

                      <Col lg={12} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          Score
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="score"
                            rules={[
                              {
                                required: true,
                                message: `Please input score`,
                              },
                            ]}
                            className="mb-0"
                          >
                            <Input
                              size="large"
                              placeholder="Please enter score"
                              type="number"
                              onChange={(e) => handleInputChange(e, "score")}
                              disabled={!isFormEditable}
                            />
                          </Form.Item>
                        </Col>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          Answer
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="answer"
                            rules={[
                              {
                                required: true,
                                message: `Please input answer`,
                              },
                            ]}
                            className="mb-0"
                          >
                            {/* <Input
                            size="large"
                            placeholder="Please select answer"
                            type="string"
                            onChange={(e) => handleInputChange(e, "answer")}
                            disabled={!isFormEditable}
                          /> */}

                            <Select
                              placeholder="Please select skills"
                              size="large"
                              options={defaultSkills}
                              onChange={(value, index) => {
                                handleSelectChange(index, "answer");
                              }}
                            >
                              {skills &&
                                skills?.map((skill, index) => (
                                  <Option key={index} value={skill}>
                                    {skill}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          Question
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: `Please input question`,
                              },
                            ]}
                            className="mb-0"
                          >
                            <ReactQuill
                              value={"text"}
                              theme="snow"
                              onChange={(value) =>
                                handleQuillChange(value, "title")
                              }
                              readOnly={!isFormEditable && true}
                            />
                          </Form.Item>
                        </Col>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Col lg={12} xs={24} className="label mt-8 mb-8">
                          Explanation
                        </Col>
                        <Col lg={24} xs={24}>
                          <Form.Item
                            name="explanation"
                            rules={[
                              {
                                required: true,
                                message: `Please input explanation`,
                              },
                            ]}
                            className="mb-0"
                          >
                            <TextArea
                              showCount
                              maxLength={2000}
                              placeholder="Enter quiz explanation"
                              onChange={(e) =>
                                handleInputChange(e, "explanation")
                              }
                              disabled={!isFormEditable}
                            />
                          </Form.Item>
                        </Col>
                      </Col>
                    </Row>

                    <Row className="mr-12 ml-12">
                      <Col lg={12} xs={24} className="label mt-8 mb-8">
                        Options
                      </Col>

                      <Col lg={24} xs={24}>
                        <Form.Item
                          name={`option_1`}
                          rules={[
                            {
                              required: true,
                              message: `Please input option`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <Input
                            size="large"
                            placeholder="Please enter option"
                            type="string"
                            className="mb-20"
                            onChange={(e) => handleInputChange(e, "option_1")}
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Form.Item
                          name={`option_2`}
                          rules={[
                            {
                              required: true,
                              message: `Please input option`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <Input
                            size="large"
                            placeholder="Please enter option"
                            type="string"
                            className="mb-20"
                            onChange={(e) => handleInputChange(e, "option_2")}
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Form.Item
                          name={`option_3`}
                          rules={[
                            {
                              required: true,
                              message: `Please input option`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <Input
                            size="large"
                            placeholder="Please enter option"
                            type="string"
                            className="mb-20"
                            onChange={(e) => handleInputChange(e, "option_3")}
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={24} xs={24}>
                        <Form.Item
                          name={`option_4`}
                          rules={[
                            {
                              required: true,
                              message: `Please input option`,
                            },
                          ]}
                          className="mb-0"
                        >
                          <Input
                            size="large"
                            placeholder="Please enter option"
                            type="string"
                            className="mb-20"
                            onChange={(e) => handleInputChange(e, "option_4")}
                            disabled={!isFormEditable}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="flex flex--space-between mr-12 ml-12 mt-20">
                      <Col></Col>

                      <Col>
                        <Button
                          className="save-quiz-button"
                          onClick={() => onSave()}
                          disabled={
                            isSaveClickedMultiple >= 1 || quizData?.length === 0
                          }
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <Row className="flex flex--center flex--align-center">
                    <Row className="generate-quiz-image-container ">
                      <img
                        className="generate-quiz-image"
                        src={icons.generate}
                        alt=""
                      />
                    </Row>
                    <Row className="generate-quiz-text-container">
                      <span className="generate-quiz-text-container-span">
                        Kindly input the necessary information to create your
                        quiz
                      </span>
                    </Row>
                  </Row>
                )}
              </Col>
            </Row>
          </Row>
        </>
      )}

      <Modal
        title={
          <span style={{ display: "flex" }}>
            <img
              src={icons.deleteIcon}
              alt="deleteIcon icon"
              style={{ marginRight: "10px" }}
            />
            <span>Are you sure you want to delete the quiz?</span>
          </span>
        }
        visible={deleteQuizModalIsOpen}
        onCancel={() => setDeleteQuizModalIsOpen(false)}
        footer={[
          <Button key="no" onClick={() => setDeleteQuizModalIsOpen(false)}>
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              onQuizDelete(clickedQuizIndex);
              setDeleteQuizModalIsOpen(false);
            }}
          >
            Yes
          </Button>,
        ]}
      />
    </>
  );
};

export default GenerateQuiz;
