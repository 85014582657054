import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./enrollButton.scss";
interface IButton {
  id: number;
}
const ResumeButton: React.FC<IButton> = ({ id }) => {
  const navigate = useNavigate();
  return (
    <Button
      className="enroll-btn"
      onClick={() => {
        navigate(`/coursedetails/coursecontent/${id}`);
      }}
    >
      Resume
    </Button>
  );
};
export default ResumeButton;
