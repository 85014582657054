import { gql } from "@apollo/client";

export const GET_MODULE_DETAILS = gql`
  query ($courseId: ID!) {
    getAllModulesDetails(courseId: $courseId) {
      moduleDetails {
        module {
          id
          moduleId
          moduleName
          moduleNumber
          moduleType
          badgeLinks
        }
        moduleMapper {
          mapperId
          orderNumber
          quiz {
            id
            title
            description
            totalScore
            duration
            prerequisite
          }
          lesson {
            id
            lessonId
            contentType
            contentThumbnail
            contentLink
            lessonName
            duration
            summary
            suggestiveQuestions
          }
          assessment {
            assessmentId
            title
            name
          }
          miniAssessment {
            id
            title
            duration
            description
          }
        }
      }
      userLessonRelation {
        id
        status
        lesson {
          id
        }
      }

      userQuizRelation {
        id
        status
        quiz {
          id
        }
      }

      userMiniAssessmentRelation {
        id
        status
        score
        userminiassessmentquestionrelationSet {
          id
          isActive
          isEvaluated
          scoreAchieved

          question {
            id
          }
        }
        miniAssessment {
          id
        }
      }
    }
  }
`;

export const GET_MINI_ASSESSMENT_QUESTION_DETAILS = gql`
  query ($courseId: ID!) {
    fetchUserMiniAssessmentRelation(courseId: $courseId) {
      miniAssessment {
        id
        title
      }
      id
      status
      score
      userminiassessmentquestionrelationSet {
        id
        isActive
        isEvaluated
        scoreAchieved
        solution

        question {
          id
        }
      }
    }
  }
`;
export const GET_COURSES_FOR_LOGGEDIN_USER = gql`
  query ($userId: ID!) {
    coursesForLoggedInUser(userId: $userId) {
      id
      course {
        id
        courseName
        duration
        courseDetails
        noOfQuizzes
        noOfAssessments
        courseBanner
        title
      }
      userEnrollment {
        completionStatus
      }
      totalAssessmentCount
      completedAssessmentCount
      totalQuizCount
      attemptedQuizCount
      totalLessonCount
      completedLessonCount
      totalMiniAssessmentCount
      completedMiniAssessmentCount
    }
  }
`;

export const COURSE_DETAILS = gql`
  query ($courseId: ID!, $userEmail: String!) {
    courseDetails(courseId: $courseId, userEmail: $userEmail) {
      id
      courseId
      courseName
      title
      duration
      courseDetails
      whatYouWillLearn
      prerequisite
      githubTeam
      updatedAt
      totalEnrollments
      noOfSeatsLeftAndNextEnrollmentDate
      userCourseRelationData
      noOfQuizzes
      noOfAssessments
      totalNoOfMiniAssessment
      maxEnrollments
      courseBanner
      validTo
      validFrom
      skills
    }
  }
`;

export const COURSE_MINIMUM_DETAILS = gql`
  query ($courseId: ID!, $userEmail: String!) {
    courseDetails(courseId: $courseId, userEmail: $userEmail) {
      id
      courseName
      title
      courseDetails
      moduleProgress {
        module {
          id
        }
        noOfTasks
        noOfTasksCompleted
      }
      userCourseRelationData
    }
  }
`;
export const GET_ALL_COURSES = gql`
  query {
    getPublishedCourses {
      id
      courseName
      title
      courseDetails
      courseBanner
      userCourseRelationData
      courseId
      courseLink
      duration
      noOfAssessments
      totalNoOfMiniAssessment
      noOfQuizzes
      status
      createdBy {
        id
        name
        email
      }
    }
  }
`;
export const GET_LOGGEDIN_USER = gql`
  query {
    users {
      id
      isContentCreator
    }
  }
`;

export const GET_QUIZ_DETAILS = gql`
  query ($quizId: ID!, $userId: ID!, $courseId: ID!) {
    userQuizDetails(quizId: $quizId, userId: $userId, courseId: $courseId) {
      showQuiz
      showAnswers
      quizDuration
      message
      userQuizRelation {
        status
      }
      quizDetails {
        title
        description
        totalScore
        questionSet {
          id
          title
          order
          type
          score
          choiceSet {
            id
            choice
            isRightChoice
            explanation
          }
        }
      }
    }
  }
`;

export const GET_QUIZ_ANSWERS = gql`
  query ($userId: ID!, $quizId: ID!, $courseId: ID!) {
    fetchUserQuizChoice(userId: $userId, quizId: $quizId, courseId: $courseId) {
      quiz {
        id
        questionSet {
          id
          type
          title
          order
          choiceSet {
            id
            choice
            isRightChoice
          }
        }
      }
      userQuestionChoice {
        question {
          id
          type
        }
        choice {
          id
          explanation
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    allUsers(orgCognitoId: "9ebbef0e-6d07-4db8-8f0b-132ced05cf88") {
      id
      email
    }
  }
`;

export const GET_MY_CONTENT = gql`
  query ($userId: ID!) {
    getUserCoursesAndCollaboratedCourses(userId: $userId) {
      userCourses {
        createdAt
        course {
          title
          id
          courseId
          courseName
          courseDetails
          title
          validTo
          validFrom
        }
      }
      collaboratorCourses {
        createdAt
        course {
          title
          id
          courseId
          courseName
          courseDetails
          title
          validTo
          validFrom
        }
      }
    }
  }
`;

export const CREATE_MINI_ASSESSMENT = gql`
  query (
    $lessonIds: [ID]!
    $courseId: ID!
    $easy: Int
    $medium: Int
    $hard: Int
    $moduleId: ID!
  ) {
    generateAssessment(
      lessonIds: $lessonIds
      courseId: $courseId
      easy: $easy
      medium: $medium
      hard: $hard
      moduleId: $moduleId
    ) {
      taskTrackingId
      success
      message
    }
  }
`;
export const GENERATE_MCQ_QUESTIONS = gql`
  query (
    $lessonIds: [ID]!
    $courseId: ID!
    $easy: Int
    $medium: Int
    $hard: Int
    $questionType: String
    $moduleId: ID
  ) {
    generateMcqQuestions(
      lessonIds: $lessonIds
      courseId: $courseId
      easy: $easy
      medium: $medium
      hard: $hard
      questionType: $questionType
      moduleId: $moduleId
    ) {
      taskTrackingId
      success
      message
    }
  }
`;

export const GENERATE_URI = gql`
  query ($blobPath: String!) {
    generateSasGetUri(blobPath: $blobPath) {
      ok
      url
    }
  }
`;

export const GENERATE_ALL_QUIZ_QUESTIONS = gql`
  query ($quizId: Int!) {
    allQuizzes(quizId: $quizId) {
      quiz {
        id
        title
        prerequisite
        passingThresholdRanges
        duration
        description
        considerForEvaluation
        totalScore
        __typename
        questionSet {
          title
          score
          order
          type
          level

          choiceSet {
            choice
            isRightChoice
            explanation
            id
            __typename
          }
          __typename
        }
      }
    }
  }
`;

export const GET_TASK_STATUS_QUIZ = gql`
  query ($taskTrackingId: [UUID]!) {
    getTaskStatus(taskTrackingId: $taskTrackingId) {
      taskStatus
      message
      taskTrackingDetails {
        isActive
        taskConfig
      }
    }
  }
`;

export const GET_TASK_STATUS_ASSESSMENT = gql`
  query ($taskTrackingId: [UUID]!) {
    getTaskStatus(taskTrackingId: $taskTrackingId) {
      taskStatus
      message
      taskTrackingDetails {
        isActive
        taskConfig
      }
    }
  }
`;

export const GET_TASK_STATUS_SUMMARY_SUGGESTIVE = gql`
  query ($taskTrackingId: [UUID]!) {
    getTaskStatus(taskTrackingId: $taskTrackingId) {
      taskStatus
      message
      taskTrackingDetails {
        isActive
        taskConfig
      }
    }
  }
`;

export const GET_TASK_STATUS_MINI_ASSESSMENT = gql`
  query ($taskTrackingId: [UUID]!) {
    getTaskStatus(taskTrackingId: $taskTrackingId) {
      message
      taskTrackingDetails {
        pathToStorageDestination
        taskStatus
        taskType
        taskConfig
      }
      status
    }
  }
`;

export const PENDING_TASKS = gql`
  query ($courseId: ID!, $moduleId: ID, $userMiniAssessmentRelationId: ID) {
    getPendingTasks(
      courseId: $courseId
      moduleId: $moduleId
      userMiniAssessmentRelationId: $userMiniAssessmentRelationId
    ) {
      taskTrackingId
      taskStatus
      taskType
      taskConfig
    }
  }
`;

export const EVALUATE_ASSESSMENT_PROXY = gql`
  query (
    $courseId: ID!
    $questionId: ID!
    $miniAssessmentQuestionRelationId: ID!
  ) {
    evaluateAssessment(
      courseId: $courseId
      questionId: $questionId
      miniAssessmentQuestionRelationId: $miniAssessmentQuestionRelationId
    ) {
      success
      message
      taskTrackingId
    }
  }
`;
export const GET_MINI_ASSESSMENT_RESULT = gql`
  query (
    $questionId: ID!
    $userId: ID!
    $courseId: ID!
    $miniAssessmentQuestionRelationId: ID!
  ) {
    fetchMiniAssessmentResultDetails(
      questionId: $questionId
      userId: $userId
      courseId: $courseId
      miniAssessmentQuestionRelationId: $miniAssessmentQuestionRelationId
    ) {
      verdict
      scoreAchieved
      testCases
      totalScore
      codeCorrectness
      edgeCaseHandling
      efficiency
      areasOfImprovement
      codeStructure
      solution
    }
  }
`;

export const FETCH_ASSESSMENTS = gql`
  query ($courseId: ID!, $userId: ID!, $miniAssessmentId: ID!) {
    fetchMiniAssessmentDetails(
      courseId: $courseId
      userId: $userId
      miniAssessmentId: $miniAssessmentId
    ) {
      miniAssessment {
        id
        title
        totalScore
        duration
        description
        miniassessmentquestionsSet {
          id
          language
          title
          requirements
          evaluationCriteria
          testcases
          skeletonCode
          score
          isActive
        }
      }
    }
  }
`;

export const PROXY_CONVERSATION_API = gql`
  query (
    $requestHeader: GenericScalar
    $requestBody: GenericScalar
    $requestType: String!
    $endpoint: String!
  ) {
    proxyEndpointRequestForDeloitteBot(
      requestType: $requestType
      endpoint: $endpoint
      requestHeader: $requestHeader
      requestBody: $requestBody
    ) {
      response
      status
    }
  }
`;

export const FETCH_MINI_ASSESSMENT = gql`
  query ($courseId: ID!, $userId: ID!, $miniAssessmentId: ID!) {
    fetchMiniAssessmentDetails(
      courseId: $courseId
      userId: $userId
      miniAssessmentId: $miniAssessmentId
    ) {
      miniAssessment {
        id
        title
        totalScore
        duration
        description
        miniassessmentquestionsSet {
          id
          language
          title
          requirements
          evaluationCriteria
          testcases
          skeletonCode
          score
        }
      }
    }
  }
`;

export const GET_ALL_SKILLS = gql`
  query {
    getAllSkills
  }
`;

export const GET_COMPLETION_STATUS = gql`
  query ($courseId: ID!) {
    getCompletionStatus(courseId: $courseId) {
      allCompleted
    }
  }
`;

export const GET_COMPLETION_STATUS_FOR_BADGES = gql`
  query ($courseId: ID!, $moduleType: String) {
    getCompletionStatus(courseId: $courseId, moduleType: $moduleType) {
      allCompleted
    }
  }
`;

export const ACKNOWLEDGE_TASK = gql`
  query ($taskTrackingId: UUID!) {
    acknowledgeTask(taskTrackingId: $taskTrackingId) {
      status
      message
    }
  }
`;

export const FETCH_SUMMARY_SUGGESTIVE = gql`
  query ($lessonId: Int!) {
    lessonDetails(lessonId: $lessonId) {
      suggestiveQuestions
      summary
    }
  }
`;
