import { Col, Row } from 'antd';
import './LessonTile.scss';
import { icons } from 'assets';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const LessonTile = ({ lesson, isPreview = false, handleLessonDelete = () => { }, mode = "EDIT", onEdit = () => { } }) => {
    const getLogo = (lesson) => {
        if (lesson.fileType === "application/pdf" || lesson.fileType === "pdf") {
            return icons.pdfLogo;
        } else if (lesson.fileType.startsWith("video/")) {
            return icons.videoLogo;
        } else {
            return icons.fileIcon;
        }
    };

    return (
        <Col span={24} className="lesson-details-tile">
            <Row
                gutter={[40, 40]}
                align="middle"
                className="flex flex--space-between lesson-details-tile-row"
            >
                <Col>
                    <Row
                        className="lesson-details-tile-heading flex flex--align-center"
                        gutter={[24, 24]}
                    >
                        <Col>
                            <span className="title">{lesson.lessonName}</span>
                        </Col>
                        <Col>
                            <img src={getLogo(lesson)} alt="YourImage" />
                        </Col>
                        <Col>
                            <div>
                                <span className="duration"></span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {!isPreview && (
                    <Col className='lesson-details-tile-icons'>
                        <EditOutlined
                            style={{ color: "#08979C" }}
                            className="mr-16 edit-icon"
                            onClick={() => {
                                onEdit();
                            }}
                        />
                        <DeleteOutlined
                            style={{ color: "#A8071A" }}
                            className="mr-16 delete-icon"
                            onClick={handleLessonDelete}
                        />
                    </Col>
                )}
            </Row>
        </Col>
    )
}

export default LessonTile;