import { icons } from "assets";
import React from "react";
import "./ChatTextInput.scss";

const ChatTextInput = ({
  setInputValue,
  inputValue,
  handleSubmit,
  disable,
}) => {
  return (
    <div
      className={`question-box_container ${
        disable ? "cursor-not-allowed" : ""
      }`}
    >
      <div className="input-box">
        <form onSubmit={handleSubmit} className="input-field-1">
          <input
            type="text"
            disabled={disable}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={`${disable ? "cursor-not-allowed" : ""}`}
            placeholder="Ask a question or get started with a prompt"
          />
          <button
            disabled={inputValue.trim().length === 0 || disable}
            type="submit"
            className={`input-field__button ${
              disable ? "cursor-not-allowed" : ""
            }`}
            data-testid="submit-button"
          >
            <img
              src={icons.sendMessageIcon}
              className={`input-icon ${disable ? "cursor-not-allowed" : ""}`}
              alt=""
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatTextInput;
