import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Spin,
  Tooltip,
  message,
} from "antd";
import Modal from "antd/es/modal/Modal";
import { icons } from "assets";
import ProgressBar from "component/SmartTutor/ProgressBar/ProgressBar";
import {
  formatDate,
  getHtmlContent,
  getLoginUserEmail,
  getPluralNoun,
  getTagColor,
  removeEmptySpacesFromHtmlText,
  removeHtmlTag,
} from "lib/Helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useLazyQueryData, useMutationData } from "services";
import {
  CREATE_USER_COURSE_RELATION,
  DELETE_USER_COURSE_RELATION,
} from "services/Mutation";
import {
  COURSE_DETAILS,
  GENERATE_URI,
  GET_COURSES_FOR_LOGGEDIN_USER,
} from "services/Queries";
import Breadcrumb from "component/Breadcrumb/Breadcrumb";
import "./CourseDetails.scss";

interface ICourseData {
  id: string;
  description: string;
  name: string;
  duration: number;
  githubId: string | null;
  noOfAssessments: number;
  totalNoOfMiniAssessment: number;
  noOfQuizzes: number;
  nextEnrollmentDate: string | null;
  noOfSeatsLeft: number;
  totalSeats: number;
  prerequisite: string;
  usersEnrolled: number;
  lastUpdate: string;
  currentUserCourseStatus: string;
  isUserEnrolled: boolean;
  whatYouWillLearn: string;
  courseLink: string;
  courseBanner: string;
  validTo: string;
  validFrom: string;
  userCourseRelationData: {
    isEnrolled: boolean;
    createdDate: string;
    courseStatus: string;
    certificateObjName: string;
  };
}

const CourseDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const userEmail = getLoginUserEmail();
  const [courseData, setCourseData] = useState<ICourseData | null>(null);
  const [confirmationModalIsOpen, setconfirmationModalIsOpen] = useState(false);
  const [banner, setBanner] = useState(null);
  const userId = localStorage.getItem("userId");
  const [progress, setProgess] = useState<any>(null);

  // Queries
  const [
    getCourseDetails,
    { data: courseDetailsResp, loading: courseDetailsLoading },
  ] = useLazyQueryData(COURSE_DETAILS, {
    courseId: courseId,
    userEmail: userEmail,
  });

  const [
    fetchAllCoursesForUser,
    { data: userCourseResp, loading: userCourseLoading },
  ] = useLazyQueryData(GET_COURSES_FOR_LOGGEDIN_USER, {
    userId: userId,
  });

  const [
    getGenerateUri,
    { data: generateUriResp, loading: generateUriLoading },
  ] = useLazyQueryData(GENERATE_URI, {
    blobPath: courseData?.courseBanner,
  });

  useEffect(() => {
    if (generateUriResp?.generateSasGetUri) {
      setBanner(generateUriResp?.generateSasGetUri?.url);
    }
  }, [generateUriResp]);

  useEffect(() => {
    if (userCourseResp) {
      console.log("coursesLogged", userCourseResp?.coursesForLoggedInUser);

      const courseDetailData = userCourseResp?.coursesForLoggedInUser?.find(
        (item) => {
          if (item?.course?.id === courseId) {
            return item;
          }
        }
      );

      setProgess(
        Math.floor(
          ((courseDetailData?.completedLessonCount +
            courseDetailData?.attemptedQuizCount +
            courseDetailData?.completedMiniAssessmentCount +
            courseDetailData?.completedAssessmentCount) *
            100) /
            (courseDetailData?.totalLessonCount +
              courseDetailData?.totalQuizCount +
              courseDetailData?.totalAssessmentCount +
              courseDetailData?.totalMiniAssessmentCount)
        )
      );
    }
  }, [userCourseResp]);

  const breadcrumbItems = [
    {
      label: "Dashboard",
      className: "breadcrumb-parent",
      onClick: () => navigate("/"),
    },
    {
      label: "Recommended Course",
      className: "breadcrumb-child",
    },
  ];

  useEffect(() => {
    getCourseDetails();
  }, [getCourseDetails]);

  useEffect(() => {
    fetchAllCoursesForUser();
  }, []);

  useEffect(() => {
    if (courseData) {
      getGenerateUri();
    }
  }, [courseData]);

  useEffect(() => {
    const courseDetails = courseDetailsResp?.courseDetails;
    console.log("courseDetails", courseDetails);
    setCourseData((prev) => ({
      ...prev,
      id: courseDetails?.id,
      description: courseDetails?.courseDetails,
      name: courseDetails?.title,
      duration: courseDetails?.duration,
      githubId: courseDetails?.githubTeam,
      noOfAssessments: courseDetails?.noOfAssessments,
      totalNoOfMiniAssessment: courseDetails?.totalNoOfMiniAssessment,
      noOfQuizzes: courseDetails?.noOfQuizzes,
      nextEnrollmentDate: formatDate(
        courseDetails?.noOfSeatsLeftAndNextEnrollmentDate.next_enrollment_date,
        "DD MMM, YYYY"
      ),
      noOfSeatsLeft:
        courseDetails?.noOfSeatsLeftAndNextEnrollmentDate.no_of_seats_left,
      totalSeats: courseDetails?.maxEnrollments,
      prerequisite: courseDetails?.prerequisite,
      usersEnrolled: courseDetails?.totalEnrollments,
      lastUpdate: formatDate(courseDetails?.updatedAt, "DD MMM, YYYY"),
      currentUserCourseStatus:
        courseDetails?.userCourseRelationData.courseStatus,
      isUserEnrolled: courseDetails?.userCourseRelationData.isEnrolled,
      whatYouWillLearn: courseDetails?.whatYouWillLearn,
      courseLink: "/coursecontent/" + courseDetails?.id,
      courseBanner: courseDetails?.courseBanner || "",
      validTo: courseDetails?.validTo || "",
      validFrom: courseDetails?.validFrom || "",
      userCourseRelationData: courseDetails?.userCourseRelationData || {},
    }));
  }, [courseDetailsResp]);

  //=================Mutations================
  const [userCourseRelData, { loading: userCourseRelLoading }] =
    useMutationData(CREATE_USER_COURSE_RELATION);
  const [deleteUserCourseRelDate] = useMutationData(
    DELETE_USER_COURSE_RELATION
  );

  const getSideButtons = () => {
    const isEnrollDisabled =
      courseData?.isUserEnrolled || courseData?.noOfSeatsLeft === 0;

    const handleViewCourseDetails = () => {
      if (progress === 100) {
        navigate(`/coursedetails/coursecontent/${courseData?.id}`, {
          state: { data: "SHOW_BADGE" },
        });
      } else {
        navigate(`/coursedetails/coursecontent/${courseData?.id}`);
      }
    };

    return !courseData?.isUserEnrolled ? (
      <>
        {console.log("entering")}
        <Button
          type="primary"
          className={`enroll-btn ${isEnrollDisabled && "enrolled"}`}
          disabled={isEnrollDisabled}
          onClick={() => setconfirmationModalIsOpen(true)}
        >
          Enroll Now
        </Button>
      </>
    ) : (
      <Row gutter={24} align="middle" className="detail-buttons">
        <Col span={12}>
          <Tooltip title="View Course Details">
            <Button
              type="primary"
              className="course-link"
              onClick={() => handleViewCourseDetails()}
              disabled={!courseData?.courseLink}
            >
              View Course Details
            </Button>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip title="Unenroll">
            <Button
              type="default"
              className="course-unenroll"
              onClick={() => setconfirmationModalIsOpen(true)}
            >
              Unenroll
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  };

  /**
   * function getDescription - renders desciption for a course
   */
  const getDescription = (description: string = "") => {
    return description ? (
      <>
        <div
          className="body-1"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      </>
    ) : (
      <Empty
        className="m-auto mt-12"
        image={icons.emptyEnrolledCourse}
        description="No course description"
      />
    );
  };

  const getFormattedDate = (
    courseDuration?: number,
    isEnrolled?: boolean,
    userEnrolledDate?: string
  ): string => {
    const options = { month: "short", year: "numeric" };
    const startDateString = isEnrolled
      ? userEnrolledDate
      : new Date().toISOString();
    const startDate = new Date(startDateString ?? 0);

    startDate.setDate(startDate.getDate() + (courseDuration || 0));
    const formattedDate = new Date(startDate).toLocaleDateString(
      "en-US",
      options as Intl.DateTimeFormatOptions
    );
    const day = new Date(startDate).getDate().toString().padStart(2, "0");

    return `${day}, ${formattedDate}`;
  };

  const handleEnrollClick = () => {
    userCourseRelData({
      variables: {
        courseId: courseData?.id,
        orgCognitoId: "9ebbef0e-6d07-4db8-8f0b-132ced05cf88",
      },
    }).then(({ data }) => {
      if (data?.createUserCourseRelation?.ok) {
        navigate(`/coursedetails/coursecontent/${courseData?.id}`);
      } else {
        message.error(data?.createUserCourseRelation?.message);
        setconfirmationModalIsOpen(false);
      }
    });
  };

  const handleUnenrollClick = () => {
    deleteUserCourseRelDate({
      variables: { courseId: courseId },
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      {courseDetailsLoading || userCourseRelLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <section className="course-details-container">
          <Row gutter={[24, 24]}>
            <Col>
              <Breadcrumb items={breadcrumbItems} />
              <Divider className="breadcrumb-divider m-0 mt-12" />
            </Col>
          </Row>

          <Row gutter={[36, 36]} className="mt-20">
            <Col xs={{ span: 24 }} lg={{ span: 16 }}>
              <Card className="course-details-card">
                <Row
                  className="flex flex--align-center"
                  justify={"space-between"}
                >
                  <Col>
                    <span className="header-5 capitalize">
                      {courseData?.name}
                    </span>
                  </Col>

                  <Col>
                    <Row className="flex flex--align-center progress-wrapper">
                      {/* <Col className="progress-bar mr-8">
                        {courseData?.userCourseRelationData?.isEnrolled && (
                          <ProgressBar
                            courseDuration={courseData?.duration}
                            userEnrolledDate={
                              courseData?.userCourseRelationData?.createdDate
                            }
                          />
                        )}
                      </Col> */}

                      <Col>
                        <Row>
                          <span className="info-deadline">
                            Deadline:{" "}
                            <span className="font-weight-bold">
                              {getFormattedDate(
                                courseData?.duration,
                                courseData?.userCourseRelationData?.isEnrolled,
                                courseData?.userCourseRelationData?.createdDate
                              )}
                            </span>
                          </span>
                        </Row>

                        {courseData && !courseData?.isUserEnrolled && (
                          <Row className="mt-4">
                            <span
                              style={{
                                color: `${getTagColor(
                                  courseData?.noOfSeatsLeft,
                                  courseData?.totalSeats
                                )}`,
                              }}
                            >
                              Seats Left:{" "}
                              <span className="seats-left-span">
                                {courseData?.noOfSeatsLeft ?? 0} of{" "}
                                {courseData?.totalSeats ?? 0}
                              </span>
                            </span>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Divider className="divider m-0 mt-16" />
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={{ span: 24 }}>
                    <div className="img-container">
                      {generateUriLoading ? (
                        <div className="loading-spinner-course-det">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <img src={banner || undefined} alt="" />
                      )}
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }}>
                    {getDescription(courseData?.description || "")}
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Row gutter={[36, 36]}>
                <Col xs={{ span: 24 }}>
                  <Card className="course-details-card">
                    <Row gutter={[8, 16]}>
                      <Col xs={{ span: 24 }} className="header-5">
                        This course includes
                      </Col>

                      <Col xs={{ span: 24 }} className="info body-1">
                        <img
                          src={icons.computerIcon}
                          alt="lab"
                          className="mr-16"
                        />
                        {(courseData?.noOfAssessments ?? 0) +
                          (courseData?.totalNoOfMiniAssessment ?? 0)}{" "}
                        Technical Assessment
                        {getPluralNoun(courseData?.noOfAssessments)}
                      </Col>
                      <Col xs={{ span: 24 }} className="info body-1">
                        <img
                          src={icons.bulbOutlined}
                          alt="quiz"
                          className="mr-16"
                        />
                        {courseData?.noOfQuizzes} Quiz Assessment
                        {getPluralNoun(courseData?.noOfQuizzes)}
                      </Col>

                      <Col xs={{ span: 24 }} className="body-1">
                        {getSideButtons()}
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col xs={{ span: 24 }}>
                  {removeEmptySpacesFromHtmlText(
                    removeHtmlTag(courseData?.prerequisite)
                  )?.length > 0 && (
                    <Card className="course-details-card">
                      <span className="header-5">Prerequisite</span>
                      <Divider className="divider m-0 mt-8" />
                      {getHtmlContent(courseData?.prerequisite)}
                    </Card>
                  )}
                </Col>
              </Row>
            </Col>

            <Col xs={{ span: 24 }}>
              <Card className="course-details-card">
                <span className="header-5">What you'll learn</span>
                <Divider className="divider m-0 mt-8" />
                {getHtmlContent(courseData?.whatYouWillLearn)}
              </Card>
            </Col>
          </Row>

          <Modal
            title={
              courseData?.userCourseRelationData?.isEnrolled
                ? "Are you sure you want to unenroll to this course?"
                : "Are you sure you want to enroll to this course?"
            }
            visible={confirmationModalIsOpen}
            onCancel={() => setconfirmationModalIsOpen(false)}
            footer={[
              <Button
                key="no"
                onClick={() => setconfirmationModalIsOpen(false)}
              >
                No
              </Button>,
              <Button
                key="yes"
                type="primary"
                onClick={
                  courseData?.userCourseRelationData?.isEnrolled
                    ? handleUnenrollClick
                    : handleEnrollClick
                }
              >
                Yes
              </Button>,
            ]}
          />
        </section>
      )}
    </>
  );
};

export default CourseDetails;
