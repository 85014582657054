import React from "react";
import { Breadcrumb } from "antd";
import "./Breadcrumb.scss";

const BreadcrumbComponent = ({ items }) => {
  return (
    <Breadcrumb separator=" > ">
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          <span
            className={`${item.className} breadcrumb-label`}
            onClick={item.onClick}
          >
            {item.label}
          </span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
