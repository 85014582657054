import React from "react";
import { icons } from "assets";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./ChatHeader.scss";

const ChatHeader = ({ setShowContent }) => {
  return (
    <div className="chat-box-start px-14 py-18">
      <div className="chat-bot-text">
        <img src={icons.smartTutorLogo} className="st-logo" alt="st-logo" />
        <span className="stick-chat-heading header-5 chat-header">
          Deloitte Super Tutor
        </span>
      </div>
      <span
        onMouseEnter={() => setShowContent(true)}
        onMouseLeave={() => setShowContent(false)}
        className="header-5"
      >
        <InfoCircleOutlined />
      </span>
    </div>
  );
};

export default ChatHeader;
