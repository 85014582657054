import { v4 as uuidv4 } from "uuid";

export const getRoleFromObject = (obj) => {
  return obj.user === "chatbot" ? "system" : obj.user || obj.role;
};

export const getLastFourMessages = (messageToOpenAI, messageLength = 4) => {
  if (messageToOpenAI.length > messageLength) {
    messageToOpenAI = messageToOpenAI?.slice(-messageLength);
  }
  return messageToOpenAI;
};

export const getFetchRequestContent = async (conversationId, payload) => {
  return await fetch(
    `${process.env.REACT_APP_BOT_URL}/conversation/${conversationId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + process.env.REACT_APP_DELOITTE_CHAT_TOKEN,
      },
      body: JSON.stringify(payload),
    }
  );

};

export const getJSONValue = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return;
  }
};

export function handleChunk(
  { done, value },
  setResponses,
  responsesNew,
  fileContentBuffer,
  messageId,
  actionType,
  setMessageId,
  setPrevMessageId,
  setConversationIsNew,
  reader
) {
  if (done) {
    setResponses([
      ...responsesNew,
      {
        user: "chatbot",
        message: fileContentBuffer,
      },
    ]);
    return;
  }
  let newData = new TextDecoder().decode(value);
  const parsedData = getJSONValue(newData);
  if (parsedData) {
    typeof(actionType) === "undefined" && setPrevMessageId(messageId);
    typeof(actionType) === "undefined" ? setMessageId(uuidv4()) : setMessageId(messageId); 
    setConversationIsNew(false);
    fileContentBuffer = fileContentBuffer + JSON.parse(newData)?.message;
  }
  setResponses([
    ...responsesNew,
    {
      user: "chatbot",
      message: fileContentBuffer,
    },
  ]);
  return reader
    .read()
    .then(({ done, value }) =>
      handleChunk(
        { done, value },
        setResponses,
        responsesNew,
        fileContentBuffer,
        messageId,
        actionType,
        setMessageId,
        setPrevMessageId,
        setConversationIsNew,
        reader
      )
    );
}
