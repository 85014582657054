// import "highlight.js/styles/stackoverflow-dark.css";
import Linkify from "react-linkify";
import { checkForMarkdown } from "../SmartTutor.helper";
import "./MessageBox.scss";

const MessageBox = ({ messageObject }) => {
  const { user } = messageObject;

  return (
    <div className="message_box-container" data-testid="responses-container">
      <div
        className={`message_box response-box-${
          user === "chatbot" ? "bot" : "user"
        }`}
      >
        <div className="response_message-box">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <span className="response_message-content">
              {checkForMarkdown(messageObject.message)}
            </span>
          </Linkify>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
