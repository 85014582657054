/* eslint-disable react-hooks/exhaustive-deps */

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Row,
  Spin,
  TabsProps,
  Tooltip,
  Typography,
  message,
} from "antd";
import { icons } from "assets";
import Breadcrumb from "component/Breadcrumb/Breadcrumb";
import SmartTutor from "component/SmartTutor/SmartTutor";
import { PYTHON_PROMPT } from "lib/Constants";
import { getHtmlContent, getLoginUserEmail } from "lib/Helper";
import cloneDeep from "lodash/cloneDeep";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQueryData, useMutationData } from "services";
import {
  CREATE_CERTIFICATE,
  CREATE_USER_ASSESSMENT_RELATION,
  CREATE_USER_QUIZ_RELATION,
  UPDATE_USER_COURSE_RELATION,
  UPDATE_USER_LESSON_RELATION,
} from "services/Mutation";
import {
  COURSE_MINIMUM_DETAILS,
  GENERATE_URI,
  GET_COMPLETION_STATUS,
  GET_COMPLETION_STATUS_FOR_BADGES,
  GET_MINI_ASSESSMENT_QUESTION_DETAILS,
  GET_MODULE_DETAILS,
} from "services/Queries";
import "./CourseContent.scss";

import { SUMMARY_NOT_AVAILABLE } from "./CourseContentConstant";

import UserAssessment from "component/UserAssessment/UserAssessment";
import Confetti from "react-confetti";
import Quiz from "pages/Quiz/Quiz";

const logoFile = {
  video: icons?.videoLogo,
  pdf: icons?.pdfLogo,
  "application/pdf": icons?.pdfLogo,
  quiz: icons?.quizLogo,
  ppt: icons?.pptLogo,
};

const { Panel } = Collapse;
const { Text, Title } = Typography;

interface ICourseData {
  id: string;
  name: string;
  title: string;
  description: string;
  moduleProgressId: string;
  totalNoOfTasks: number;
  totalNoOfTasksCompleted: number;
}

const CourseContent = (): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const { id: courseId } = useParams();
  const [moduleData, setModuleData] = useState<any>([]);
  const [quizActiveKeys, setQuizActiveKeys] = useState<string[]>([]);
  const userEmail = getLoginUserEmail();
  const [contentType, setContentType] = useState<any>(null);
  const [contentThumbnail, setContentThumbnail] = useState("");
  const [contentLink, setContentLink] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const courseContentSection = useRef<HTMLDivElement | null>(null);
  const [courseData, setCourseData] = useState<ICourseData | null>(null);
  const [modulePopupOpen, setModulePopupVisibility] = useState(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disablePrev, setDisablePrev] = useState<boolean>(true);
  const [loadFail, setLoadFail] = useState<boolean>(true);
  const [questionEmpty, setquestionEmpty] = useState<boolean>(false);
  const [currentPythonQuestions, setCurrentPythonQuestions] = useState<
    string[][]
  >([]);
  const [currentSummary, setCurrentSummary] = useState<string[]>([]);
  const [sectionItemClicked, setsectionItemClicked] = useState<any>({});
  const [selectedQuiz, setSelectedQuiz] = useState<any>();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [moduleDetailsData, setModuleDetailsData] = useState<any>();
  const [prevItemId, setPrevItemId] = useState<string>();
  const [currItemId, setCurrItemId] = useState<string>();
  const [prevType, setPrevType] = useState<String>();
  const [currType, setCurrType] = useState<String>();
  const [isInside, setIsInside] = useState(false);
  const [notStartedLesson, setNotStartedLesson] = useState<any>(null);
  const [moduleNumber, setModuleNumber] = useState(null);
  const [isLessonClicked, setIsLessonClicked] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [currQuizId, setCurrQuizId] = useState<any>(null);
  const [clickedLessonCheck, setClickedLessonCheck] = useState(false);
  const [assessmentClicked, setAssessmentClicked] = useState(false);
  const [quizClicked, setQuizClicked] = useState(false);

  const [activeContent, setActiveContent] = useState("BOT");
  const [currMiniAssessmentId, setCurrMiniAssessmentId] = useState<any>();
  const [evaluationCriteria, setEvaluationCriteria] = useState("");
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [type, setType] = useState("");
  const [userAnswers, setUserAnswers] = useState<Array<any>>(
    new Array(assessmentQuestions?.length).fill("")
  );
  const [userScores, setUserScores] = useState<Array<any>>(
    new Array(assessmentQuestions?.length).fill("-")
  );
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [bookmark, setBookmark] = useState(1);
  const [badgeImage, setBadgeImage] = useState<any>(null);
  const [certPdf, setCertPdf] = useState<any>(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [badgePath, setBadgePath] = useState(null);

  //userAssessment State
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isBadgeClicked, setIsBadgeClicked] = useState(false);

  const [runModuleResp, setRunModuleResp] = useState(0);
  const [visitedCount, setVisitedCount] = useState(false);
  const [showCert, setShowCert] = useState(false);
  const [certPath, setCertPath] = useState<any>(null);
  const [incNumber, setIncNumber] = useState(0);
  const [isAssessmentResultLoading, setIsAssessmentResultLoading] =
    useState(false);

  function capitalizeFirstLetter(sentence) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  //quiz
  const [quizDetails, setQuizDetails] = useState<any>();
  const [quizAnswerDetails, setQuizAnswerDetails] = useState<any>();
  const [quizSavedDetails, setQuizSavedDetails] = useState<any>();
  const [currentQuizQuestionIndex, setCurrentQuizQuestionIndex] = useState(0);

  const [userQuizAnswers, setUserQuizAnswers] = useState<Array<any>>(
    new Array(quizDetails?.questionSet?.length).fill("")
  );
  const [progress, setProgress] = useState<number>(0);
  const [quizLoading, setQuizLoading] = useState(true);
  const [certLink, setCertLink] = useState(null);
  const [certDoc, setCertDoc] = useState<any>(null);

  const [
    getGenerateUri,
    { data: generateUriResp, loading: generateUriLoading },
  ] = useLazyQueryData(GENERATE_URI, {
    blobPath: contentLink,
  });

  const [
    getGenerateUriForBadge,
    { data: generateUriForBadgeResp, loading: generateUriForBadgeLoading },
  ] = useLazyQueryData(GENERATE_URI, {
    blobPath: badgePath,
  });

  const [
    getGenerateUriForCert,
    { data: getGenerateUriForCertResp, loading: getGenerateUriForCertLoading },
  ] = useLazyQueryData(GENERATE_URI, {
    blobPath: certPath,
  });

  // =====Mutations=====
  const [userLessonRelData] = useMutationData(UPDATE_USER_LESSON_RELATION);
  const [userQuizRelData] = useMutationData(CREATE_USER_QUIZ_RELATION);
  const [userAssessmentRelData] = useMutationData(
    CREATE_USER_ASSESSMENT_RELATION
  );
  const [createCertificateRelData] = useMutationData(CREATE_CERTIFICATE);
  // const [assessmentStatus, setAssessmentStatus] = useState("");
  const [currMiniAssessmentRelation, setCurrMiniAssessmentRelation] = useState(
    {}
  );
  const [updateUserCourseRelation] = useMutationData(
    UPDATE_USER_COURSE_RELATION
  );

  const breadcrumbItems = [
    {
      label: "Dashboard",
      className: "breadcrumb-parent",
      onClick: () => navigate("/"),
    },
    {
      label: "Recommended Course",
      className: "breadcrumb-parent",
      onClick: () => navigate(`/coursedetails/${courseData?.id}`),
    },
    {
      label: "Course Content",
      className: "breadcrumb-child",
    },
  ];

  // setting usestates for pdfjs
  const pdfjsLib: any = require("pdfjs-dist/legacy/build/pdf.js");
  const [pdfDoc, setPdfDoc] = useState<any>(null);
  const [numberPages, setNumberPages] = useState<any>(null);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageIsRendering, setPageIsRendering] = useState<boolean>(false);
  const [pageNumIsPending, setPageNumIsPending] = useState<number | null>(null);
  const [moduleDetailsLength, setModuleDetailsLength] = useState<any>(null);
  const [lastModuleMapperLength, setLastModuleMapperLength] = useState(0);
  const [diffIndex, setDiffIndex] = useState(0);
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const scale: number = 1.5;

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const [disableFinal, setDisableFinal] = useState(false);
  const [disableBadge, setDisableBadge] = useState(false);

  // Update the windowWidth state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   setRefreshKey(refreshKey + 1);
  // }, [userQuizAnswers]);

  const findAnswerStatus = (id) => {
    for (let i = 0; i < quizAnswerDetails.length; i++) {
      if (quizAnswerDetails[i].question.id === id) {
        for (let k = 0; k < quizDetails.questionSet.length; k++) {
          if (id === quizDetails.questionSet[k].id) {
            for (
              let j = 0;
              j < quizDetails.questionSet[k].choiceSet.length;
              j++
            ) {
              if (
                quizDetails.questionSet[k].choiceSet[j].id ===
                  quizAnswerDetails[i].choice.id &&
                quizDetails.questionSet[k].choiceSet[j].isRightChoice
              ) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };

  const returnUserAnswers = (index) => {
    for (let j = 0; j < quizAnswerDetails?.length; j++) {
      if (
        quizDetails.questionSet[index].id === quizAnswerDetails[j].question.id
      ) {
        for (
          let k = 0;
          k < quizDetails.questionSet[index].choiceSet.length;
          k++
        ) {
          if (
            quizDetails.questionSet[index].choiceSet[k].id ===
            quizAnswerDetails[j].choice.id
          ) {
            return [quizAnswerDetails[j].choice.id];
          }
        }
      }
    }
    return undefined;
  };

  const calculateScore = () => {
    let score = 0;
    for (let i = 0; i < quizAnswerDetails.length; i++) {
      let id = quizAnswerDetails[i].question.id;
      for (let k = 0; k < quizDetails.questionSet.length; k++) {
        if (id === quizDetails.questionSet[k].id) {
          for (
            let j = 0;
            j < quizDetails.questionSet[k].choiceSet.length;
            j++
          ) {
            if (
              quizDetails.questionSet[k].choiceSet[j].id ===
                quizAnswerDetails[i].choice.id &&
              quizDetails.questionSet[k].choiceSet[j].isRightChoice
            ) {
              score += quizDetails.questionSet[k].score;
            }
          }
        }
      }
    }

    return `${score}/${quizDetails.totalScore}`;
  };

  const getAttemptedQuizStyles = (index, id) => {
    if (returnUserAnswers(index)) {
      if (findAnswerStatus(id)) {
        return {
          color: "#237804",
        };
      } else {
        return {
          color: "#F5222D",
        };
      }
    } else {
      return {
        color: "#AD8B00",
      };
    }
  };

  function handleLessonClick(section: any) {
    const {
      contentType,
      contentThumbnail,
      contentLink,
      suggestiveQuestions,
      summary,
    } = section;
    if (!isBadgeClicked) {
      setContentType(contentType);
      setContentThumbnail(contentThumbnail);
      setContentLink(contentLink);
      setClickedLessonCheck(true);
    }

    if (contentType === "pdf" || contentType === "application/pdf") {
      getGenerateUri();
      if (summary) {
        const parsedSummary = JSON.parse(summary);
        const updatedSummary = parsedSummary.map((inner) => {
          if (inner.length === 0 || (inner.length === 1 && inner[0] === "")) {
            return [SUMMARY_NOT_AVAILABLE];
          }

          return inner;
        });
        setCurrentSummary(updatedSummary);
      } else {
        setCurrentSummary([SUMMARY_NOT_AVAILABLE]);
      }

      if (suggestiveQuestions) {
        setCurrentPythonQuestions(JSON.parse(suggestiveQuestions));
      } else {
        setquestionEmpty(true);
      }
    }

    if (courseContentSection && courseContentSection.current)
      courseContentSection?.current?.scrollIntoView();
    handlePopoverVisibleChange(false);
  }
  function getTotalModuleDuration(modulesList: any) {
    return modulesList?.reduce((accumulator: any, current: any) => {
      if (current?.lesson) {
        return accumulator + current?.lesson?.duration;
      } else return 0;
    }, 0);
  }

  const getHeader = (module: any, index: any) => {
    const totalModuleDuration = getTotalModuleDuration(module?.moduleMapper);
    return (
      <div className="header-container">
        <span className="header-title">
          Section {index + 1}: {module?.module?.moduleName}
        </span>
      </div>
    );
  };
  const handlePopoverVisibleChange = (visible: boolean) => {
    setModulePopupVisibility(visible);
  };
  const handleClose = () => {
    window.location.reload();
  };

  const renderBorderLeftForLesson = (id: any) => {
    const userLesson =
      moduleDetailsData?.getAllModulesDetails?.userLessonRelation.find(
        (item) => {
          return item.lesson && item.lesson.id === id;
        }
      );

    if (userLesson) {
      const status = userLesson?.status;

      if (status === "NOT_STARTED") {
        return false;
      } else if (status === "COMPLETED") {
        return true;
      }
    }
  };

  const renderBorderLeftForQuiz = (id: any) => {
    const userQuiz =
      moduleDetailsData?.getAllModulesDetails?.userQuizRelation.find((item) => {
        return item.quiz && item.quiz.id === id;
      });

    if (userQuiz) {
      const status = userQuiz?.status;

      if (status === "COMPLETED") {
        return 0;
      } else if (status === "IN_PROGRESS") {
        return 1;
      }else if (status === "NOT_STARTED"){
        return 2;
      }
    }
  };

  const getQuizStartedStatus = (id: any) => {
    const userQuiz =
      moduleDetailsData?.getAllModulesDetails?.userQuizRelation.find((item) => {
        return item.quiz && item.quiz.id === id;
      });

    if (userQuiz) {
      const status = userQuiz?.status;

      if (status === "NOT_STARTED") {
        return false;
      } else if (status === "IN_PROGRESS") {
        return true;
      }
    }
  };

  useEffect(() => {
    const arr = assessmentQuestions;
    setAssessmentQuestions(arr);
  }, [userAnswers]);

  useEffect(() => {
    if (generateUriResp?.generateSasGetUri && clickedLessonCheck) {
      setContentLink(generateUriResp?.generateSasGetUri?.url);
      setClickedLessonCheck(false);
    }
  }, [generateUriResp]);

  useEffect(() => {
    if (generateUriForBadgeResp?.generateSasGetUri) {
      setBadgeImage(generateUriForBadgeResp?.generateSasGetUri?.url);
    }
  }, [generateUriForBadgeResp]);

  useEffect(() => {
    if (getGenerateUriForCertResp?.generateSasGetUri) {
      setCertPdf(getGenerateUriForCertResp?.generateSasGetUri?.url);
    }
  }, [getGenerateUriForCertResp]);

  useEffect(() => {
    if (certPdf) {
      setCertLink(certPdf);
    }
  }, [certPdf]);

  useEffect(() => {
    if(certLink) {
      setPageNum(1);
      setPageIsRendering(false);
      setPageNumIsPending(null);
      pdfjsLib
        .getDocument(certLink)
        .promise.then((pdfDoc_: any) => {
          setCertDoc(pdfDoc_);
          setNumberPages(pdfDoc_.numPages);
          if (pageNum <= 1) {
            setDisablePrev(true);
          } else {
            setDisablePrev(false);
          }

          setCurrentIndex(0);
          if (pdfDoc_.numPages <= 1) {
            setDisableNext(true);
          } else {
            setDisableNext(false);
          }
          setLoadFail(false);
        })
        .catch((err: any) => {
          setLoadFail(true);
        });
    }
  }, [certLink]);

  useEffect(() => {
    if(certDoc) {
      setPageIsRendering(true);
      certDoc.getPage(1).then((page: any) => {
        const viewport = page.getViewport({ scale });
        const canvas: HTMLCanvasElement | null =
          document.querySelector("#certificate-render");
        if (canvas) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const ctx = canvas.getContext("2d");
          const renderCtx = {
            canvasContext: ctx,
            viewport,
          };
          // Clear the canvas before rendering a new page
          ctx?.clearRect(0, 0, canvas.width, canvas.height);
          page.render(renderCtx).promise.then(() => {
            setPageIsRendering(false);
            if (pageNumIsPending !== null) {
              renderPage(pageNumIsPending);
              setPageNumIsPending(null);
            }
            setCurrentIndex(0);
          });
        }
      });
    }
  }, [certDoc]);

  const onChange = (key: string) => {};

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: assessmentClicked ? "Evalutation Criteria" : "Summary",
      children: (
        <>
          {loadFail ? (
            <div className="spin-container">
              <Spin size="large" />
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  contentType === "quiz" ||
                  (contentType === "miniAssessment" && !assessmentClicked)
                    ? SUMMARY_NOT_AVAILABLE
                    : contentType === "miniAssessment" && assessmentClicked
                    ? evaluationCriteria
                    : currentSummary[currentIndex],
              }}
              className="current-summary-content"
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (prevItemId) {
      userLessonRelData({
        variables: {
          lessonId: prevItemId,
          courseId: courseId,
          status: "COMPLETED",
        },
      }).then(() => {});
    }

    if (prevItemId && moduleDetailsData) {
      const updatedModuleDetailsData: any = cloneDeep(moduleDetailsData);
      const prevLesson =
        updatedModuleDetailsData?.getAllModulesDetails?.userLessonRelation.find(
          (item) => {
            return item?.lesson && item?.lesson?.id === prevItemId;
          }
        );

      if (prevLesson) {
        prevLesson.status = "COMPLETED";
        setModuleDetailsData(updatedModuleDetailsData);
        getCompletionStatus();
        getCompletionStatusForBadges();
      }
    }
  }, [currItemId]);

  useEffect(() => {
    if (prevType === "LESSON") {
      if (prevItemId) {
        userLessonRelData({
          variables: {
            lessonId: prevItemId,
            courseId: courseId,
            status: "COMPLETED",
          },
        }).then(() => {});
      }

      if (prevItemId && moduleDetailsData) {
        const updatedModuleDetailsData: any = cloneDeep(moduleDetailsData);
        const currLesson =
          updatedModuleDetailsData?.getAllModulesDetails?.userLessonRelation.find(
            (item) => {
              return item?.lesson && item?.lesson?.id === prevItemId;
            }
          );

        if (currLesson) {
          currLesson.status = "COMPLETED";
          setModuleDetailsData(updatedModuleDetailsData);
          getCompletionStatus();
          getCompletionStatusForBadges();
        }
      }
    }
  }, [currType]);
  const getLessonSection = (
    sectionNumber: number,
    lesson: any,
    mNumber: any
  ) => {
    return (
      <div
        onClick={() => {
          setBookmark(1);
          setIsLessonClicked(true);
          setPrevItemId(currItemId);
          setCurrItemId(lesson?.id);
          setPrevType(currType);
          setCurrType("LESSON");
          setIsBadgeClicked(false);
          setsectionItemClicked({
            moduleNumber: mNumber,
            lesson: lesson.lessonName,
          });
          setAssessmentClicked(false);
          setQuizClicked(false);
          setUserAnswers([]);
          setUserScores([]);
          setCurrentQuestionIndex(0);
          setCurrMiniAssessmentId(0);
          getCompletionStatus();
          getCompletionStatusForBadges();
          handleLessonClick(lesson);
          setShowCert(false);

          // Set currentItemIndex and sectionItemIndex when any lesson is clicked.
          const sectionIndex =
            moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.findIndex(
              (moduleDetail) => moduleDetail?.module?.moduleNumber === mNumber
            );
          const lessonIndex =
            moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.[
              sectionIndex
            ]?.moduleMapper.findIndex(
              (moduleMapper) => moduleMapper?.lesson?.id === lesson?.id
            );

          setCurrentSectionIndex(sectionIndex);
          setCurrentItemIndex(lessonIndex);
        }}
      >
        <span className="section-title">
          {sectionNumber}: {capitalizeFirstLetter(lesson?.lessonName)}
        </span>
        <div className="section-info mt-4">
          <img src={logoFile[lesson?.contentType]} alt="content type logo" />
        </div>
      </div>
    );
  };

  const getAssessmentSection = (
    sectionNumber: any,
    assessment: any,
    mNumber: any
  ) => {
    return (
      <Row
        onClick={() => {
          setContentType("miniAssessment");
          setCurrMiniAssessmentId(assessment?.id);
          if (currMiniAssessmentId !== assessment?.id && assessmentClicked) {
            setAssessmentClicked(false);
            setUserAnswers([]);
            setUserScores([]);
            setCurrentQuestionIndex(0);
          }
          setsectionItemClicked({
            moduleNumber: mNumber,
            miniAssessment: assessment?.title,
          });
          setIsBadgeClicked(false);
          getCompletionStatus();
          getCompletionStatusForBadges();
          setShowCert(false);
          setQuizClicked(false);

          const sectionIndex =
            moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.findIndex(
              (moduleDetail) => moduleDetail?.module?.moduleNumber === mNumber
            );
          const lessonIndex =
            moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.[
              sectionIndex
            ]?.moduleMapper.findIndex(
              (moduleMapper) =>
                moduleMapper?.miniAssessment?.id === assessment?.id
            );
          setCurrentSectionIndex(sectionIndex);
          setCurrentItemIndex(lessonIndex);
        }}
      >
        <Col xs={{ span: 18 }} className="section-title">
          {sectionNumber}: {capitalizeFirstLetter(assessment?.title)}
        </Col>
        <Col xs={{ span: 18 }} className="section-info mt-4">
          <img src={icons.techAssignmentIcon} alt="quiz logo" />
        </Col>
      </Row>
    );
  };

  const getQuizSection = (sectionNumber: any, quiz: any, mNumber: any) => {
    return (
      <Row
        onClick={() => {
          setSelectedQuiz(quiz);
          setCurrQuizId(quiz?.id);
          setsectionItemClicked({
            moduleNumber: mNumber,
            quiz: quiz?.title,
          });
          if (currQuizId !== quiz?.id) {
            setQuizClicked(false);
          }
          setContentType("quiz");
          setIsBadgeClicked(false);
          setAssessmentClicked(false);
          setUserAnswers([]);
          setUserScores([]);
          setCurrentQuestionIndex(0);
          setCurrMiniAssessmentId(0);
          getCompletionStatus();
          getCompletionStatusForBadges();
          setShowCert(false);

          // Set currentItemIndex and sectionItemIndex when any quiz is clicked.
          const sectionIndex =
            moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.findIndex(
              (moduleDetail) => moduleDetail?.module?.moduleNumber === mNumber
            );
          const lessonIndex =
            moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.[
              sectionIndex
            ]?.moduleMapper.findIndex(
              (moduleMapper) => moduleMapper?.quiz?.id === quiz?.id
            );
          setCurrentSectionIndex(sectionIndex);
          setCurrentItemIndex(lessonIndex);
        }}
      >
        <Col xs={{ span: 18 }} className="section-title">
          {sectionNumber}: {capitalizeFirstLetter(quiz?.title)}
        </Col>
        <Col xs={{ span: 18 }} className="section-info mt-4">
          <img src={icons.quizLogo} alt="quiz logo" />
        </Col>
      </Row>
    );
  };

  const [
    getCourseDetails,
    { data: courseDetailsResp, loading: courseDetailsLoading },
  ] = useLazyQueryData(COURSE_MINIMUM_DETAILS, {
    courseId: courseId,
    userEmail: userEmail,
  });

  const [
    getCompletionStatus,
    { data: completionStatusResp, loading: completionStatusLoading },
  ] = useLazyQueryData(GET_COMPLETION_STATUS, {
    courseId: courseId,
  });

  const [
    getCompletionStatusForBadges,
    {
      data: completionStatusForBadgesResp,
      loading: completionStatusForBadgesLoading,
    },
  ] = useLazyQueryData(GET_COMPLETION_STATUS_FOR_BADGES, {
    courseId: courseId,
    moduleType: "BADGES",
  });

  const [
    getModuleDetails,
    { data: moduleDetailsResp, loading: moduleDetailsLoading },
  ] = useLazyQueryData(GET_MODULE_DETAILS, {
    courseId: courseId,
  });

  const [
    getModuleAssessmentDetails,
    {
      data: moduleAssessmentDetailsResp,
      loading: moduleAssessmentDetailsLoading,
    },
  ] = useLazyQueryData(GET_MINI_ASSESSMENT_QUESTION_DETAILS, {
    courseId: courseId,
  });

  const renderPage = (num: number) => {
    setPageIsRendering(true);
    pdfDoc.getPage(num).then((page: any) => {
      const viewport = page.getViewport({ scale });
      const canvas: HTMLCanvasElement | null =
        document.querySelector("#pdf-render");
      if (canvas) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const ctx = canvas.getContext("2d");
        const renderCtx = {
          canvasContext: ctx,
          viewport,
        };
        // Clear the canvas before rendering a new page
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
        page.render(renderCtx).promise.then(() => {
          setPageIsRendering(false);
          setPageNumIsPending(null);
          setCurrentIndex(num - 1);
        });
      }
    });
    setBookmark(num);
    if (contentType !== "quiz" && contentType !== "miniAssessment") {
      updateUserCourseRelation({
        variables: {
          courseId: courseId,
          updateId: currItemId,
          updateType: "L",
          bookmark: num,
          userEmail: userEmail,
        },
      });
    }
  };

  useEffect(() => {
    if (contentType && prevItemId !== currItemId) {
      let updateType = "L";
      if (contentType == "quiz") {
        updateType = "Q";
      } else if (contentType == "miniAssessment") {
        updateType = "MA";
      }
      updateUserCourseRelation({
        variables: {
          courseId: courseId,
          updateId: currItemId,
          updateType: updateType,
          bookmark: bookmark ?? 1,
          userEmail: userEmail,
        },
      });
    }
  }, [currItemId]);

  const showNextPage = () => {
    let currentPage = pageNum;
    if (currentPage >= numberPages) {
      return;
    }
    if (currentPage === numberPages - 1) {
      setDisableNext(true);
      // if (currItemId && moduleDetailsData) {
      //   const updatedModuleDetailsData: any = cloneDeep(moduleDetailsData);
      //   const currLesson =
      //     updatedModuleDetailsData?.getAllModulesDetails?.userLessonRelation.find(
      //       (item) => {
      //         return item?.lesson && item?.lesson?.id === currItemId;
      //       }
      //     );

      //   if (currLesson) {
      //     currLesson.status = "COMPLETED";
      //     setModuleDetailsData(updatedModuleDetailsData);
      //     userLessonRelData({
      //       variables: {
      //         lessonId: currItemId,
      //         courseId: courseData?.id,
      //         status: "COMPLETED",
      //       },
      //     }).then(() => {});
      //     getCompletionStatus();
      //   }
      // }
    }
    setDisablePrev(false);
    setPageNum(currentPage + 1);
    queueRenderPage(currentPage + 1);
  };
  useEffect(() => {
    if (
      currentPythonQuestions[currentIndex] &&
      currentPythonQuestions[currentIndex][0] === ""
    )
      setquestionEmpty(true);
    else setquestionEmpty(false);
  }, [currentPythonQuestions, currentIndex]);

  const showPrevPage = () => {
    let currentPage = pageNum;
    if (currentPage === 1) {
      return;
    }
    if (currentPage === 2) {
      setDisablePrev(true);
    }
    setDisableNext(false);
    setPageNum(currentPage - 1);
    queueRenderPage(currentPage - 1);
  };

  useEffect(() => {
    setPageNum(bookmark ?? 1);
    setPageIsRendering(false);
    setPageNumIsPending(null);
    pdfjsLib
      .getDocument(contentLink)
      .promise.then((pdfDoc_: any) => {
        setPdfDoc(pdfDoc_);
        setNumberPages(pdfDoc_.numPages);
        if (pageNum <= 1) {
          setDisablePrev(true);
        } else {
          setDisablePrev(false);
        }

        setCurrentIndex(0);
        if (pdfDoc_.numPages <= 1) {
          setDisableNext(true);
        } else {
          setDisableNext(false);
        }
        setLoadFail(false);
      })
      .catch((err: any) => {
        setLoadFail(true);
      });
  }, [contentLink]);

  useEffect(() => {
    if (pdfDoc) {
      renderPage(bookmark ?? 1);
    }
  }, [pdfDoc]);

  useEffect(() => {
    const currentSectionItem =
      moduleData?.[currentSectionIndex]?.moduleMapper?.[currentItemIndex];
    const moduleNumber =
      moduleData?.[currentSectionIndex]?.module?.moduleNumber;

    if (currentSectionItem && currentSectionItem?.lesson) {
      setPrevItemId(currItemId);
      setPrevType(currType);
      setCurrItemId(currentSectionItem?.lesson?.id);
      setCurrType("LESSON");
      handleLessonClick(currentSectionItem?.lesson);
      setsectionItemClicked({
        moduleNumber: moduleNumber,
        lesson: currentSectionItem?.lesson?.lessonName,
      });
    } else if (currentSectionItem && currentSectionItem?.quiz) {
      setPrevItemId(currItemId);
      setPrevType(currType);
      setCurrType("QUIZ");
      setPrevItemId(currItemId);
      setsectionItemClicked({
        moduleNumber: moduleNumber,
        quiz: currentSectionItem?.quiz?.title,
      });
      setSelectedQuiz(currentSectionItem?.quiz);
      setContentType("quiz");
      setCurrItemId(currentSectionItem?.quiz?.id);
    } else {
      setPrevItemId(currItemId);
      setPrevType(currType);
      setCurrType("ASSESSMENT");
      setContentType("miniAssessment");
      setPrevItemId(currItemId);
      setCurrItemId(currentSectionItem?.miniAssessment?.id);
      setsectionItemClicked({
        moduleNumber: moduleNumber,
        miniAssessment: currentSectionItem?.miniAssessment?.title,
      });
      setCurrMiniAssessmentId(currentSectionItem?.miniAssessment?.id);
    }
  }, [currentItemIndex, currentSectionIndex]);

  const queueRenderPage = (num: number) => {
    if (pageIsRendering) {
      setPageNumIsPending(num);
    } else {
      renderPage(num);
    }
  };

  useEffect(() => {
    getCourseDetails();
    getModuleDetails();
    getCompletionStatus();
    getCompletionStatusForBadges();
  }, []);

  useEffect(() => {
    if (completionStatusResp) {
      console.log("complete resp", completionStatusResp);
      setDisableFinal(!completionStatusResp?.getCompletionStatus?.allCompleted);
    }
  }, [completionStatusResp]);

  useEffect(() => {
    if (completionStatusForBadgesResp) {
      console.log(
        "complete completionStatusForBadgesResp",
        completionStatusForBadgesResp
      );
      setDisableBadge(
        completionStatusForBadgesResp?.getCompletionStatus?.allCompleted
      );
    }
  }, [completionStatusForBadgesResp]);

  useEffect(() => {
    if (disableBadge && !visitedCount) {
      getGenerateUriForBadge();
      setVisitedCount(true);
      setContentType("badges");
      setShowConfetti(true);
      setIsBadgeClicked(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 10000);
    }
  }, [disableBadge]);

  useEffect(() => {
    const courseDetails = courseDetailsResp?.courseDetails;
    setCourseData((prev) => ({
      ...prev,
      id: courseDetails?.id,
      name: courseDetails?.courseName,
      title: courseDetails?.title,
      moduleProgressId: courseDetails?.moduleProgress?.module?.id,
      description: courseDetails?.courseDetails,
      totalNoOfTasks: courseDetails?.moduleProgress?.noOfTasks || 0,
      totalNoOfTasksCompleted:
        courseDetails?.moduleProgress?.noOfTasksCompleted || 0,
    }));
    setBookmark(
      Number(
        courseDetailsResp?.courseDetails?.userCourseRelationData?.bookmark
      ) !== 0
        ? Number(
            courseDetailsResp?.courseDetails?.userCourseRelationData?.bookmark
          )
        : 1
    );

    // Finding the first lesson where the status is NOT_STARTED.
    const notStartedLessonObj =
      moduleDetailsResp?.getAllModulesDetails?.userLessonRelation?.find(
        (userLesson) => userLesson?.status === "NOT_STARTED"
      );

    if (
      notStartedLessonObj &&
      !isLessonClicked &&
      moduleDetailsResp &&
      courseDetailsResp
    ) {
      moduleDetailsResp?.getAllModulesDetails?.moduleDetails.forEach(
        (moduleDetail, sectionIndex) => {
          if (
            !notStartedLesson &&
            !moduleNumber &&
            !currentSectionIndex &&
            !currentItemIndex
          ) {
            let correspondingModule: any = null;
            if (
              courseDetailsResp?.courseDetails?.userCourseRelationData
                ?.lastActiveContentType == "L"
            ) {
              correspondingModule = moduleDetail?.moduleMapper?.find(
                (moduleMapper, lessonIndex) => {
                  return (
                    moduleMapper?.lesson?.id ==
                      (courseDetailsResp?.courseDetails?.userCourseRelationData
                        ?.lastActiveContent ??
                    moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                      .moduleMapper[0]?.lesson?.id)
                  );
                }
              );
            } else if (
              courseDetailsResp?.courseDetails?.userCourseRelationData
                ?.lastActiveContentType == "Q"
            ) {
              correspondingModule = moduleDetail?.moduleMapper?.find(
                (moduleMapper, lessonIndex) => {
                  return (
                    moduleMapper?.quiz?.id ==
                      (courseDetailsResp?.courseDetails?.userCourseRelationData
                        ?.lastActiveContent ??
                    moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                      .moduleMapper[0]?.quiz?.id)
                  );
                }
              );
            } else {
              correspondingModule = moduleDetail?.moduleMapper?.find(
                (moduleMapper, lessonIndex) =>
                  moduleMapper?.miniAssessment?.id ==
                    (courseDetailsResp?.courseDetails?.userCourseRelationData
                      ?.lastActiveContent ??
                  moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                    .moduleMapper[0]?.miniAssessment?.id)
              );
            }

            if (correspondingModule) {
              setNotStartedLesson(correspondingModule?.lesson);
              setModuleNumber(moduleDetail?.module?.moduleNumber);
              setCurrentSectionIndex(sectionIndex);
              let lessonIndex =
                moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                  .moduleMapper[0]?.lesson?.id;
              if (
                courseDetailsResp?.courseDetails?.userCourseRelationData
                  ?.lastActiveContentType == "L"
              ) {
                lessonIndex = moduleDetail?.moduleMapper?.findIndex(
                  (moduleMapper) =>
                    moduleMapper?.lesson?.id ==
                      (courseDetailsResp?.courseDetails?.userCourseRelationData
                        ?.lastActiveContent ??
                    moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                      .moduleMapper[0]?.lesson?.id)
                );
              } else if (
                courseDetailsResp?.courseDetails?.userCourseRelationData
                  ?.lastActiveContentType == "Q"
              ) {
                lessonIndex = moduleDetail?.moduleMapper?.findIndex(
                  (moduleMapper) => {
                    return (
                      moduleMapper?.quiz?.id ==
                        (courseDetailsResp?.courseDetails?.userCourseRelationData
                          ?.lastActiveContent ??
                      moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                        .moduleMapper[0]?.quiz?.id)
                    );
                  }
                );
              } else {
                lessonIndex = moduleDetail?.moduleMapper?.findIndex(
                  (moduleMapper) =>
                    moduleMapper?.miniAssessment?.id ==
                      (courseDetailsResp?.courseDetails?.userCourseRelationData
                        ?.lastActiveContent ??
                    moduleDetailsResp?.getAllModulesDetails?.moduleDetails[0]
                      .moduleMapper[0]?.miniAssessment?.id)
                );
              }
              setCurrentItemIndex(lessonIndex);
              return;
            }
          }
        }
      );
    }
  }, [courseDetailsResp, moduleDetailsResp]);

  useEffect(() => {
    if (runModuleResp < 1 && moduleDetailsResp && courseDetailsResp) {
      console.log("module executed");
      const firstModule =
        moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.at(0);
      const firstModuleSection = firstModule?.moduleMapper?.at(0);
      if (firstModuleSection?.lesson) {
        if (state === null) {
          handleLessonClick(firstModuleSection?.lesson);
          setsectionItemClicked({
            moduleNumber: firstModule?.module?.moduleNumber,
            lesson: firstModuleSection?.lesson?.lessonName,
          });
          setCurrItemId(firstModuleSection?.lesson?.id);
          setCurrType("LESSON");
        } else {
          setLoadFail(false);
        }
      }
      const moduleDetailsArray =
        moduleDetailsResp?.getAllModulesDetails?.moduleDetails;
      setModuleData(moduleDetailsArray);
      setModuleDetailsData(moduleDetailsResp);
      setRunModuleResp(runModuleResp + 1);
    }
  }, [moduleDetailsResp, courseDetailsResp]);

  useEffect(() => {
    if (state !== null) {
      setCurrentSummary([SUMMARY_NOT_AVAILABLE]);
    }
  }, []);

  useEffect(() => {
    console.log("currentSummary", currentSummary);
  }, [currentSummary]);

  useEffect(() => {
    setModuleDetailsLength(
      moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.length - 1
    );

    const path = moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.find((module) => {
      return module?.module?.moduleType === "BADGES";
    });

    setBadgePath(path?.module?.badgeLinks[0]);
  }, [moduleDetailsResp]);

  useEffect(() => {
    let len = 0;
    let i = 0;

    if (moduleDetailsLength) {
      while (
        moduleDetailsResp?.getAllModulesDetails?.moduleDetails[
          moduleDetailsLength - i
        ]?.moduleMapper?.length === 0
      ) {
        i++;
        len =
          moduleDetailsResp?.getAllModulesDetails?.moduleDetails[
            moduleDetailsLength - i
          ]?.moduleMapper?.length - 1;
      }
      setLastModuleMapperLength(len);
      setDiffIndex(i);
    }
  }, [moduleDetailsLength, moduleDetailsResp]);

  useEffect(() => {
    if (currQuizId) {
      const quizRelation =
        moduleDetailsResp?.getAllModulesDetails?.userQuizRelation?.find(
          (quiz) => quiz?.quiz?.id === currQuizId
        );

      if (quizRelation) {
        setIsQuizCompleted(quizRelation?.status === "COMPLETED");
      } else {
        setIsQuizCompleted(false);
      }
    }
  }, [currQuizId]);

  const renderLeftBorderForAssessment = (id) => {
    const userMiniAssessmentRelation =
      moduleAssessmentDetailsResp?.fetchUserMiniAssessmentRelation?.find(
        (assessment) => assessment?.miniAssessment?.id === id
      );

    if (userMiniAssessmentRelation) {
      if (userMiniAssessmentRelation?.status === "COMPLETED") {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    console.log("mini assessment id changed");
    getModuleAssessmentDetails();
    const userMiniAssessmentRelation =
      moduleAssessmentDetailsResp?.fetchUserMiniAssessmentRelation?.find(
        (assessment) => assessment?.miniAssessment?.id === currMiniAssessmentId
      );

    if (userMiniAssessmentRelation) {
      if (userMiniAssessmentRelation?.status === "COMPLETED") {
        setAssessmentStatus("COMPLETED");
      } else if (userMiniAssessmentRelation?.status === "IN_PROGRESS") {
        setAssessmentStatus("IN_PROGRESS");
      } else {
        setAssessmentStatus("START");
      }
      setCurrMiniAssessmentRelation(userMiniAssessmentRelation);
    }
  }, [currMiniAssessmentId]);

  const showBadge = () => {
    return (
      <Col className="badge-container">
        {showConfetti && <Confetti numberOfPieces={600} recycle={false} />}
        <h2 className="badge-title body-1 badge-heading">Congratulations !</h2>
        <img src={badgeImage} alt="badge" className="badge-image" />
        <p className="badge-paragraph para-body body-1">
          Learner Success Unlocked
        </p>
        <p className="badge-paragraph body-1">
          Congratulations on completing the course journey!
        </p>
        <button
          className="header-6 badge-button"
          onClick={async () => {
            setShowCert(true);
            setContentType("");
            const certData: any = await createCertificateRelData({
              variables: {
                courseId: courseId,
                traineeId: localStorage.getItem("userId"),
              },
            });

            setCertPath(certData?.data?.createCertificate?.objectName);
            setIncNumber(prev => prev + 1);
          }}
        >
          <span className="allowed-span">View Certificate</span>
        </button>
      </Col>
    );
  };

  useEffect(() => {
    if (certPath) {
      getGenerateUriForCert();
    }
  }, [certPath, incNumber]);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = certPdf;
    a.download = "certificate.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const showCertificate = () => {
    return (
      <>
        <Col className="certificate-container">
          <Col className="content-left">
            <Text className="header-5 capitalize course-header">
              {courseData?.title}
            </Text>
          </Col>

          <Col className="content-right">
            <button
              className="header-6 certificate-button"
              onClick={() => handleDownload()}
            >
              <span className="allowed-span">Download Certificate</span>
            </button>
          </Col>
        </Col>

        {getGenerateUriForCertLoading ||
        generateUriForBadgeLoading ||
        generateUriLoading ? (
          <div className="pdf-loading-spinner">
            <Spin size="large" />
          </div>
        ) : (
          <canvas
            id="certificate-render"
            className="canvas pdf-container-certificate"
          />
        )}
      </>
    );
  };

  const launchQuizSection = () => {
    return (
      <Row className="launch-quiz-row">
        <Col className="launch-quiz-container">
          <img
            src={icons.launchQuiz}
            alt="Launch-Quiz"
            className="quiz-image"
          />
          <h2 className="quiz-title body-1 mb-12 quiz-heading">Quiz Time</h2>
          <p
            className="quiz-paragraph body-1"
            dangerouslySetInnerHTML={{
              __html: selectedQuiz?.description,
            }}
          />
          {isQuizCompleted || renderBorderLeftForQuiz(selectedQuiz?.id)===0 ? (
            <button
              className="header-6 quiz-button"
              onClick={() => {
                // navigate(`${location.pathname}/quiz/${selectedQuiz?.id}`);
                setQuizClicked(true);
                setActiveContent("QSTATUS");
              }}
            >
              <span className="allowed-span">View Quiz</span>
            </button>
          ) : (
            <button
              className="header-6 quiz-button"
              onClick={() => {
                userQuizRelData({
                  variables: {
                    courseId: courseData?.id,
                    quizId: selectedQuiz?.id,
                    userId: localStorage.getItem("userId"),
                  },
                }).then(() => {
                  // navigate(`${location.pathname}/quiz/${selectedQuiz?.id}`);
                  setQuizClicked(true);
                  setActiveContent("QSTATUS");
                });
              }}
            >

              {renderBorderLeftForQuiz(selectedQuiz?.id) ===1 ? (

                <span className="allowed-span">Continue Quiz</span>
              ) : (
                <span className="allowed-span">Launch Quiz</span>
              )}
            </button>
          )}
        </Col>
      </Row>
    );
  };

  const launchAssessmentSection = () => {
    // renderLeftBorderForAssessment();
    return (
      <Row
        className="launch-quiz-row"
        style={{ height: "80%", alignItems: "center" }}
      >
        <Col className="launch-quiz-container">
          <img
            src={icons.launchAssessment}
            alt="Launch-Quiz"
            className="quiz-image"
          />
          <h2 className="quiz-title body-1 mb-12 quiz-heading">
            Assessment Time
          </h2>
          <p className="quiz-paragraph body-1">
            Please consider participating in the assessment to evaluate your
            knowledge based on the information you have acquired this far.
          </p>

          {assessmentStatus === "START" && (
            <button
              className="header-6 quiz-button"
              onClick={() => {
                userAssessmentRelData({
                  variables: {
                    courseId: courseData?.id,
                    assessmentId: currMiniAssessmentId,
                    userId: localStorage.getItem("userId"),
                  },
                }).then(({ data }) => {
                  setAssessmentClicked(true);
                  setActiveContent("STATUS");
                });
              }}
            >
              <span className="allowed-span">Launch Assessment</span>
            </button>
          )}

          {assessmentStatus === "IN_PROGRESS" && (
            <button
              className="header-6 quiz-button"
              onClick={() => {
                setAssessmentClicked(true);
                setActiveContent("STATUS");
              }}
            >
              <span className="allowed-span">Continue Assessment</span>
            </button>
          )}
          {assessmentStatus === "COMPLETED" && (
            <button
              className="header-6 quiz-button"
              onClick={() => {
                setAssessmentClicked(true);
                setActiveContent("STATUS");
              }}
            >
              <span className="allowed-span">View Assessment</span>
            </button>
          )}
        </Col>
      </Row>
    );
  };

  const handleRightCaretClick = () => {
    setIsLessonClicked(false);
    setIsBadgeClicked(false);
    const rightCondition = !disableFinal
      ? currentSectionIndex < moduleData.length - 1
      : currentSectionIndex < moduleData.length - 3;
    if (
      currentItemIndex <
      moduleData[currentSectionIndex]?.moduleMapper?.length - 1
    ) {
      setCurrentItemIndex(currentItemIndex + 1);
    } else if (rightCondition) {
      setCurrentSectionIndex(currentSectionIndex + 1);
      setCurrentItemIndex(0);
    }
    setBookmark(1);
  };

  const handleLeftCaretClick = () => {
    setIsLessonClicked(false);
    setIsBadgeClicked(false);
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    } else if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
      const prevSectionLength =
        moduleData[currentSectionIndex - 1]?.moduleMapper?.length;
      setCurrentItemIndex(prevSectionLength - 1);
    }
    setBookmark(1);
    let updateType = "L";
    if (contentType == "quiz") {
      updateType = "Q";
    } else if (contentType == "miniAssessment") {
      updateType = "MA";
    }
    updateUserCourseRelation({
      variables: {
        courseId: courseId,
        updateId: currItemId,
        updateType: updateType,
        bookmark: 1,
        userEmail: userEmail,
      },
    });
  };

  const updateUserAnswers = (newAnswers) => {
    setUserAnswers(newAnswers);
    setRefreshKey(refreshKey + 1);
  };

  const updateUserScores = (newScores) => {
    setUserScores(newScores);
    setRefreshKey(refreshKey + 1);

    setRefreshKey((prev) => prev + 1);

    console.log("scores", userScores);
    console.log("new scores", newScores);
  };

  const contentWidth =
    windowWidth < 1200 ? "93%" : windowWidth <= 1440 ? "94%" : "95%";

  const courseMediaStyle = {
    width: activeContent === "" ? contentWidth : "64%",
    ...(activeContent === "" && contentType === "quiz" && { overflow: "auto" }),
    // ...(contentType === "miniAssessment" && { overflow: "auto" }),
  };
  // const courseSummaryStyle = {
  //   width: activeContent === "" ? contentWidth : "95%",

  //   // ...(contentType === "miniAssessment" && { overflow: "auto" }),
  // };

  // style={{
  //                                     color: userAnswers[index]
  //                                       ? "#006D75"
  //                                       : "#D48806",
  //                                   }}
  const getAttemptedStyles = (index) => {
    if (userAnswers[index]) {
      if (userScores[index] || userScores[index] == 0) {
        if (userScores[index] <= 4) {
          return {
            color: "#F5222D",
          };
        } else if (userScores[index] <= 8) {
          return {
            color: "#AD8B00",
          };
        } else {
          return {
            color: "#237804",
          };
        }
      } else {
        return {
          color: "#000",
        };
      }
    } else {
      return {
        color: "#AD8B00",
      };
    }
  };

  return (
    <>
      {courseDetailsLoading || moduleDetailsLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[24, 20]} className="course-content-container">
          <Row className="ml-12">
            <Col>
              <Breadcrumb items={breadcrumbItems} />
            </Col>

            <Divider className="divider m-0 mt-12" />
          </Row>

          <Col
            xs={{ span: 24 }}
            className="flex flex--align-center flex--space-between course-content-heading-wrapper"
          >
            <span className="header-4 capitalize course-content-heading">
              {courseData?.title}
            </span>
          </Col>

          <Col xs={{ span: 24 }} className="course-content">
            {
              <Row>
                <Col
                  style={courseMediaStyle}
                  className="course-media-container"
                  ref={courseContentSection}
                >
                  <Row className="course-content-header">
                    <Col
                      span={assessmentClicked || quizClicked ? 8 : 16}
                      className="content-left"
                    >
                      <Text className="header-5 capitalize course-header">
                        {isBadgeClicked
                          ? `Badge and Certificate`
                          : quizClicked
                          ? `Quiz`
                          : !assessmentClicked
                          ? `Section  ${sectionItemClicked?.moduleNumber}: ${moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.[currentSectionIndex]?.module?.moduleName}`
                          : `Assessment`}
                      </Text>
                      {!assessmentClicked && !quizClicked && (
                        <Text className="course-title">
                          {!isBadgeClicked
                            ? `${
                                sectionItemClicked?.quiz ||
                                sectionItemClicked?.lesson ||
                                sectionItemClicked?.miniAssessment
                              }`
                            : ""}
                        </Text>
                      )}
                    </Col>

                    {!isBadgeClicked
                      ? !assessmentClicked &&
                        !quizClicked && (
                          <Col
                            span={8}
                            className="content-right"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Tooltip title="Previous Module">
                              <span
                                style={
                                  !(
                                    moduleDetailsResp?.getAllModulesDetails
                                      ?.moduleDetails?.[0]?.moduleMapper?.[0]
                                      ?.mapperId ===
                                    moduleData?.[currentSectionIndex]
                                      ?.moduleMapper?.[currentItemIndex]
                                      ?.mapperId
                                  )
                                    ? { cursor: "pointer" }
                                    : { cursor: "not-allowed" }
                                }
                                onClick={
                                  !(
                                    moduleDetailsResp?.getAllModulesDetails
                                      ?.moduleDetails?.[0]?.moduleMapper?.[0]
                                      ?.mapperId ===
                                    moduleData?.[currentSectionIndex]
                                      ?.moduleMapper?.[currentItemIndex]
                                      ?.mapperId
                                  )
                                    ? handleLeftCaretClick
                                    : () => {}
                                }
                              >
                                <img
                                  src={icons.caretLeft}
                                  alt=""
                                  style={{ marginRight: "0" }}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="Next Module">
                              <span
                                onClick={
                                  !(
                                    moduleDetailsResp?.getAllModulesDetails
                                      ?.moduleDetails?.[
                                      moduleDetailsLength - diffIndex
                                    ]?.moduleMapper?.[lastModuleMapperLength]
                                      ?.mapperId ===
                                    moduleData?.[currentSectionIndex]
                                      ?.moduleMapper?.[currentItemIndex]
                                      ?.mapperId
                                  )
                                    ? handleRightCaretClick
                                    : () => {}
                                }
                                style={
                                  moduleDetailsResp?.getAllModulesDetails
                                    ?.moduleDetails?.[
                                    moduleDetailsLength - diffIndex
                                  ]?.moduleMapper?.[lastModuleMapperLength]
                                    ?.mapperId ===
                                  moduleData?.[currentSectionIndex]
                                    ?.moduleMapper?.[currentItemIndex]?.mapperId
                                    ? { cursor: "not-allowed" }
                                    : { cursor: "pointer" }
                                }
                              >
                                <img
                                  src={icons.caretRight}
                                  alt=""
                                  style={{ marginRight: "0" }}
                                />
                              </span>
                            </Tooltip>
                          </Col>
                        )
                      : ""}
                    {!isBadgeClicked
                      ? assessmentClicked &&
                        assessmentQuestions && (
                          <>
                            <Col
                              span={8}
                              className="content-right"
                              style={{ justifyContent: "center" }}
                            >
                              <Tooltip title="Previous Question">
                                <span
                                  className="caret-icon"
                                  style={
                                    currentQuestionIndex === 0 ||
                                    isAssessmentResultLoading
                                      ? { cursor: "not-allowed" }
                                      : {}
                                  }
                                  onClick={
                                    isAssessmentResultLoading
                                      ? () => {}
                                      : () => {
                                          if (currentQuestionIndex !== 0) {
                                            setCurrentQuestionIndex(
                                              currentQuestionIndex - 1
                                            );
                                          }
                                        }
                                  }
                                >
                                  <img src={icons.caretLeft} alt="" />
                                </span>
                              </Tooltip>
                              {assessmentQuestions && (
                                <span style={{ color: "#000" }}>{`${
                                  currentQuestionIndex + 1
                                }/${
                                  assessmentQuestions?.length
                                } Questions`}</span>
                              )}

                              <Tooltip title="Next Question">
                                <span
                                  className="caret-icon"
                                  onClick={
                                    isAssessmentResultLoading
                                      ? () => {}
                                      : () => {
                                          if (
                                            currentQuestionIndex <
                                            assessmentQuestions.length - 1
                                          ) {
                                            setCurrentQuestionIndex(
                                              currentQuestionIndex + 1
                                            );
                                          }
                                        }
                                  }
                                  style={
                                    currentQuestionIndex ===
                                      assessmentQuestions?.length - 1 ||
                                    isAssessmentResultLoading
                                      ? { cursor: "not-allowed" }
                                      : {}
                                  }
                                >
                                  <img src={icons.caretRight} alt="" />
                                </span>
                              </Tooltip>
                            </Col>
                            <Col span={8} className="exit-icon">
                              <Tooltip title="Close">
                                <img
                                  style={
                                    isAssessmentResultLoading
                                      ? { cursor: "not-allowed" }
                                      : {}
                                  }
                                  src={icons.exitIcon}
                                  alt=""
                                  onClick={
                                    isAssessmentResultLoading
                                      ? () => {}
                                      : () => {
                                          handleClose();
                                        }
                                  }
                                />
                              </Tooltip>
                            </Col>
                          </>
                        )
                      : ""}

                    {!isBadgeClicked
                      ? quizClicked &&
                        quizDetails && (
                          <>
                            <Col
                              span={8}
                              className="content-right"
                              style={{ justifyContent: "center" }}
                            >
                              <Tooltip title="Previous Question">
                                <span
                                  className="caret-icon"
                                  style={
                                    currentQuizQuestionIndex === 0
                                      ? { cursor: "not-allowed" }
                                      : {}
                                  }
                                  onClick={() => {
                                    if (currentQuizQuestionIndex !== 0) {
                                      setCurrentQuizQuestionIndex(
                                        currentQuizQuestionIndex - 1
                                      );
                                      const newProgress =
                                        ((currentQuizQuestionIndex - 1) /
                                          (quizDetails?.questionSet?.length -
                                            1)) *
                                        100;
                                      setProgress(newProgress);
                                    }
                                  }}
                                >
                                  <img src={icons.caretLeft} alt="" />
                                </span>
                              </Tooltip>

                              {!quizLoading && (
                                <span>
                                  {`${currentQuizQuestionIndex + 1}/${
                                    quizDetails?.questionSet?.length
                                  } Questions`}
                                </span>
                              )}

                              <Tooltip title="Next Question">
                                <span
                                  className="caret-icon"
                                  onClick={() => {
                                    if (
                                      currentQuizQuestionIndex <
                                      quizDetails?.questionSet?.length - 1
                                    ) {
                                      setCurrentQuizQuestionIndex(
                                        currentQuizQuestionIndex + 1
                                      );

                                      const newProgress =
                                        ((currentQuizQuestionIndex + 1) /
                                          (quizDetails?.questionSet?.length -
                                            1)) *
                                        100;
                                      setProgress(newProgress);
                                    }
                                  }}
                                  style={
                                    currentQuizQuestionIndex ===
                                    quizDetails?.questionSet?.length - 1
                                      ? { cursor: "not-allowed" }
                                      : {}
                                  }
                                >
                                  <img src={icons.caretRight} alt="" />
                                </span>
                              </Tooltip>
                            </Col>
                            <Col span={8} className="exit-icon">
                              <Tooltip title="Close">
                                <img
                                  // style={
                                  //   isAssessmentResultLoading
                                  //     ? { cursor: "not-allowed" }
                                  //     : {}
                                  // }
                                  src={icons.exitIcon}
                                  alt=""
                                  onClick={
                                    isAssessmentResultLoading
                                      ? () => {}
                                      : () => {
                                          handleClose();
                                        }
                                  }
                                />
                              </Tooltip>
                            </Col>
                          </>
                        )
                      : ""}
                  </Row>
                  {contentType === "video" && (
                    <div className="video-player-container">
                      <ReactPlayer
                        url={contentLink}
                        controls={true}
                        light={
                          <img
                            width={"100%"}
                            src={contentThumbnail}
                            alt="Thumbnail"
                          />
                        }
                        width="100%"
                      />
                    </div>
                  )}
                  {contentType === "txt" && getHtmlContent(contentLink)}
                  {(contentType === "pdf" ||
                    contentType === "application/pdf") && (
                    <>
                      {/* {loadDocument && ( */}
                      {loadFail ? (
                        <div className="pdf-loading-spinner">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <canvas
                          id="pdf-render"
                          className="canvas pdf-container"
                        />
                      )}
                      {!loadFail && (
                        <div className="pdf-toolbar">
                          <Tooltip title="Previous Page">
                            <Button
                              onClick={showPrevPage}
                              type="text"
                              disabled={disablePrev}
                            >
                              <LeftOutlined />
                            </Button>
                          </Tooltip>
                          <Text>
                            {pageNum} / {numberPages}
                          </Text>
                          <Tooltip title="Next Page">
                            <Button
                              onClick={showNextPage}
                              type="text"
                              disabled={disableNext}
                            >
                              <RightOutlined />
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}
                  {contentType === "quiz" &&
                    !quizClicked &&
                    launchQuizSection()}
                  {contentType === "badges" && showBadge()}
                  {showCert && showCertificate()}
                  {contentType === "miniAssessment" &&
                    !assessmentClicked &&
                    launchAssessmentSection()}
                  {contentType === "miniAssessment" && assessmentClicked && (
                    <div
                      className="assessment-content-container"
                      style={{ overflow: "auto" }}
                    >
                      <UserAssessment
                        miniAssessmentId={currMiniAssessmentId}
                        setEvaluationCriteria={setEvaluationCriteria}
                        setAssessmentQuestions={setAssessmentQuestions}
                        updateUserAnswers={updateUserAnswers}
                        userAnswers={userAnswers}
                        userScores={userScores}
                        updateUserScores={updateUserScores}
                        curMiniAssessmentRelation={currMiniAssessmentRelation}
                        currentQuestionIndex={currentQuestionIndex}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        isAssessmentResultLoading={isAssessmentResultLoading}
                        setIsAssessmentResultLoading={
                          setIsAssessmentResultLoading
                        }
                      />
                    </div>
                  )}

                  {contentType === "quiz" && quizClicked && (
                    <Quiz
                      quizId={selectedQuiz?.id}
                      quizDetails={quizDetails}
                      setQuizDetails={setQuizDetails}
                      quizSavedDetails={quizSavedDetails}
                      setQuizSavedDetails={setQuizSavedDetails}
                      setQuizAnswerDetails={setQuizAnswerDetails}
                      quizAnswerDetails={quizAnswerDetails}
                      currentQuestionIndex={currentQuizQuestionIndex}
                      setCurrentQuestionIndex={setCurrentQuizQuestionIndex}
                      progress={progress}
                      setProgress={setProgress}
                      userAnswers={userQuizAnswers}
                      setUserAnswers={setUserQuizAnswers}
                      quizLoading={quizLoading}
                      setQuizLoading={setQuizLoading}
                    />
                  )}
                  {contentType === "none" && (
                    <div className="pdf-container flex flex--align-center flex--justify-center">
                      File does not exist
                    </div>
                  )}
                </Col>
                {activeContent === "BOT" && (
                  <Col
                    //lg={{ span: activeContent === "BOT" ? 7 : 0 }}
                    style={
                      activeContent === "BOT"
                        ? { width: "28%" }
                        : { width: "0%", display: "none" }
                    }
                    className="chat-bot-container"

                  >
                    <SmartTutor
                      visible={true}
                      assessmentId={1}
                      selectedTaskIndex={currentIndex}
                      questionEmpty={questionEmpty}
                      questionsList={currentPythonQuestions}
                      isBackend={false}
                      local={true}
                      pageContext={false}
                      prompt={PYTHON_PROMPT}
                      forLearning={true}
                      loadFail={loadFail}
                      contentType={contentType}
                      courseId={courseId}
                    />
                  </Col>
                )}

                {activeContent === "CONTENT_DETAILS" && (
                  <Col
                    // xs={{ span: activeContent === "CONTENT_DETAILS" ? 7 : 0 }}
                    style={
                      activeContent === "CONTENT_DETAILS"
                        ? { width: "28%" }
                        : { width: "0%", display: "none" }
                    }
                    className="course-content course-module-popup"
                  >
                    <div className="course-module-header">
                      <Title
                        level={4}
                        className="header-5 course-content-popup-header"
                      >
                        Course Content
                      </Title>
                    </div>
                    <Collapse
                      activeKey={quizActiveKeys}
                      onChange={(keys) => setQuizActiveKeys([...keys])}
                      expandIconPosition="end"
                      className="module-popup-collapse-container"
                    >
                      {moduleDetailsData?.getAllModulesDetails?.moduleDetails?.map(
                        (module: any, index) => {
                          const moduleType = module?.module?.moduleType;

                          return moduleType === "BADGES" ? (
                            <>
                              <div
                                className="header-container badges"
                                style={{
                                  cursor: !disableBadge
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                onClick={() => {
                                  if (disableBadge) {
                                    getGenerateUriForBadge();
                                    setContentType("badges");
                                    setShowConfetti(true);
                                    setIsBadgeClicked(true);
                                    setShowCert(false);

                                    setTimeout(() => {
                                      setShowConfetti(false);
                                    }, 10000);
                                  } else {
                                    message.info(
                                      "Please complete all the sections"
                                    );
                                  }
                                }}
                              >
                                <span className="header-title">
                                  Badge and Certificate
                                </span>
                              </div>
                            </>
                          ) : (
                            <Panel
                              header={getHeader(module, index)}
                              key={module?.module?.moduleNumber}
                              showArrow={true}
                              disabled={
                                module?.module?.moduleType ===
                                  "FINAL_ASSESSMENT" && disableFinal
                              }
                            >
                              {module?.moduleMapper?.map((section: any) => {
                                return (
                                  <div
                                    key={section?.mapperId}
                                    className="section-container"
                                  >
                                    {section?.miniAssessment !== null && (
                                      <Row className="section-item">
                                        <Col
                                          span={20}
                                          className="pl-10"
                                          style={
                                            renderLeftBorderForAssessment(
                                              section?.miniAssessment?.id
                                            )
                                              ? {
                                                  borderLeft:
                                                    "2px #237804 solid",
                                                }
                                              : {}
                                          }
                                        >
                                          {getAssessmentSection(
                                            section?.orderNumber,
                                            section?.miniAssessment,
                                            module?.module?.moduleNumber
                                          )}
                                        </Col>
                                      </Row>
                                    )}
                                    {section?.lesson !== null && (
                                      <Row className="section-item">
                                        <Col
                                          span={20}
                                          style={
                                            renderBorderLeftForLesson(
                                              section?.lesson?.id
                                            )
                                              ? {
                                                  borderLeft:
                                                    "2px #237804 solid",
                                                }
                                              : {}
                                          }
                                          className="pl-10"
                                        >
                                          {getLessonSection(
                                            section?.orderNumber,
                                            section?.lesson,
                                            module?.module?.moduleNumber
                                          )}
                                        </Col>
                                      </Row>
                                    )}
                                    {section?.quiz !== null && (
                                      <Row className="section-item">
                                        <Col
                                          span={20}
                                          className="pl-10"
                                          style={
                                            renderBorderLeftForQuiz(
                                              section?.quiz?.id
                                            )
                                              ? {
                                                  borderLeft:
                                                    "2px #237804 solid",
                                                }
                                              : {}
                                          }
                                        >
                                          {getQuizSection(
                                            section?.orderNumber,
                                            section?.quiz,
                                            module?.module?.moduleNumber
                                          )}
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                );
                              })}
                            </Panel>
                          );
                        }
                      )}
                    </Collapse>
                  </Col>
                )}

                {activeContent === "STATUS" && (
                  <Col
                    style={
                      activeContent === "STATUS"
                        ? { width: "28%" }
                        : { width: "0%", display: "none" }
                    }
                    key={refreshKey}
                  >
                    <Col className="quiz-status">
                      <Row
                        className="quiz-status-header assessment-header"
                        style={{ padding: "1.3rem" }}
                      >
                        <Text className="header-5 capitalize title">
                          <span className="content">{`Completion Status`}</span>
                        </Text>
                      </Row>

                      <div className="completion-status-container">
                        {assessmentQuestions?.map(
                          (question: any, index: number) => (
                            <Row
                              key={question?.id}
                              className="quiz-status-content"
                              onClick={
                                isAssessmentResultLoading
                                  ? () => {}
                                  : () => {
                                      setCurrentQuestionIndex(index);
                                    }
                              }
                            >
                              <Card
                                className="width-100 quiz-status-card"
                                style={{ textAlign: "center" }}
                              >
                                <Row>
                                  <Col className="flex flex--center">
                                    <Text className="quiz-question-title">{`Question ${
                                      index + 1
                                    }`}</Text>
                                  </Col>

                                  <Col className="flex flex--center">
                                    <div
                                      // style={{
                                      //   color: userAnswers[index]
                                      //     ? "#006D75"
                                      //     : "#D48806",
                                      // }}
                                      style={getAttemptedStyles(index)}
                                      className="answered-status"
                                    >
                                      <span className="answer-status">
                                        {userAnswers[index]
                                          ? "Attempted"
                                          : "Not Attempted"}
                                      </span>
                                    </div>
                                  </Col>

                                  <Col className="flex flex--center">
                                    <Text
                                      className="quiz-question-title"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {userScores[index] ||
                                      userScores[index] === 0 ||
                                      userScores[index] === "0"
                                        ? `${userScores[index]}/10`
                                        : `N/A`}
                                    </Text>
                                  </Col>
                                </Row>
                              </Card>
                            </Row>
                          )
                        )}
                      </div>
                    </Col>
                  </Col>
                )}

                {activeContent === "QSTATUS" && (
                  <Col
                    style={
                      activeContent === "QSTATUS"
                        ? { width: "28%" }
                        : { width: "0%", display: "none" }
                    }
                    key={refreshKey}
                  >
                    {!quizAnswerDetails && (
                      <Col className="quiz-status ">
                        <Row className="quiz-status-header">
                          <Text className="header-5 capitalize title">
                            <span className="content">{`Completion Status`}</span>
                          </Text>
                        </Row>
                        <Row className="completion-status-container">
                          {quizDetails?.questionSet?.map(
                            (question: any, index: number) => (
                              <Row
                                key={question?.id}
                                className="quiz-status-content width-100"
                              >
                                <Card className="width-100 quiz-status-card">
                                  <Row className="flex flex--space-around">
                                    <Col className="flex flex--align-center">
                                      <Text className="quiz-question-title">{`Question ${
                                        index + 1
                                      }`}</Text>
                                    </Col>

                                    <Col>
                                      <div
                                        style={{
                                          color: userQuizAnswers[index]
                                            ? "#006D75"
                                            : "#D48806",
                                        }}
                                        className="answered-status"
                                      >
                                        <span className="answer-status">
                                          {userQuizAnswers[index]
                                            ? "Attempted"
                                            : "Not Attempted"}
                                        </span>
                                      </div>
                                    </Col>

                                    <Col>
                                      <Text className="mark-status">{`${
                                        question?.score
                                      } ${
                                        question?.score === 1
                                          ? "Point"
                                          : "Points"
                                      }`}</Text>
                                    </Col>
                                  </Row>
                                </Card>
                              </Row>
                            )
                          )}
                        </Row>
                      </Col>
                    )}

                    {quizAnswerDetails && (
                      <Col className="quiz-status quiz-flow-status">
                        <Row className="quiz-status-header flex flex--space-between">
                          <Col>
                            <Text className="header-5 capitalize title">{`Score Board`}</Text>
                          </Col>
                          <Col>
                            <Text className="header-5 capitalize title">
                              {calculateScore()}
                            </Text>
                          </Col>
                        </Row>
                        <Row className="completion-status-container">
                          {quizDetails?.questionSet?.map(
                            (question: any, index: number) => (
                              <Row
                                key={question?.id}
                                className="quiz-status-content width-100"
                              >
                                <Card className="width-100 quiz-status-card">
                                  <Row className="flex flex--space-around">
                                    <Col className="flex flex--align-center">
                                      <Text className="score-card-question">{`Question ${
                                        index + 1
                                      }`}</Text>
                                    </Col>
                                    <Col>
                                      {" "}
                                      <Col>
                                        <div
                                          style={getAttemptedQuizStyles(
                                            index,
                                            question.id
                                          )}
                                          className="answered-status"
                                        >
                                          <span>
                                            {returnUserAnswers(index)
                                              ? "Attempted"
                                              : "Not Attempted"}
                                          </span>
                                        </div>
                                      </Col>
                                    </Col>

                                    <Col>
                                      <Text className="score-card-mark">
                                        {findAnswerStatus(question.id)
                                          ? `${question?.score}/${question?.score}`
                                          : `0/${question?.score}`}
                                      </Text>
                                    </Col>
                                  </Row>
                                </Card>
                              </Row>
                            )
                          )}
                        </Row>
                      </Col>
                    )}
                  </Col>
                )}

                <Col style={{ width: "5%" }} className="floating-buttons">
                  <Tooltip title="Super Tutor Bot" placement="left">
                    <img
                      src={
                        activeContent === "BOT" ? icons.BotActive : icons.Bot
                      }
                      alt=""
                      onClick={() => {
                        if (activeContent === "BOT") {
                          setActiveContent("");
                        } else {
                          setActiveContent("BOT");
                        }
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Course Content" placement="left">
                    <img
                      src={
                        activeContent === "CONTENT_DETAILS"
                          ? icons.ContentDetailsActive
                          : icons.ContentDetails
                      }
                      alt=""
                      onClick={() => {
                        getCompletionStatus();
                        getCompletionStatusForBadges();
                        if (activeContent === "CONTENT_DETAILS") {
                          setActiveContent("");
                        } else {
                          setActiveContent("CONTENT_DETAILS");
                        }
                        // setModulePopupVisibility(!modulePopupOpen);
                      }}
                      className="mt-16"
                    />
                  </Tooltip>

                  {assessmentClicked && (
                    <Tooltip title="Assessment Status" placement="left">
                      {" "}
                      <img
                        src={
                          activeContent === "STATUS"
                            ? icons.statusActive
                            : icons.status
                        }
                        alt=""
                        onClick={() => {
                          if (activeContent === "STATUS") {
                            setActiveContent("");
                          } else {
                            setActiveContent("STATUS");
                          }
                        }}
                        className="mt-16"
                      />
                    </Tooltip>
                  )}

                  {quizClicked && (
                    <Tooltip title="Quiz Status" placement="left">
                      {" "}
                      <img
                        src={
                          activeContent === "QSTATUS"
                            ? icons.statusActive
                            : icons.status
                        }
                        alt=""
                        onClick={() => {
                          if (activeContent === "QSTATUS") {
                            setActiveContent("");
                          } else {
                            setActiveContent("QSTATUS");
                          }
                        }}
                        className="mt-16"
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            }
          </Col>
          {contentType !== "quiz" && contentType !== "badges" && !showCert && (
            <Col xs={{ span: 24 }} className="summary-container">
              <Row className="course-content-header">
                <Col span={16} className="content-left">
                  <Text className="header-5 capitalize course-header">
                    {assessmentClicked ? "Evalutation Criteria" : "Summary"}
                  </Text>
                </Col>
              </Row>
              <Row className="summary-content">
                <>
                  {loadFail ? (
                    <div className="spin-container">
                      <Spin size="large" />
                    </div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          contentType === "quiz" ||
                          (contentType === "miniAssessment" &&
                            !assessmentClicked)
                            ? SUMMARY_NOT_AVAILABLE
                            : contentType === "miniAssessment" &&
                              assessmentClicked
                            ? evaluationCriteria
                            : currentSummary[currentIndex],
                      }}
                      className="current-summary-content"
                    />
                  )}
                </>
              </Row>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default CourseContent;
