import { Col, Divider, Row, Tabs, Typography } from "antd";
import GenerateQuiz from "component/GenerateQuiz/GenerateQuiz";
import LessonDetails from "component/LessonDetails/LessonDetails";
import { useState } from "react";
import "./Segments.scss";
import Assessments from "component/Assessments/Assessments";

const { TabPane } = Tabs;
const { Text } = Typography;

const Segments = ({ sectionClicked, sectionDetails, setSectionDetails, courseId, course_cuuid }) => {
    const [activeTab, setActiveTab] = useState("Lessons");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Row gutter={24} className="segments-container">
            <Col className="segments-header" span={24}>
                <Text className="header-5 capitalize segments-header-title">
                    {`Section ${sectionDetails.findIndex((section) => section.moduleId === sectionClicked.moduleId) + 1}- ${sectionClicked?.title}`}
                </Text>
                <Divider className="breadcrumb-divider m-0 mt-12" />
            </Col>
            <Col span={24} className="segments-content">
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane tab="Lessons" key="Lessons">
                        <LessonDetails
                            section={sectionClicked}
                            sectionDetails={sectionDetails}
                            updateSection={setSectionDetails}
                            courseId={courseId}
                            course_cuuid={course_cuuid}
                        />
                    </TabPane>
                    <TabPane tab="Assessment" key="Assessment">
                        <Assessments 
                            sectionDetails={sectionDetails}
                            sectionClicked={sectionClicked}
                            courseId={courseId}
                            updateSection={setSectionDetails}
                        />
                    </TabPane>
                    <TabPane tab="Generate Quiz" key="Generate Quiz">
                        <GenerateQuiz 
                            sectionDetails={sectionDetails}
                            sectionClicked={sectionClicked}
                            courseId={courseId}
                            updateSection={setSectionDetails}
                        />
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    )
}

export default Segments;