import React, { useEffect, useState } from "react";
import "./AddEditSectionDetails.scss";
import SectionDetails from "component/SectionDetails/SectionDetails";
import { Button, Col, Divider, Row, Spin, message } from "antd";
import Breadcrumb from "component/Breadcrumb/Breadcrumb";
import { COURSE_CONTENT_BREADCRUMB_TILES } from "component/lib/Constants";
import Segments from "component/Segments/Segments";
import { useLazyQueryData } from "services";
import { GET_MODULE_DETAILS } from "services/Queries";

const AddEditSectionDetails = ({
  onNext,
  onPrev,
  sectionDetails,
  setSectionDetails,
  courseId,
  coursecuuid
}) => {
  // onNext props will be used in GenerateQuiz component.
  const [sectionClicked, setSectionClicked] = useState<any>({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTabComponent, setActiveTabComponent] =
    useState<any>(COURSE_CONTENT_BREADCRUMB_TILES.SECTION_DETAILS);
  const [breadcrumbItems, setBreadcrumbItems] = useState<any>([
    {
      label: COURSE_CONTENT_BREADCRUMB_TILES.SECTION_DETAILS,
      onClick: () => handleBreadcrumbItemClick(0),
      className: "bc-parent",
    },
    {
      label: COURSE_CONTENT_BREADCRUMB_TILES.SEGMENTS,
      onClick: () => { },
      className: "bc-child"
    }
  ]);

  const handleBreadcrumbItemClick = (index) => {
    setActiveIndex(index);
    setActiveTabComponent(breadcrumbItems[index].label);
    // Items having index less than or equal to activeIndex will be clickable.
    const updatedBreadcrumbItems = breadcrumbItems.map((item, i) => ({
      ...item,
      className: i <= index ? "bc-parent" : "bc-child",
      onClick: i <= index ? () => handleBreadcrumbItemClick(i) : null,
    }));
    setBreadcrumbItems(updatedBreadcrumbItems);
  };

  const handleNextButtonClick = () => {
    // Mutation to be added here once all the sections, lessons and quizzes are added.
    onNext();
  };

  // ====Queries====
  const [
    getModuleDetails,
    { data: moduleDetailsResp, loading: moduleDetailsLoading },
  ] = useLazyQueryData(GET_MODULE_DETAILS, {
    courseId: courseId,
  });

  useEffect(() => {
    getModuleDetails();
  }, [courseId]);

  useEffect(() => {
    if (moduleDetailsResp && moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.length > 0) {
      const responseData = moduleDetailsResp?.getAllModulesDetails?.moduleDetails?.map((module) => {
        const m_id = module.module.id;
        const moduleId = module.module.moduleId;
        const title = module.module.moduleName;
        const moduleNumber = module.module.moduleNumber;
        const moduleType = module.module.moduleType;
        const lessons = module.moduleMapper.filter(item => item.lesson !== null).map((item) => {
          const l_id = item.lesson.id;
          const lessonId = item.lesson.lessonId;
          const lessonName = item.lesson.lessonName;
          const fileType = item.lesson.contentType;
          const fileName = item.lesson.contentLink;
          const orderNumber = item.orderNumber;
          const summary = item.lesson.summary;
          const suggestiveQuestions = item.lesson.suggestiveQuestions;

          return {
            l_id,
            newEntry: false,
            lessonId,
            lessonName,
            fileType,
            fileName,
            orderNumber,
            summary,
            suggestiveQuestions
          }
        });

        const quizzes = module.moduleMapper.filter(item => item.quiz !== null).map((item) => {
          const id = item.quiz.id;
          const title = item.quiz.title;
          const description = item.quiz.description;
          const totalScore = item.quiz.totalScore;
          const duration = item.quiz.duration;
          const prerequisite = item.quiz.prerequisite;
          const orderNumber = item.orderNumber;

          return {
            id,
            title,
            description,
            totalScore,
            duration,
            prerequisite,
            orderNumber
          }
        });

        const assessments = module.moduleMapper.filter(item => item.miniAssessment !== null).map((item) => {
          const id = item.miniAssessment.id;
          const title = item.miniAssessment.title;
          const duration = item.miniAssessment.duration;
          const description = item.miniAssessment.description;
          const orderNumber = item.orderNumber;

          return {
            id,
            title,
            duration,
            description,
            orderNumber
          }
        });

        return {
          m_id,
          moduleId,
          newEntry: false,
          title,
          moduleNumber,
          moduleType,
          lessons,
          quizzes,
          assessments
        }
      });

      setSectionDetails(responseData);
    }
  }, [moduleDetailsResp]);

  return (
    <>
      {moduleDetailsLoading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <Col span={24} className="main-container">
          <Row className="mb-16 main-container-row">
            <Col span={24}>
              <Breadcrumb items={breadcrumbItems} />
            </Col>
            <Col span={4} className="divider-container">
              <Divider className="divider m-0 mt-12 mb-10" />
            </Col>
          </Row>
          {activeTabComponent === COURSE_CONTENT_BREADCRUMB_TILES.SECTION_DETAILS && (
            moduleDetailsLoading ? (
              <div className="loading-spinner">
                <Spin size="large" />
              </div>
            ) :
              <SectionDetails
                title={COURSE_CONTENT_BREADCRUMB_TILES.SECTION_DETAILS}
                sectionDetails={sectionDetails}
                updateSection={setSectionDetails}
                setSectionClicked={setSectionClicked}
                handleBreadcrumbItemClick={handleBreadcrumbItemClick}
                activeIndex={activeIndex}
                courseId={courseId}
              />
          )}

          {activeTabComponent === COURSE_CONTENT_BREADCRUMB_TILES.SEGMENTS && (
            <Segments
              sectionClicked={sectionClicked}
              sectionDetails={sectionDetails}
              setSectionDetails={setSectionDetails}
              courseId={courseId}
              course_cuuid={coursecuuid}
            />
          )}

          <Row gutter={[24, 24]} className="course-form-button-container mt-12">
            <Col span={24}>
              <Row className="flex flex--space-between mb-20 mt-20 mr-12">
                <Col></Col>
                <Col>
                  <Button
                    className="form-clear-button mr-8"
                    onClick={() => onPrev()}
                  >
                    Back
                  </Button>
                  <Button
                    className="form-next-button"
                    onClick={() => {
                      if (activeTabComponent === COURSE_CONTENT_BREADCRUMB_TILES.SECTION_DETAILS) {
                        if (sectionDetails.length === 0) {
                          message.info("No section has been added");
                        } else {
                          handleNextButtonClick();
                        }
                      } else if (activeTabComponent === COURSE_CONTENT_BREADCRUMB_TILES.SEGMENTS) {
                        return handleNextButtonClick();
                      }
                    }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export default AddEditSectionDetails;
