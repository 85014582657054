import { createHttpLink } from "apollo-link-http";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import CurrentSettings from "auth/Config";

const cache = new InMemoryCache();

export const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = JSON.parse(localStorage.getItem("userData") || {});
  return {
    headers: {
      ...headers,
      "X-Api-Token": token?.accessToken,
    },
  };
});

const httpLink = createHttpLink({
  uri: CurrentSettings.api,
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  resolvers: {},
});

export default client;
