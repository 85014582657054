import { useLazyQuery, useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import { GET_LOGGEDIN_USER } from "services/Queries";

export const useLazyQueryData = (
  QUERY,
  variables,
  skip = false,
  fetchPolicy = "network-only",
  nextFetchPolicy = "network-only"
) => {
  const { instance, accounts } = useMsal();

  let activeAccount = null;
  let token = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || {}) : null;
  let userId = localStorage.getItem("userId") 
  if(!userId || userId == "undefined"){
    QUERY = GET_LOGGEDIN_USER
    variables= {}
  }
  if(!token || !token?.accessToken){
    instance.loginRedirect().catch((error) => console.log(error));
    if (instance) {
      activeAccount = instance.getActiveAccount() || accounts[0];
      activeAccount &&
        instance
          .acquireTokenSilent({
            scopes: ["User.Read"],
            account: activeAccount,
          })
          .then((response) => {
            if (response) {
              localStorage.setItem(
                "userData",
                JSON.stringify({
                  account: response.account,
                  accessToken: response.accessToken,
                })
              );
            }
          });
    }
  }
  return useLazyQuery(QUERY, {
    variables: { ...variables },
    skip: skip,
    fetchPolicy: fetchPolicy,
    nextFetchPolicy,
  });
};
 
export const useMutationData = (QUERY) => {
  const { instance, accounts } = useMsal();

  let activeAccount = null;
  
  let token = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || {}) : null;
  let userId = localStorage.getItem("userId") 
  if(!token || !token?.accessToken || !userId || userId=="undefined"){
    instance.loginRedirect().catch((error) => console.log(error));
    if (instance) {
      activeAccount = instance.getActiveAccount() || accounts[0];
      activeAccount &&
        instance
          .acquireTokenSilent({
            scopes: ["User.Read"],
            account: activeAccount,
          })
          .then((response) => {
            if (response) {
              localStorage.setItem(
                "userData",
                JSON.stringify({
                  account: response.account,
                  accessToken: response.accessToken,
                })
              );
            }
          });
    }
  }
  return useMutation(QUERY);
};