import { Col, Row } from "antd";
import "./SectionTile.scss";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const SectionTile = ({
  section,
  index,
  mode = "EDIT",
  handleDeleteSection = () => {},
  onEdit = () => {},
  handleClickedSection = () => {},
  editInPreview = () => {}
}) => {
  return (
    <Col span={24}>
      <Row
        gutter={[40, 40]}
        align="middle"
        className="flex flex--space-between section-details-tile-row"
      >
        <Col>
          <Row className="section-details-tile-heading">
            Section {index + 1}
          </Row>
          <Row className="section-details-tile-title">{section.title}</Row>
        </Col>
        <Col className="section-details-tile-buttons">
          <EditOutlined
            style={{ color: "#08979C" }}
            className="mr-16"
            onClick={() => {
              editInPreview();
              onEdit();
            }}
          />
          <DeleteOutlined
            style={{ color: "#A8071A" }}
            className="mr-16"
            onClick={() => handleDeleteSection()}
          />
          {mode === "CREATE" && section?.moduleType === "NORMAL" && (
            <span onClick={handleClickedSection}>Add/Edit segment</span>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default SectionTile;
