import React from "react";

const DashboardIcon = ({ active = false }) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.2729 9.75C26.2729 9.3912 25.9868 9.1 25.6342 9.1H15.4143C15.0617 9.1 14.7755 9.3912 14.7755 9.75V25.35C14.7755 25.7088 15.0617 26 15.4143 26H25.6342C25.9868 26 26.2729 25.7088 26.2729 25.35V9.75ZM12.2205 18.85C12.2205 18.4912 11.9344 18.2 11.5818 18.2H1.36189C1.0093 18.2 0.723145 18.4912 0.723145 18.85V25.35C0.723145 25.7088 1.0093 26 1.36189 26H11.5818C11.9344 26 12.2205 25.7088 12.2205 25.35V18.85ZM10.9431 19.5V24.7H2.00063V19.5H10.9431ZM24.9954 10.4V24.7H16.053V10.4H24.9954ZM12.2205 0.65C12.2205 0.2912 11.9344 0 11.5818 0H1.36189C1.0093 0 0.723145 0.2912 0.723145 0.65V16.25C0.723145 16.6088 1.0093 16.9 1.36189 16.9H11.5818C11.9344 16.9 12.2205 16.6088 12.2205 16.25V0.65ZM10.9431 1.3V15.6H2.00063V1.3H10.9431ZM26.2729 0.65C26.2729 0.2912 25.9868 0 25.6342 0H15.4143C15.0617 0 14.7755 0.2912 14.7755 0.65V7.15C14.7755 7.5088 15.0617 7.8 15.4143 7.8H25.6342C25.9868 7.8 26.2729 7.5088 26.2729 7.15V0.65ZM24.9954 1.3V6.5H16.053V1.3H24.9954Z"
        fill="white"
      />
    </svg>
  );
};

export default DashboardIcon;
