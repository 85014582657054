import React, { useEffect, useState } from "react";
import "./AddEditCourseDetails.scss";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Typography,
  DatePicker,
  Select,
  Divider,
  Upload,
  message,
} from "antd";
import { CalendarOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useLazyQueryData, useMutationData } from "services";
import { GENERATE_PUT_URI } from "services/Mutation";
import dayjs from "dayjs";
import { GET_ALL_SKILLS } from "services/Queries";
import ReactQuill from "react-quill";
import { ALLOWED_TYPES_FOR_IMAGES } from 'component/lib/Constants';

const { Text } = Typography;
const { Option } = Select;

const AddEditCourseDetails = ({
  onNext,
  title,
  usersInfo,
  courseDetails,
  courseId,
  setCourseId,
  createUpdateCourseMutation,
  userEmail,
  setCourse_uuid,
  setCourseCreationData,
  courseCreationData
}) => {
  const [skills, setSkills] = useState<any>([]);
  const [form] = Form.useForm();
  const [cuuid, setcuuid] = useState(null);
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [notEnter, setNotEnter] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [defaultSkills, setDefaultSkills] = useState<any>();

  // ===Queries===
  const [
    getAllSkills, 
    { data: getSkillsResp, loading: getSkillsLoading },
  ] = useLazyQueryData(GET_ALL_SKILLS);

  // ===Mutations===
  const [generatePutUri, { loading: generatePutUriLoading }] =
    useMutationData(GENERATE_PUT_URI);

  const validateDateRange = () => {
    const validFrom = form.getFieldValue("validFrom");
    const validTo = form.getFieldValue("validTo");

    if (validFrom && validTo && validFrom.isAfter(validTo)) {
      form.setFields([
        {
          name: "validTo",
          errors: ["ValidTo must be later than ValidFrom"],
        },
      ]);
    } else {
      form.setFields([
        {
          name: "validTo",
          errors: [],
        },
      ]);
    }
  };

  useEffect(() => {
    setcuuid(uuidv4());
  }, []);

  useEffect(() => {
    setCourse_uuid(cuuid);
  }, [cuuid]);

  const handleNextButtonClick = () => {
    form
      .validateFields()
      .then(() => {
        validateDateRange();
        const errors = form.getFieldsError();
        const hasErrors = errors.some(
          (fieldError) => fieldError.errors.length !== 0
        );

        if (!hasErrors) {
          const formData = form.getFieldsValue();
          const isObjectFormat = formData?.skills.length > 0 && typeof formData?.skills[0] === 'object';
          const formattedSkills = isObjectFormat
            ? formData.skills.map((skill) => JSON.stringify(skill))
            : formData.skills.map((skill) => (JSON.stringify({ value: skill, label: skill })));

          setCourseCreationData({
            title: formData?.title,
            courseBanner: formData?.courseBanner,
            maxEnrollment: formData?.maxEnrollment,
            validFrom: moment(formData?.validFrom?.$d).format('YYYY-MM-DD'),
            validTo: moment(formData?.validTo?.$d).format('YYYY-MM-DD'),
            duration: formData?.duration,
            courseDescription: formData?.courseDescription,
            prerequisites: formData?.prerequisites,
            whtylearn: formData?.whtylearn,
            skills: formattedSkills
          });

          const coursePayload = {
            id: courseId ?? null,
            courseId: cuuid,
            courseBanner: formData?.courseBanner,
            title: formData?.title,
            courseDetails: formData?.courseDescription,
            whatYouWillLearn: formData?.whtylearn,
            prerequisite: formData?.prerequisites,
            maxEnrollments: formData?.maxEnrollment,
            validTo: moment(formData?.validTo?.$d).format('YYYY-MM-DD'),
            validFrom: moment(formData?.validFrom?.$d).format('YYYY-MM-DD'),
            duration: formData?.duration,
            status: "PUBLISHED",
            skills: formattedSkills,
          };

          createUpdateCourseMutation({
            variables: {
              course: coursePayload,
              userEmail: userEmail,
            },
          }).then(({ data }) => {
            setCourseId(data?.courseSaveDraft?.course?.id);
            onNext();
          });
        }
      })
      .catch((errorInfo) => { });
  };

  useEffect(() => {
    if (courseId && courseDetails && Object.keys(courseDetails).length > 0) {
      setNotEnter(true);
      const correctedResponse = courseDetails?.courseDetails?.skills.map((item) => item.replace(/'/g, '"'));
      const jsonArray = correctedResponse.map((item) => JSON.parse(item));

      setSkills(
        jsonArray.map((item) => ({
          value: item.value,
          label: item.label
        }))
      );

      setcuuid(courseDetails?.courseDetails?.courseId);

      form.setFieldsValue({
        title: courseDetails?.courseDetails?.title,
        courseBanner: courseDetails?.courseDetails?.courseBanner,
        maxEnrollment: courseDetails?.courseDetails?.maxEnrollments,
        validFrom: dayjs(courseDetails?.courseDetails?.validFrom),
        validTo: dayjs(courseDetails?.courseDetails?.validTo),
        duration: courseDetails?.courseDetails?.duration,
        courseDescription: courseDetails?.courseDetails?.courseDetails,
        prerequisites: courseDetails?.courseDetails?.prerequisite,
        whtylearn: courseDetails?.courseDetails?.whatYouWillLearn,
      });

      const parts = courseDetails?.courseDetails?.courseBanner?.split("/");
      const lastPart = parts?.[parts?.length - 1];
      setFileName(lastPart);
    } else {
      if (courseCreationData) {
        form.setFieldsValue({
          title: courseCreationData?.title,
          courseBanner: courseCreationData?.courseBanner,
          maxEnrollment: courseCreationData?.maxEnrollment,
          validFrom: dayjs(courseCreationData?.validFrom),
          validTo: dayjs(courseCreationData?.validTo),
          duration: courseCreationData?.duration,
          courseDescription: courseCreationData?.courseDescription,
          prerequisites: courseCreationData?.prerequisites,
          whtylearn: courseCreationData?.whtylearn
        });

        setNotEnter(true);
        const parts = courseCreationData?.courseBanner?.split("/");
        const lastPart = parts?.[parts?.length - 1];
        setFileName(lastPart);
        const correctedResponse = courseCreationData?.skills.map((item) => item.replace(/'/g, '"'));
        const jsonArray = correctedResponse.map((item) => JSON.parse(item));

        setSkills(
          jsonArray.map((item) => ({
            value: item.value,
            label: item.label
          }))
        );
      }
    }
  }, [courseId, courseDetails]);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  useEffect(() => {
    if (notEnter) {
      form.setFieldsValue({
        skills: skills,
      });
    }
  }, [skills]);

  useEffect(() => {
    getAllSkills();

    return () => setSkills([]);
  }, []);

  useEffect(() => {
    if (getSkillsResp) {
      setDefaultSkills(getSkillsResp?.getAllSkills);
    }
  }, [getSkillsResp]);

  useEffect(() => {
    if (selectedFile && selectedFile.name && cuuid) {
      const path = `course_content/course_${cuuid}/assets/${selectedFile?.name}`;

      const processSelectedFile = async () => {
        const generatePutUriResponse = await generatePutUri({
          variables: {
            blobName: path,
          },
        });

        const { url, blobPath } =
          generatePutUriResponse?.data?.generateSasPutUri ?? {};

        // This will give error for now.
        try {
          await fetch(url, {
            method: 'PUT',
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Access-Control-Allow-Origin": "*"
            },
            body: selectedFile
          });
        } catch (error) {
          console.log("Error uploading file", error);
        }

        form.setFieldsValue({
          courseBanner: blobPath,
        });
      };

      processSelectedFile();
    }
  }, [selectedFile, generatePutUri, cuuid]);

  const customRequest = async (options) => {
    try {
      setSelectedFile(options.file);
      message.success("File uploaded successfully");
    } catch (error) {
      options.onError(error);
    }
  };

  return (
    <>
      <Row gutter={[24, 24]} className="course-form-container">
        <Col className="course-form-header" span={24}>
          <Text className="header-5 capitalize course-form-header-title">
            {`${title}`}
          </Text>
          <Divider className="breadcrumb-divider m-0 mt-12" />
        </Col>

        <Col className="course-form-content" span={24}>
          <Form form={form}>
            <Row gutter={[24, 24]} className="mb-20">
              <Col lg={12} xs={24}>
                <Col span={12} className="label mb-8">
                  <span className="mandatory">* </span>Title
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: `Please input course title`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input size="large" placeholder="Enter course title" />
                  </Form.Item>
                </Col>
              </Col>
              <Col lg={12} xs={24}>
                <Col span={12} className="label mb-8">
                  <span className="mandatory">* </span>
                  Course Banner
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="courseBanner"
                    rules={[
                      {
                        required: true,
                        message: `Please input course banner`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Upload
                      customRequest={customRequest}
                      showUploadList={false}
                      maxCount={1}
                      accept=".png, .jpeg, .jpg"
                      beforeUpload={(file) => {
                        if (!ALLOWED_TYPES_FOR_IMAGES.includes(file.type)) {
                          message.error(
                            "Please upload PNG / JPEG / JPG files only !"
                          );
                          return false; // Prevent upload
                        }
                        return true; // Proceed with upload
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        {selectedFile && selectedFile.name ? selectedFile.name : fileName ? fileName : "Upload File"}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Col>
            </Row>

            <Row gutter={[24, 24]} className="mb-20">
              <Col lg={12} xs={24}>
                <Col lg={12} xs={24} className="label mt-8 mb-8">
                  <span className="mandatory">* </span>Skills
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    name="skills"
                    rules={[
                      {
                        required: true,
                        message: `Please input skills`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      mode="multiple"
                      placeholder="Please select skills"
                      size="large"
                      options={defaultSkills}
                    >
                      {skills && skills?.map((skill) => (
                          <Option key={skill.value} value={skill.value}>{skill.label}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Col>

              <Col lg={12} xs={24}>
                <Col lg={12} xs={24} className="label mt-8 mb-8">
                  <span className="mandatory">* </span>Max Enrollment
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    name="maxEnrollment"
                    rules={[
                      {
                        required: true,
                        message: `Please input max enrollment`,
                      },
                      {
                        validator: (_, value) => {
                          if (!value || /^[0-9]+$/.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please enter a numeric value'));
                        },
                      }
                    ]}
                    className="mb-0"
                  >
                    <Input size="large" placeholder="Enter max enrollment" />
                  </Form.Item>
                </Col>
              </Col>
            </Row>

            <Row gutter={[24, 24]} className="mb-20">
              <Col lg={12} xs={24}>
                <Row gutter={[24, 24]}>
                  <Col lg={12} xs={24}>
                    <Col lg={12} xs={24} className="label mt-8 mb-8">
                      <span className="mandatory">* </span>Valid From
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        name="validFrom"
                        rules={[
                          {
                            required: true,
                            message: `Please input validFrom`,
                          },
                        ]}
                        className="mb-0"
                      >
                        <DatePicker
                          size="large"
                          placeholder="Select validFrom"
                          suffixIcon={<CalendarOutlined />}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                  </Col>

                  <Col lg={12} xs={24}>
                    <Col lg={12} xs={24} className="label mt-8 mb-8">
                      <span className="mandatory">* </span>Valid To
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        name="validTo"
                        rules={[
                          {
                            required: true,
                            message: `Please input validTo`,
                          },
                        ]}
                        className="mb-0"
                      >
                        <DatePicker
                          size="large"
                          placeholder="Select validTo"
                          suffixIcon={<CalendarOutlined />}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                  </Col>
                </Row>
              </Col>

              <Col lg={12} xs={24}>
                <Col lg={12} xs={24} className="label mt-8 mb-8">
                  <span className="mandatory">* </span>Duration
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: `Please input duration`,
                      },
                      {
                        validator: (_, value) => {
                          if (!value || /^[0-9]+$/.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please enter a numeric value'));
                        },
                      }
                    ]}
                    className="mb-0"
                  >
                    <Input size="large" placeholder="Enter duration" />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row gutter={[24, 24]} className="mb-18">
              <Col span={24}>
                <Col span={12} className="label mt-4 mb-8">
                  Course Description
                </Col>
                <Col span={12}>
                  <Form.Item name="courseDescription" className="mb-40">
                    <ReactQuill
                      key={`courseDescription`}
                      value={"text"}
                      theme="snow"
                      style={{ height: '100px' }}
                      placeholder="Enter the course description"
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>

            <Row gutter={[24, 24]} className="mb-18">
              <Col span={24}>
                <Col span={12} className="label mt-8 mb-8">
                  Prerequisites
                </Col>
                <Col span={12}>
                  <Form.Item name="prerequisites" className="mb-40">
                    <ReactQuill
                      key={`prerequisites`}
                      value={"text"}
                      theme="snow"
                      style={{ height: '100px' }}
                      placeholder="Enter prerequisites"
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>

            <Row gutter={[24, 24]} className="mb-20">
              <Col span={24}>
                <Col span={12} className="label mt-8 mb-8">
                  What you'll learn
                </Col>
                <Col span={12}>
                  <Form.Item name="whtylearn" className="mb-40">
                    <ReactQuill
                      key={`whtylearn`}
                      value={"text"}
                      theme="snow"
                      style={{ height: '100px' }}
                      placeholder="Enter what you'll learn"
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row gutter={[24, 24]} className="course-form-button-container mt-12">
        <Col span={24}>
          <Row className="flex flex--space-between mb-20 mt-20 mr-12">
            <Col></Col>
            <Col>
              <Button
                className="form-next-button"
                onClick={handleNextButtonClick}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddEditCourseDetails;
