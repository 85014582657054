import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { Avatar, Input, Popover, Typography } from "antd";
import { icons } from "assets";
import "./index.scss";

export default function HeaderContent() {
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };
  const { Search } = Input;
  const onSearch = () => console.log("searched");

  const headerMenu = () => {
    return (
      <div className="sign-out">
        <a style={{ color: "inherit" }} href="/" onClick={handleLogoutRedirect}>
          <LogoutOutlined /> Sign Out
        </a>
      </div>
    );
  };

  return (
    <>
      <div className=" header-container-nav flex flex--space-between flex--align-center">
        <div className="header-tnl">
          <img
            src={icons.siderLogo}
            alt="appLOGO"
            style={{ height: "30px", width: "30px" }}
          />
          <Typography className="header-title">Deloitte Super Tutor</Typography>
        </div>

        <Search
          className="header-search"
          placeholder="input search text"
          onSearch={onSearch}
          enterButton
          disabled={true}
        />
        <div className="flex flex--space-between" style={{ gap: '1rem' }}>
          <img
            className="header-notification"
            src={icons.Notification}
            alt=""
          />
          <Popover placement="bottomLeft" content={headerMenu()}>
            <Avatar icon={<UserOutlined />} />
          </Popover>
        </div>
      </div>
    </>
  );
}
