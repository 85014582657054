import ClipboardJS from "clipboard";
import _ from "lodash";
import MarkdownIt from "markdown-it";
import mdHighlight from "markdown-it-highlightjs";

// eslint-disable-next-line no-unused-vars
const clipboard = new ClipboardJS(".markdown-it-code-copy");

function renderCode(origRule, options) {
  const defaultOptions = {
    iconStyle: "default_icon_styling",
    iconClass: "mdi mdi-content-copy",
    buttonStyle: "",
    buttonClass: "default_copy_button_styling",
  };

  options = _.merge(defaultOptions, options);

  return (...args) => {
    const [tokens, idx] = args;
    const content = tokens[idx].content
      .replaceAll('"', "&quot;")
      .replaceAll("'", "&#39;");
    const origRendered = origRule(...args);

    if (content.length === 0) return origRendered;

    return `
<div style="position: relative">
	${origRendered}
	<button class="markdown-it-code-copy ${options.buttonClass}" data-clipboard-text="${content}" style="${options.buttonStyle}" title="Copy">
		<span style="${options.iconStyle}" class="${options.iconClass}"></span>
	</button>
</div>
`;
  };
}

function mdCodeCopy(md, options) {
  md.renderer.rules.code_block = renderCode(
    md.renderer.rules.code_block,
    options
  );
  md.renderer.rules.fence = renderCode(md.renderer.rules.fence, options);
}

export const checkForMarkdown = (message) => {
  const splitPattern = "***";
  let sections = [];

  if (message.includes(splitPattern)) {
    sections = message.split(splitPattern);
  } else {
    sections.push(message);
  }

  let md = MarkdownIt({
    linkify: true,
    breaks: true,
  }).use(mdCodeCopy, {
    iconStyle: "", // Clear default icon style
    iconClass: "icon_styling", // Set a custom class for the icon element
    buttonStyle: "",
    buttonClass: "copy_button_styling",
  });

  md = md.use(mdHighlight);

  const renderedText = sections.map((section, index) => {
    if (index % 2 === 0) {
      const data = section.trim();
      const result = md.render(data);
      return (
        <div
          dangerouslySetInnerHTML={{ __html: result }}
          style={{ wordWrap: "break-word" }}
        />
      );
    } else {
      let markdownText = section.trim();
      const linkRegex = /<ins>(.*?)<\/ins>\]\((.*?)\)/;
      const [, textContent, link] = linkRegex.exec(markdownText) || [];
      if (textContent) {
        return (
          <div>
            <hr />
            <em>Sources: </em>
            <a href={link} target="_blank" rel="noreferrer">
              <ins>{textContent}</ins>
            </a>
          </div>
        );
      }
      return (
        <div>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: markdownText }} />
        </div>
      );
    }
  });

  return renderedText;
};

export const capabilitiesText = [
  "Capable of answering questions specific to internal Deloitte data",
  "Remembers what user said earlier in the conversation",
  "Sources Deloitte Data and OpenAI data to answer questions.",
];

export const pythonPrompt = `
You are a Python Functions, OOPS concepts, RE, Lambda, Map and Reduce Smart Tutor. As an experienced tutor specializing in Python concepts like python functions, OOPS, RE,Lambda,Map and Reduce etc, you assist learners in understanding various concepts and solving coding challenges.
The user interacting with you is a developer focusing on learning python 101.
Your objective is to help learners understand Python concepts mentioned above by providing coding challenges, guiding questions, and evaluating their solutions. When the user says 'Challenge my Knowledge', assign them one of the Python functions coding challenge.
Encourage users to write their own code and evaluate it. Assess their solutions based on correctness, clarity, and adherence to best practices. Provide constructive feedback and encourage iteration for improvement.

Few examples of Coding Challenges:
How to sort a dictionary in python according to the value?
How to get minimum key value pair from a dictionary in python according to the value?
How to use regular expressions in python to replace special charachters with #?
How to add elements to a nested list of lists in python?
How to use a time related package in python to get the number of days between two given dates?
Given a list and a position n, how do we get a list of elements what occurs every nth position?  
Print the longest word in an array of strings using reduce operator?
Demonstrate types of inheritance using a program

Remember to prompt the user to provide their code and evaluate it. Evaluate their solutions based on correctness, efficiency, adherence to Python best practices, and any specific requirements mentioned in the challenge.
Encourage users to ask questions and engage in conversation to understand the concepts better. Provide guidance and ask guiding questions to help them arrive at the correct solutions.
Ensure to ask different question every time and dont give direct solution or code to the user. Always provide only direction and strictly dont give coding solution to the problem. Strictly do not giving code answer to any question specific to page context`;

export const scrolledToBottom = (setIsScrolledToBottom = () => {}) => {
  const chatInfo = document.querySelector("#chat-content-container-id");
  const winScroll = chatInfo?.scrollTop;
  const height = chatInfo?.scrollHeight - chatInfo?.clientHeight;
  // allow inaccuracy by adding some
  setIsScrolledToBottom(height <= winScroll + 1);
};

export const scrollToBottom = () => {
  const chatInfo = document.querySelector("#chat-content-container-id");
  chatInfo?.scrollTo(0, chatInfo.scrollHeight);
};

export const getInitials = (activeAccount) => {
  if (activeAccount) {
    const words = activeAccount.split(" ").reverse();
    const initials = words.map((word) => word[0].toUpperCase()).join("");
    return initials;
  }
  return "";
};

export const getFormattedData = (value) => {
  const data = value?.map((text) => {
    if (!/[a-zA-Z]/.test(text.toString())) return [];
    return text.toString().split("\n");
  });
  return data;
};

export const CONTEXT_MAPPER = [
  "",
  `
    In this assignment, you'll get to practice some of the concepts and skills covered in the curriculum with you. Some things to keep in mind: 

    Write the code for each problem statement against the function name mentioned. 
    Do not change variable names, delete cells or disturb other existing code shared in GitHub repo. It may cause problems during evaluation. 
    Few test cases are provided in the shared GitHub Codebase. Follow the Readme file in the shared initial codebase to learn about how to run the program and test cases. 

    After attempting all the questions, submit the assessment to get your score. This assessment gives you a good feeling of how you can get data out of different data structures. There is a lot more to Python, so keep moving to the next assessment and improving your Python skills.
  `,
  `
    Write a program to get the name of the youngest and oldest in the group.
    Input: {"Kelly":25,"John":30,"Andrew":21,"Sam":32,"Suzane":22} 
    Output: {'min_key': 'Andrew', 'max_key': 'Sam'} 
  `,
  `
    Write a regex expression to validate emails which allows only (these two special symbols (.  and -) before @ symbol. 
  `,
  `
    You are given a nested list of characters, but few characters are missing in the list. Write a program to inject the missing characters at the right place maintaining the order and structure of the list. 
    Input List: ["a", "b", ["c", ["d", "e", ["f", "g"], "k"], "l"], "m", "n"] 
    CharInject: ["h", "i", "j"] 
    Output: ['a', 'b', ['c', ['d', 'e', ['f', 'g', 'h', 'i', 'j'], 'k'], 'l'], 'm', 'n'] 
  `,
  `
    You are given unstructured data. Write a program to sort the tuples in ascending order based on the second position value. 
    Input: tuple1 = (('a', 23), ('b', 37), ('c', 11), ('d',29)) w
    Output: (('c', 11), ('a', 23), ('d', 29), ('b', 37))  
  `,
  `
    Tony wants to generate an account report between 2 dates and club them based on number of days. Write a program to calculate the number of days between two given dates so that Tony can easily club the reports.
    Input: 
    Date 1 – 25-01-2020 
    Date 2 – 28-05-2022 
    Output: 854 
  `,
  `
    Tony is working on two different data sets and wants to merge them. He is stuck with the problem as the data sets have a lot of common/duplicate items. Write a program to merge given 2 sets keeping the occurrence of duplicate items to only one set.  
    Input: 
    set1 = {10, 20, 30, 40, 50} 
    set2 = {30, 40, 50, 60, 70} 
    Output: set1 = {10, 20, 30, 40, 50, 60, 70}
  `,
  `
    Mr. Tony is planning to acquire a cars company. He wants to understand if the business would be profitable to him or not. For the same, he needs to process some data to get a better understanding of the company.
    Given the cars data in dictionary, write a program and help Mr. Tony to: 
    Get list of all jeeps 
    Get the first car of every manufacturer 
    Get all vehicles containing the string “Trail” in their names 
    Sort the models(values) alphabetically 
    Input: 
    cars = { 
        'Ford': ['Falcon', 'Focus', 'Festiva', 'Fairlane'], 
        'Holden': ['Commodore', 'Captiva', 'Barina', 'Trailblazer'], 
        'Nissan': ['Maxima', 'Pulsar', '350Z', 'Navara'], 
        'Honda': ['Civic', 'Accord', 'Odyssey', 'Jazz'], 
        'Jeep': ['Grand Cherokee', 'Cherokee', 'Trailhawk', 'Trackhawk'] 
    }
  `,
  `
    Write a class called Employee with instance variables: name and salary and an instance method: getDetails().  Make the instance variables - (name and salary) private, public, and protected in three separate examples and print the value of these variables in the method getDetails()
  `,
  `
    Create a Bus child class that inherits from the Vehicle class. The default fare charge of any vehicle should be seating_capacity * 100. If Vehicle is Bus instance, we need to add an extra 10% on full fare as a maintenance charge. So total fare for bus instance should become the final_amount = total fare + 10% of the total fare. Demonstrate this example using Bus and Vehicle classes and using the concept of method overriding. 
  `,
  `
    Write a Python program to add two given lists and find the difference between lists. Use map() function. 
    nums1 = [6, 5, 3, 9] 
    nums2 = [0, 1, 7, 7] 
  `,
  `
    Mr. Tony wants to find the person with most friends. You are presented with a users dict of keys=ids and values=usernames and a friendships list of user id tuples expressing a friendship between two users. Find out which user has the most friends. Re
    Input: 
    friendships = [(0, 1), (0, 2), (0, 4), (0, 5), (1, 3), (2, 4), (4, 5)] 
    users={0: 'bob', 1: 'bob', 2: 'tim', 3: 'tim', 4: 'julian', 5: 'julian'} 
    Output: (‘bob’, ['bob', 'julian', 'julian', 'tim']) 
  `,
  `
    Mr. Tony’s employee management system crashed, and he urgently needs to share some data with clients. For the same reason, Mr. Tony needs help in searching profiles from the given big data set.

    Write a function called get_profile that takes: 
    a required name, 
    a required age, 
    one or more optional sports (args), 
    one or more optional awards (keyword args).

    Add the following validations: 
    If age is not an int raise a ValueError, 
    If more than 5 sports are provided raise a ValueError. 

    Some examples how your function can be called 
    get_profile('tim', 36) 
    get_profile('tim', 36, 'tennis', 'basketball') 
    get_profile('tim', 36, 'tennis', 'basketball', champ='helped out team in crisis') 

    The function should return a dict with all the args, like so: 
    get_profile('tim', 36) == {'name': 'tim', 'age': 36} # some arg types 
    {'name': 'tim', 'age': 36, 'sports': ['basketball', 'tennis'], 'awards': {'champ': 'helped out team in crisis'}} # all arg types 
    Note: (args list to be sorted alphabetically) 
  `,
];
